import React, { Fragment, memo } from 'react'
import { PaginationType } from '@types'
import styles from './Pagination.module.scss'

interface PaginationProps extends Partial<PaginationType> {
  limit?: number
  onClick(page: number): void
}

const Pagination: React.FC<PaginationProps> = props => {
  const { pageNumber, totalPages = 1, limit = 5, onClick } = props
  if (!pageNumber) return null

  /* Pages from the left and right to render */
  const pagesOffset = Math.floor(limit / 2)

  /* If current page is in page offset so current in first pages */
  const isFirstPages = pageNumber <= pagesOffset
  /* If current page + page offset greater than total pages the current is in last pages */
  const isLastPages = pageNumber + pagesOffset > totalPages!

  /* Start page */
  let start: number
  /* End page */
  let end: number

  if (isFirstPages) {
    /*
      If first pages:
        start: 1
        end: limit or total pages
    */
    start = 1
    end = Math.min(limit, totalPages!)
  } else if (isLastPages) {
    /*
      If last pages:
        start: total pages - limit without inclusion or 1
        end: total pages

      - 1 because of last page inclusion
    */

    start = Math.max(totalPages! - (limit - 1), 1)
    end = totalPages!
  } else {
    /*
      Else:
        start: current - page offset
        end: last page in a row or total pages
    */
    start = pageNumber - pagesOffset
    end = Math.min(pageNumber + pagesOffset, totalPages!)
  }

  const renderPages = () => {
    return new Array(limit).fill(0).map((_, index) => {
      const page = start + index

      /* End constrait */
      const isEnd = page > end
      const isFirstPagesDots = !isFirstPages && index === 0
      const isLastPagesDots = !isLastPages && page === end

      if (isEnd) return

      const isActive = page === pageNumber
      // TODO Enter and tab logic + test

      /* First pages dots */
      if (isFirstPagesDots) {
        return (
          <Fragment key={index}>
            <li tabIndex={0} className={styles.paginationListItem} onClick={() => onClick(1)} data-active={isActive}>
              1
            </li>
            <li tabIndex={0} className={styles.paginationListItem} onClick={() => onClick(1)}>
              ...
            </li>
          </Fragment>
        )
      }

      /* Last pages dots */
      if (isLastPagesDots) {
        return (
          <Fragment key={index}>
            <li tabIndex={0} className={styles.paginationListItem} onClick={() => onClick(totalPages)}>
              ...
            </li>
            <li
              tabIndex={0}
              className={styles.paginationListItem}
              onClick={() => onClick(totalPages)}
              data-active={isActive}
            >
              {totalPages}
            </li>
          </Fragment>
        )
      }

      return (
        <li
          key={index}
          tabIndex={0}
          className={styles.paginationListItem}
          onClick={() => onClick(page)}
          data-active={isActive}
        >
          {page}
        </li>
      )
    })
  }

  return (
    <nav role="pagination" className={styles.pagination}>
      <ul className={styles.paginationList}>{renderPages()}</ul>
    </nav>
  )
}

export default memo(Pagination)
