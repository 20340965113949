import React from 'react'
import { ButtonWithPencil } from '@elements'
import BankLogo from '@images/bank-logo.svg'
import MasterCard from '@images/master-card.svg'
import Visa from '@images/visa.svg'
import { navigate } from 'gatsby'
import './SellerCard.scss'

export interface SellerCardProps {
  cardNumber: String
  payoutType: String
  brand: String
  stripeAccountUrl: string
}

const SellerCard: React.FC<SellerCardProps> = props => {
  const { cardNumber, brand, payoutType, stripeAccountUrl } = props

  const renderCardLogo = (brand: SellerCardProps['brand'], payoutType: SellerCardProps['payoutType']) => {
    if (payoutType === 'card') {
      if (brand === 'Visa') {
        return (
          <div className={'seller-card-item-cardlogo-wrap'}>
            <Visa width={40} height={40} />
          </div>
        )
      }
      return (
        <div className={'seller-card-item-cardlogo-wrap'}>
          <MasterCard height={40} width={40} />
        </div>
      )
    } else if (payoutType === 'bank_account') {
      return (
        <div className={'seller-card-item-cardlogo-wrap'}>
          <BankLogo width={40} height={40} />
        </div>
      )
    }
  }

  const renderPayoutType = (payoutType: SellerCardProps['payoutType']) => {
    if (payoutType === 'card') return <div className={'seller-card-item-acctype'}>Card for processing fee</div>
    return <div className={'seller-card-item-acctype'}>Bank Account for Payout</div>
  }

  return (
    <div className={'seller-card-item'}>
      <div className={'seller-card-item-wrapper'}>
        <div className={'seller-card-item-cardlogo'}>{renderCardLogo(brand, payoutType)}</div>
        <div className={'seller-card-item-number'}>
          <div className={'seller-card-item-number-mask'}>&#8226;&#8226;&#8226;&#8226;</div>
          <div className={'seller-card-item-number-value'}>{cardNumber}</div>
        </div>
        {renderPayoutType(payoutType)}
      </div>
      <a href={stripeAccountUrl}>
        <ButtonWithPencil onClick={() => navigate(stripeAccountUrl)}>Edit</ButtonWithPencil>
      </a>
    </div>
  )
}

export default SellerCard
