import { gql } from '@apollo/client'
import { BrandAttrs } from '@fragments'

export const FetchBrands = gql`
  query FetchBrands {
    res: brands {
      ...BrandAttrs
    }
  }

  ${BrandAttrs}
`

export const FetchBrand = gql`
  query FetchBrand($id: UUID4!) {
    res: brand(id: $id) {
      ...BrandAttrs
    }
  }

  ${BrandAttrs}
`

export const FetchBrandByCategory = gql`
  query FetchBrandByCategory($id: UUID4!) {
    res: brandByCategory(id: $id) {
      ...BrandAttrs
    }
  }

  ${BrandAttrs}
`
