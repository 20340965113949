import { Autocomplete } from '@components'
import { Button } from '@elements'
import { useGolfClubModelSearch } from '@hooks'
import SearchImage from '@images/search.svg'
import { navigate } from 'gatsby'
import React, { memo } from 'react'
import styles from './GolfClubsSearch.module.scss'

interface GolfClubsSearchProps {}

const GolfClubModelSearch: React.FC<GolfClubsSearchProps> = () => {
  const page = '/catalogue'
  const {
    catalogItems,
    handleUrlSearchQueryClear,
    initialSearch,
    openCataloguePageWithSearch,
    loading,
    handleSearch,
    params
  } = useGolfClubModelSearch()

  const golfItemsOptions = catalogItems.map(catalogItem => {
    return {
      label: `${catalogItem?.brand || ''}  ${catalogItem?.title || ''} - ${catalogItem.category || ''}`,
      value: catalogItem.slug
    }
  })

  const openProductPage = async (productSlug: string | null) => {
    if (productSlug) {
      const navigateCatalogItemParams = new URLSearchParams('')
      const link = `/product/${productSlug}`
      await navigate(link + '?' + navigateCatalogItemParams.toString())
    }
  }

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key
    /* It seems that debounce removes currentTarget field from event */
    const searchValue = (e.target as HTMLInputElement).value

    if (key === 'Enter') {
      await openCataloguePageWithSearch()
    } else {
      handleSearch(searchValue)
    }
  }

  return (
    <div className={styles.golfClubsSearchInput}>
      <Autocomplete
        disableFilter
        value={null}
        initialSearch={decodeURIComponent(initialSearch || '')}
        options={golfItemsOptions}
        name={'golfClubsSearch'}
        loading={loading}
        clearable={false}
        onChange={openProductPage}
        onBlur={handleUrlSearchQueryClear}
        onClear={handleUrlSearchQueryClear}
        onKeyDown={handleKeyDown}
        onInputChange={handleSearch}
        dropdown={{
          allResultsLink: page + params
        }}
        input={{
          role: 'search',
          placeholder: 'What are you looking for?',
          prefix: <SearchImage />,
          suffix: (
            <Button type={'black'} onClick={openCataloguePageWithSearch}>
              Search
            </Button>
          )
        }}
      />
    </div>
  )
}

export default memo(GolfClubModelSearch)
