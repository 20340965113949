import { gql } from '@apollo/client'
import { BrowsingViewResponseAttrs, UserBrowsingViewsAttrs } from '@fragments'

export const FetchPaginatedBrowsingViews = gql`
  query FetchPaginatedBrowsingViews($page: Int, $pageSize: Int) {
    res: paginatedBrowsingViews(page: $page, pageSize: $pageSize) {
      entries {
        ...UserBrowsingViewsAttrs
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }

  ${UserBrowsingViewsAttrs}
`

export const CreateBrowsingView = gql`
  mutation CreateBrowsingView($browsingView: BrowsingViewParams
) {
    res: createBrowsingView(browsingView: $browsingView) {
      ...BrowsingViewResponseAttrs
            result {
        ...UserBrowsingViewsAttrs
      }
    }
  }
  ${BrowsingViewResponseAttrs},
  ${UserBrowsingViewsAttrs}
`

export const DeleteBrowsingView = gql`
  mutation DeleteBrowsingView($id: UUID4!) {
    res: deleteBrowsingView(id: $id) {
      ...BrowsingViewResponseAttrs
    }
  }
  ${BrowsingViewResponseAttrs}
`
export const ClearUserBrowsingHistory = gql`
  mutation ClearUserBrowsingHistory {
    res: clearBrowsingViews {
      successful
    }
  }
`

export const AddUserBrowsingView = gql`
  mutation CreateBrowsingView($id: UUID4!) {
    res: createBrowsingView(browsingView: { golfClubModelId: $id }) {
      ...BrowsingViewResponseAttrs
      result {
        ...UserBrowsingViewsAttrs
      }
    }
  }

  ${UserBrowsingViewsAttrs}
  ${BrowsingViewResponseAttrs}
`
