export * from './lists'
export * from './table'
export * from './forms'
export * from './apollo'
export * from './cache'
export * from './dates'
export * from './modals'
export * from './html'
// APOLLO
export * from './apollo/enum'
export * from './apollo/equipment'
export * from './apollo/catalog'
export * from './apollo/order'
export * from './apollo/notifications'
export * from './apollo/categories'
export * from './apollo/elastic'

export type ProductPageItemType = 'GolfClubModel' | 'GolfEquipmentModel' | string | undefined
export type Children = JSX.Element | JSX.Element[] | string | null
export type AnyObject = Record<string, any>
export type OptionSchema = {
  [x: string]: string | ((item: any) => string)
}

export type SiteMetadataNavigationRoute = {
  path: string
  label: string
}

export type SiteMetadataNavigation = SiteMetadataNavigationRoute[]

export type SiteMetadata = {
  site: {
    siteMetadata: {
      title: string
      author: {
        name: string
        summary: string
      }
      description: string
      siteUrl: string
      navigation: SiteMetadataNavigation
      accountNavigation: SiteMetadataNavigation
    }
  }
}

export type FooterNavigationButtonType = {
  name: string
  link: string
  icon: string
  requireLogin?: boolean
}
