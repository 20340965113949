import React, { memo } from 'react'
import { useQuery } from '@apollo/client'
import { ButtonWithPlus, SellerCard } from '@elements'
import { store as graphqlStore } from '@graphql'
import { ResponseType, StripeAccountUrlResponseType, User } from '@types'
import { navigate } from 'gatsby'
import './ProfilePayout.scss'

export interface ProfilePayoutProps {
  payoutMethod?: {
    id: string
    object: string
    cardNumber: string
    expYear: number
    expMonth: number
    user: User
    brand: string
  }
  stripeReturnUrl: string
}

const ProfilePayout: React.FC<ProfilePayoutProps> = props => {
  const { payoutMethod, stripeReturnUrl } = props

  const { data: stripeAccountURLData } = useQuery<ResponseType<StripeAccountUrlResponseType>>(
    graphqlStore.GetStripeAccountUrl,
    { fetchPolicy: 'cache-first', variables: { redirectUrl: stripeReturnUrl } }
  )
  const stripeAccountUrl = stripeAccountURLData?.res.url || ''

  if (!payoutMethod) {
    return (
      <div className={'payout-info-add-information'}>
        <h5 className={'payout-info-add-information-title'}>Add a vendor payment information</h5>
        <div className={'payout-info-add-information-button'}>
          <ButtonWithPlus onClick={() => navigate(stripeAccountUrl)}>Add Payout Method</ButtonWithPlus>
        </div>
      </div>
    )
  }
  return (
    <div className={'payout-info-card'}>
      {payoutMethod && (
        <SellerCard
          stripeAccountUrl={stripeAccountUrl}
          brand={payoutMethod.brand}
          payoutType={payoutMethod.object}
          cardNumber={payoutMethod.cardNumber}
        />
      )}
    </div>
  )
}

export default memo(ProfilePayout)
