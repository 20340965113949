import { gql, InMemoryCache, InMemoryCacheConfig, makeVar } from '@apollo/client'
import {
  AddressCard,
  CacheDrawer,
  CacheModal,
  CatalogItem,
  ExistingProductFeedbackType,
  GolfClubModel,
  NewProductFeedbackType,
  PaginationType
} from '@types'
import { AddressCardAttrs } from '@fragments'
import possibleTypes from './gatsby-apollo-cache-types.json'
import { FieldFunctionOptions } from '@apollo/client/cache/inmemory/policies'

export const modalsVar = makeVar<CacheModal[]>([])
export const drawersVar = makeVar<CacheDrawer[]>([])
export const customBrowsePageItemsVar = makeVar<{ entries: CatalogItem[]; title: string; backUrl: string }>({
  entries: [],
  backUrl: '',
  title: ''
})
export const feedbackVar = makeVar<NewProductFeedbackType | ExistingProductFeedbackType | {}>({})
export const cacheCatalogueFilterParams = makeVar('')

const mergePaginated = (
  existing: PaginationType<Record<string, any>>,
  incoming: PaginationType<Record<string, any>>,
  options: FieldFunctionOptions
) => {
  const args = options?.args
  const page = args?.page || incoming?.pageNumber || 1
  const pageSize = args?.pageSize || incoming.pageSize || 10
  const newEntries = [...(existing?.entries || [])]
  const incomingEntries = incoming?.entries || []
  const endIndex = page * pageSize
  const startIndex = endIndex - pageSize
  for (let i = 0; i < incomingEntries.length; ++i) {
    newEntries[startIndex + i] = { ...incomingEntries[i] }
  }

  return {
    ...incoming,
    pageNumber: page,
    pageSize,
    entries: newEntries
  }
}
const readPaginated = (existing: PaginationType<Record<string, any>>, options: FieldFunctionOptions) => {
  const args = options?.args
  const page = args?.page || 1
  const pageSize = args?.pageSize || 10
  const endIndex = page * pageSize
  const startIndex = endIndex - pageSize

  return {
    ...existing,
    pageNumber: page,
    pageSize,
    entries: (existing?.entries || []).slice(startIndex, endIndex)
  }
}

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        paginatedGolfClub: {
          keyArgs: ['term'],
          merge: mergePaginated,
          read: readPaginated
        },
        paginatedPurchaseRequests: {
          keyArgs: ['status'],
          merge: mergePaginated,
          read: readPaginated
        },
        modals: {
          read() {
            return modalsVar()
          }
        },
        drawers: {
          read() {
            return drawersVar()
          }
        }
      }
    },
    CurrentUser: {
      fields: {
        // avatar(avatar) {
        //   /**
        //    * Image prefix from .env file
        //    */
        //
        //   return avatar ? avatarPrefix + avatar : avatar
        // },
        name(_, { cache }) {
          const data: { currentUser: { firstName: string; lastName: string } } | null = cache.readQuery({
            query: gql`
              query {
                currentUser {
                  firstName
                  lastName
                }
              }
            `
          })

          const currentUser = data && data.currentUser
          return currentUser && currentUser.firstName + ' ' + currentUser.lastName
        },
        defaultAddressCard(_, { cache }) {
          const data: { currentUser: { addressCards: AddressCard[] } } | null = cache.readQuery({
            query: gql`
              query {
                currentUser {
                  addressCards {
                    ...AddressCardAttrs
                  }
                }
              }

              ${AddressCardAttrs}
            `
          })

          const currentUser = data && data.currentUser
          const currentUserAddressCards = currentUser && currentUser.addressCards
          const currentUserFirstAddressCard = currentUserAddressCards && currentUserAddressCards[0]
          const currentUserDefaultAddressCards =
            currentUserAddressCards && currentUserAddressCards.find(addressCard => addressCard.default)

          return currentUserDefaultAddressCards || currentUserFirstAddressCard || null
        }
      }
    },
    BasicInfo: {
      fields: {
        flex(flex = localStorage.getItem('flex')) {
          return flex
        },
        dexterity(dexterity = localStorage.getItem('dexterity')) {
          return dexterity
        }
      }
    },
    GolfClubModel: {
      fields: {
        golfClubTypes: {
          merge(existing: any[] = [], incoming: any[] = []) {
            if (!incoming && existing) {
              return existing
            }
            return incoming
          }
        }
      }
    },
    GolfEquipmentModel: {
      fields: {
        golfClubTypes: {
          merge(existing: any[] = [], incoming: any[] = []) {
            if (!incoming && existing) {
              return existing
            }
            return incoming
          }
        }
      }
    }
  }
} as InMemoryCacheConfig)

export default cache
