import React, { memo } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { LinkGetProps } from '@reach/router'
import { Children } from '@types'
import LinkArrowImage from '@images/link-arrow.svg'
import styles from './Link.module.scss'

export interface LinkProps {
  to: string
  href?: string
  replace?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  key?: string | number
  className?: string
  state?: Record<string, any>
  active?: boolean
  underlined?: boolean
  children: Children
  withArrow?: boolean
  noSvgStyles?: boolean
}

const Link: React.FC<LinkProps> = props => {
  const { to, href, key, state, onClick, active, underlined, withArrow, children, replace, noSvgStyles } = props

  if (href) {
    return (
      <a href={href} className={`${styles.link} ${props.className}`}>
        {children}
      </a>
    )
  }

  const handleGetProps = (linkProps: LinkGetProps) => {
    return {}
  }

  return (
    <GatsbyLink
      key={key}
      className={`${styles.link} ${props.className}`}
      getProps={handleGetProps}
      data-active={active}
      data-underlined={underlined}
      to={to}
      state={state}
      onClick={onClick}
      data-arrow={withArrow}
      data-svg-styles={!noSvgStyles}
      replace={replace}
    >
      {children}
      {withArrow && <LinkArrowImage />}
    </GatsbyLink>
  )
}

Link.defaultProps = {
  className: ''
}

export default memo(Link)
