import { gql } from '@apollo/client'
import { BrandAttrs, OptionAttrs, PurchaseRequestAttrs } from './commonTypes'
import { ValidationMessageAttrs } from './response'

export const EquipmentCategoryAttrs = gql`
  fragment EquipmentCategoryAttrs on EquipmentCategory {
    id
    insertedAt
    title
    updatedAt
  }
`

export const GolfEquipmentAttr = gql`
  fragment GolfEquipmentAttr on GolfEquipment {
    avatar
    avatars
    expiresAt
    id
    insertedAt
    price
    soldAt
    status
    updatedAt
    brand {
      title
    }
    category {
      title
    }
    golfEquipmentType {
      id
    }
    golfEquipmentModel {
      avatar
      title
      slug
      tourdayRetailPricePercent
    }
  }
`

export const GolfEquipmentModelBrowsingHistoryAttrs = gql`
  fragment GolfEquipmentModelBrowsingHistoryAttrs on GolfEquipmentModel {
    id
    avatar
    title
    slug
    lowestSellPrice
    highestBuyPrice
    brand {
      title
    }
  }
`

export const GolfEquipmentTypeAttrs = gql`
  fragment GolfEquipmentTypeAttrs on GolfEquipmentType {
    condition
    openPurchaseRequests {
      ...PurchaseRequestAttrs
    }
    purchaseRequests {
      ...PurchaseRequestAttrs
    }
    golfEquipments {
      ...GolfEquipmentAttr
    }
    golfEquipmentsOnSale {
      ...GolfEquipmentAttr
    }
    golfEquipmentModel {
      additionalInformation
      availabilityStatus
      avatar
      avatars
      brand {
        ...BrandAttrs
      }
      care
      category {
        ...EquipmentCategoryAttrs
      }
      color
      description
      features
      fit
      gender

      highestBuyPrice
      id
      images
      insertedAt
      isLimited
      lowestSellPrice
      optionTitle
      options {
        ...OptionAttrs
      }
      quantityMade
      releaseYear
      retailPrice
      slug
      style
      subCategory {
        id
        insertedAt
        title
        updatedAt
      }
      tickerSymbol
      title
      tourdayValue
      tourdayRetailPricePercent
      updatedAt
    }
    brand {
      id
      title
    }
    category {
      id
      title
    }
    option
    id
    insertedAt
    option
    updatedAt
  }
  ${PurchaseRequestAttrs}
  ${GolfEquipmentAttr}
  ${PurchaseRequestAttrs}
  ${BrandAttrs}
  ${OptionAttrs}
  ${EquipmentCategoryAttrs}
`

export const GolfEquipmentModelAttrs = gql`
  fragment GolfEquipmentModelAttrs on GolfEquipmentModel {
    additionalInformation
    availabilityStatus
    avatar
    avatars
    brand {
      ...BrandAttrs
    }
    care
    category {
      ...EquipmentCategoryAttrs
    }
    color
    description
    features
    fit
    gender
    golfEquipmentTypes {
      ...GolfEquipmentTypeAttrs
    }
    highestBuyPrice
    id
    images
    insertedAt
    isLimited
    lowestSellPrice
    optionTitle
    options {
      ...OptionAttrs
    }
    quantityMade
    conditions
    releaseYear
    retailPrice
    slug
    style
    subCategory {
      id
      insertedAt
      title
      updatedAt
    }
    tickerSymbol
    title
    updatedAt
  }
  ${BrandAttrs}
  ${EquipmentCategoryAttrs}
  ${OptionAttrs}
  ${GolfEquipmentAttr}
  ${GolfEquipmentTypeAttrs}
`
