import { gql } from '@apollo/client'
import { PurchaseRequestAttr, PurchaseRequestResponseAttr, ValidationMessageAttrs } from '@fragments'

export const FetchPurchaseRequest = gql`
  query FetchPurchaseRequest($id: UUID4!) {
    res: purchaseRequest(id: $id) {
      ...PurchaseRequestAttr
    }
  }

  ${PurchaseRequestAttr}
`

export const FetchPaginatedPurchaseRequests = gql`
  query FetchPaginatedPurchaseRequests($page: Int, $pageSize: Int, $status: PurchaseRequestStatus) {
    res: paginatedPurchaseRequests(page: $page, pageSize: $pageSize, status: $status) {
      entries {
        expiresAt
        itemPrice
        id
        insertedAt
        status
        updatedAt
        golfClubType {
          id
          insertedAt
          option
          updatedAt
          category {
            id
            title
          }
          brand {
            id
            title
          }
          golfClubModel {
            id
            slug
            title
            avatar
          }
        }

        golfEquipmentType {
          id
          insertedAt
          updatedAt
          option
          category {
            id
            title
          }
          brand {
            id
            title
          }
          golfEquipmentModel {
            id
            slug
            title
            avatar
          }
        }
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
`

export const CreatePurchaseRequest = gql`
  mutation CreatePurchaseRequest($purchaseRequest: PurchaseRequestParams) {
    res: createPurchaseRequest(purchaseRequest: $purchaseRequest) {
      messages {
        code
        field
        message
        options {
          key
          value
        }
        template
      }
      result {
        id
        buyerSalesTax
        buyerShippingPrice
        buyerTotalPrice
        expiresAt
        itemPrice
        insertedAt
        sellerProcessingFee
        sellerProfit
        sellerShippingPrice
        sellerTotalPrice
        status
        updatedAt
        user {
          id
        }
      }
      successful
    }
  }
`

export const UpdatePurchaseRequest = gql`
  mutation updatePurchaseRequest($id: UUID4!, $purchaseRequest: PurchaseRequestParams) {
    res: updatePurchaseRequest(id: $id, purchaseRequest: $purchaseRequest) {
      ...PurchaseRequestResponseAttr
    }
  }

  ${PurchaseRequestResponseAttr}
`

export const DeletePurchaseRequest = gql`
  mutation DeletePurchaseRequest($id: UUID4!) {
    res: deletePurchaseRequest(id: $id) {
      messages {
        ...ValidationMessageAttrs
      }
      successful
      result {
        id
      }
    }
  }
  ${ValidationMessageAttrs}
`
export const ExtendPurchaseRequest = gql`
  mutation ExtendPurchaseRequest($expiresAt: Date!, $id: UUID4!) {
    res: extendPurchaseRequest(expiresAt: $expiresAt, id: $id) {
      messages {
        field
        code
        message
        template
        options {
          value
          key
        }
      }
      successful
      result {
        ...PurchaseRequestAttr
      }
    }
  }

  ${PurchaseRequestAttr}
`

export const DeleteMultiplyPurchaseRequests = gql`
  mutation DeletePurchaseRequests($ids: [UUID4]) {
    deletePurchaseRequests(ids: $ids) {
      successful
    }
  }
`
