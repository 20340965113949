import React, { memo } from 'react'
import { useFormContext, RegisterOptions, FieldError } from 'react-hook-form'
import { handleEvent } from '@utils'
import { ValidationErrorMessage, ValidationErrorMessageType } from '@elements'
import { Children } from '@types'
import TimesImage from '@images/times.svg'
import NotAllowedImage from '@images/not-allowed.svg'
import styles from './Input.module.scss'

const BACKSPACE_KEY = 'Backspace'

export type InputSuffixProp = JSX.Element | string | number
export interface InputProps {
  type?: string
  name?: string
  label?: string
  value?: string
  defaultValue?: string
  placeholder?: string
  prefix?: InputSuffixProp
  suffix?: InputSuffixProp
  validation?: RegisterOptions
  onChange?: (value: string) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onTimesClick?: () => void
  noCursor?: boolean
  disabled?: boolean
  direction?: 'horizontal' | 'vertical'
  autoComplete?: 'on' | 'off' | string
  role?: string
  forSelect?: boolean
  noTimes?: boolean
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  error?: FieldError
  renderError?(error: ValidationErrorMessageType, className: string): Children
  theme?: 'neutral' | 'green'
}

const Input: React.FC<InputProps> = props => {
  const {
    theme = 'neutral',
    type,
    name,
    label,
    value,
    defaultValue,
    placeholder,
    autoComplete,
    prefix,
    suffix,
    validation,
    onChange,
    noCursor,
    onFocus,
    onBlur,
    onKeyDown,
    onTimesClick,
    disabled,
    direction,
    role,
    forSelect,
    noTimes,
    min,
    max,
    minLength,
    maxLength,
    renderError
  } = props

  const formContext = useFormContext()
  const register = formContext && formContext.register
  const trigger = formContext && formContext.trigger
  const setValue = formContext && formContext.setValue
  const errors = formContext && formContext.errors
  const error = props.error || (errors && name && errors[name])

  /* Is required check */
  const isRequired = !!validation?.required
  /* Register input in parent form */
  const ref = value === undefined ? register && register(validation) : null
  /* If disabled no focus */
  const tabIndex = disabled ? -1 : 0

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleEvent(onBlur, { value: e, disabled })
    handleEvent(trigger, { value: name, disabled })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleEvent(onChange, { value: e.currentTarget.value, disabled })
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => handleEvent(onFocus, { value: e, disabled })
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isValidKey = type === 'number' ? e.key === BACKSPACE_KEY || !isNaN(+e.key) : true
    if (!isValidKey) {
      e.preventDefault()
      return
    }
    handleEvent(onKeyDown, { value: e, disabled })
  }
  const handleClear = () => {
    if (!disabled) {
      if (onChange) {
        onChange('')
      }

      if (setValue && name) {
        setValue(name, '', {
          shouldValidate: true
        })
      }
    }
  }

  const isSuffix = suffix || !noTimes || disabled

  return (
    <div className={`${styles.input} ${styles[`input${theme}`]}`} data-disabled={disabled} data-direction={direction}>
      {label && (
        <label htmlFor={name} data-required={isRequired}>
          {label}
        </label>
      )}
      <div className={styles.inputInner}>
        <input
          id={name}
          type={type}
          name={name}
          ref={ref}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          data-disabled={disabled}
          data-cursor={!noCursor}
          data-prefix={!!prefix}
          data-suffix={!!suffix}
          data-error={!!error}
          data-times={!noTimes}
          role={role}
          tabIndex={tabIndex}
          min={min}
          max={max}
          minLength={minLength}
          maxLength={maxLength}
          theme={theme}
        />
        {prefix && <div className={styles.inputInnerPrefix}>{prefix}</div>}
        {isSuffix && (
          <div className={styles.inputInnerSuffix}>
            {!noTimes && <TimesImage className={styles.inputInnerSuffixTimes} onClick={onTimesClick || handleClear} />}
            {disabled && <NotAllowedImage className={styles.inputInnerSuffixNotAllowed} />}
            {suffix}
          </div>
        )}
      </div>
      {!forSelect && <ValidationErrorMessage render={renderError} name={name || ''} />}
    </div>
  )
}

Input.defaultProps = {
  type: 'text',
  placeholder: 'Enter text',
  autoComplete: 'on',
  direction: 'vertical'
}

export default memo(Input)
