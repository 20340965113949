import React, { memo, forwardRef } from 'react'
import { handleEvent } from '@utils'
import './RadioButton.scss'

export interface RadioButtonProps {
  name: string | undefined
  label?: string | HTMLElement
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  required?: boolean
  onChange?: (value: boolean) => void
  onFocus?: (e: React.FocusEvent<HTMLLabelElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLLabelElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLLabelElement>) => void
}

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>((props, ref) => {
  const { name, label, checked, defaultChecked, disabled, required, onChange } = props

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleEvent(onChange, { value: e.currentTarget.value, disabled })

  return (
    <div className={'radio'} data-disabled={disabled}>
      <input
        type={'radio'}
        className={'radio-input'}
        id={name}
        name={name}
        ref={ref}
        onChange={handleChange}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        tabIndex={-1}
      />
      <div className={'radio-indicator'}>
        <div className={'radio-indicator-check'} />
      </div>
      {label && (
        <label htmlFor={name} className={'radio-label'} data-required={required}>
          {label}
        </label>
      )}
    </div>
  )
})

RadioButton.displayName = 'RadioButton'
RadioButton.defaultProps = {}

export default memo(RadioButton)
