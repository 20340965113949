import { useState } from 'react'
import { DocumentNode, QueryHookOptions, useQuery } from '@apollo/client'
import { FiltersArgs, QueryPaginationArgs, PaginationType, ResponseType } from '@types'

const usePagination = <TData, TVariables = Omit<QueryPaginationArgs, 'pagination'>>(
  query: DocumentNode,
  variables: TVariables,
  options?: Omit<QueryHookOptions, 'variables'>,
  initialPage = 1,
  initialPageSize = 4
) => {
  const [[page, pageSize], setPage] = useState([initialPage, initialPageSize])
  /* Runs query */
  const paginatedQueryData = useQuery<ResponseType<PaginationType<TData>>, TVariables>(query, {
    variables: { ...variables, pageSize: 18 },
    ...options
  })

  /* Defines fetchMore function to control pagination */
  const fetchMore = async (variables: TVariables, forcePage?: number, forcePageSize?: number) => {
    setPage([forcePage || page + 1, forcePageSize || pageSize])

    return paginatedQueryData.fetchMore({
      variables: {
        ...variables,
        page: forcePage || page + 1,
        pageSize: forcePageSize || pageSize
      }
    })
  }

  const refetch = async (filters: FiltersArgs, sortBy?: string) => {
    /* Refetch the same query with filters */

    const refetchedPaginatedQueryData = await paginatedQueryData.refetch({
      ...variables,
      ...filters,
      sortBy
    })

    const { data } = refetchedPaginatedQueryData
    const content = data?.res.entries
    const totalPages = data?.res.totalPages

    /*
      If after filtering we have no content, we should use fetchMore to set a new page.
    */
    if (content && !content.length && totalPages) {
      const lastPage = totalPages! || 1
      return fetchMore(
        {
          ...variables,
          ...filters
        },
        lastPage
      )
    }

    return refetchedPaginatedQueryData
  }

  /* Removes unnecessary data */
  const { fetchMore: _, refetch: __, data, ...usePaginationData } = paginatedQueryData

  const content = data?.res?.entries
  const pagination = {
    pageNumber: data?.res?.pageNumber || 0,
    pageSize: data?.res?.pageSize || 0,
    totalEntries: data?.res?.totalEntries || 0,
    totalPages: data?.res?.totalPages || 0
  }

  return {
    ...usePaginationData,
    content,
    pagination,
    fetchMore,
    refetch
  }
}

export default usePagination
