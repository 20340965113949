import React, { memo } from 'react'
import { RenderPaginationProps } from 'react-elastic-carousel'
import styles from './Bullets.module.scss'

interface BulletsProps extends RenderPaginationProps {}

const Bullets: React.FC<BulletsProps> = props => {
  const { pages, onClick, activePage } = props

  return (
    <div className={styles.bullets}>
      {pages.map((page, index) => {
        return (
          <div
            key={index}
            className={styles.bullet}
            onClick={() => onClick(page.toString())}
            data-active={page === activePage}
          />
        )
      })}
    </div>
  )
}

export default memo(Bullets)
