export enum CatalogItemType {
  GOLF_CLUB_MODEL = 'GOLF_CLUB_MODEL',
  GOLF_EQUIPMENT_MODEL = 'GOLF_EQUIPMENT_MODEL'
}

export enum GolfClubCondition {
  EXCELLENT = 'EXCELLENT',
  GOOD = 'GOOD',
  NEW = 'NEW'
}

export enum GolfItemType {
  GOLF_CLUB = 'GOLF_CLUB',
  GOLF_EQUIPMENT = 'GOLF_EQUIPMENT'
}

export enum GolfEquipmentStatus {
  EXPIRED = 'EXPIRED',
  IN_PROCESS = 'IN_PROCESS',
  ON_SALE = 'ON_SALE',
  SOLD = 'SOLD',
  TAXES_PAYMENT_ERROR = 'TAXES_PAYMENT_ERROR',
  UNSOLD = 'UNSOLD',
  WAIT_FOR_PAYMENT = 'WAIT_FOR_PAYMENT'
}

export enum DeviceType {
  BOTH = 'BOTH',
  COMPUTER = 'COMPUTER',
  MOBILE = 'MOBILE'
}

export enum CatalogType {
  BOTH = 'BOTH',
  CLUBS = 'CLUBS',
  EQUIPMENTS = 'EQUIPMENTS'
}

export enum CatalogSorting {
  LATEST_ACTIVITY = 'LATEST_ACTIVITY',
  MOST_POPULAR = 'MOST_POPULAR',
  NEWLY = 'NEWLY',
  PRICE_ASC = 'PRICE_ASC',
  PRICE_DESC = 'PRICE_DESC',
  RELEASE_YEAR_ASC = 'RELEASE_YEAR_ASC',
  RELEASE_YEAR_DESC = 'RELEASE_YEAR_DESC'
}

export enum ExistingProductFeedbackDescriptionType {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

export enum GolfClubModelAvailableType {
  ALL = 'ALL',
  BUY = 'BUY',
  SELL = 'SELL'
}
