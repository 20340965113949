import { gql } from '@apollo/client'

export const FetchOptionClassByTag = gql`
  query OptionClassByTag($tag: String!) {
    res: optionClassByTag(tag: $tag) {
      id
      insertedAt
      options {
        id
        title
      }
      title
    }
  }
`
