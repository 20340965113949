import { gql } from '@apollo/client'
export * from './commonTypes'
export * from './response'
export * from './user'
export * from './golfClubModel'
export * from './golfClub'
export * from './addressCard'
export * from './order'
export * from './golfClubType'
export * from './purchaseRequest'
export * from './golfClubDraft'
export * from './csv'
export * from './promoCode'
export * from './section'
export * from './equipment'
export * from './golfEquipmentModel'
export * from './locker'

export const PaginationAttrs = gql`
  fragment PaginationAttrs on PaginationType {
    pageNumber
    pageSize
    totalEntries
    totalPages
  }
`
