import { MutationFunctionOptions, MutationHookOptions, useMutation } from '@apollo/client'
import { golfClub as graphqlGolfClub, golfItems as graphqlGolfItems } from '@graphql'
import { useRequestErrorHandler } from '@hooks'
import { CreateGolfClubArgs, GolfClub, MutationResponse, ResponseType, UpdateGolfClubArgs } from '@types'
import { useAlert } from 'react-alert'
import { GolfClubAttrs } from '@fragments'

type GolfClubResponse = ResponseType<MutationResponse & { result: GolfClub }>

const useGraphqlGolfClub = () => {
  const handleRequestError = useRequestErrorHandler()
  const alert = useAlert()

  const [createGolfClubMutation, { loading: createGolfClubLoading }] = useMutation<
    GolfClubResponse,
    { golfClub: CreateGolfClubArgs }
  >(graphqlGolfClub.CreateGolfClub, { onError: error => handleRequestError(null, error) })

  const [updateGolfClubMutation, { loading: updateGolfClubLoading }] = useMutation<
    GolfClubResponse,
    UpdateGolfClubArgs
  >(graphqlGolfClub.UpdateGolfClub, {
    onError: error => handleRequestError(null, error)
  })

  const [deleteGolfClubMutation, { loading: deleteGolfClubLoading }] = useMutation<GolfClubResponse, { id: string }>(
    graphqlGolfClub.DeleteGolfClub,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const [extendGolfClubMutation, { loading: extendGolfClubLoading }] = useMutation<GolfClubResponse>(
    graphqlGolfClub.ExtendGolfClub,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const [republishGolfClubMutation, { loading: republishGolfClubLoading }] = useMutation<GolfClubResponse>(
    graphqlGolfClub.RepublishGolfClub,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const [deleteMultipleGolfClubsMutation, { loading: deleteMultipleGolfClubsLoading }] = useMutation<
    ResponseType<MutationResponse & { result: undefined }>
  >(graphqlGolfClub.DeleteMultipleGolfClub, { onError: error => handleRequestError(null, error) })

  const createGolfClub = async (golfClub: CreateGolfClubArgs, options?: MutationFunctionOptions) => {
    const request = await createGolfClubMutation({
      ...(options || {}),
      variables: { golfClub },
      update(cache, mutationResult) {
        const result = mutationResult.data?.res.result
        const successful = mutationResult.data?.res.successful
        if (!successful) return null

        cache.modify({
          fields: {
            paginatedGolfClubs(existing) {
              return {
                ...existing,
                entries: [result, ...(existing?.entries || [])]
              }
            }
          }
        })
      }
    })

    return handleRequestError<GolfClub>(request)
  }

  const updateGolfClub = async (updateArgs: UpdateGolfClubArgs) => {
    const { id } = updateArgs
    const updateRequest = await updateGolfClubMutation({
      variables: { ...updateArgs },
      update(cache, mutationResult) {
        const success = mutationResult.data?.res?.successful
        const result = mutationResult.data?.res?.result
        if (success) {
          const normalizedId = cache.identify({ id, __typename: 'GolfClub' })
          cache.modify({
            id: normalizedId,
            fields(existingValue, { fieldName }) {
              const newValue = result?.[fieldName as keyof GolfClub]
              return newValue || existingValue
            }
          })
          alert.show(`Golf club successfully updated!`, {
            type: 'success'
          })
        }
      }
    })

    return handleRequestError<GolfClub>(updateRequest)
  }

  const deleteGolfClub = async (id: string, options?: Omit<MutationHookOptions, 'variables'>) => {
    const deleteRequest = await deleteGolfClubMutation({
      variables: { id },
      ...(options || {}),
      update: (cache, mutationResult) => {
        const successful = mutationResult?.data?.res?.successful

        if (!successful) return null

        const normalizedId = cache.identify({ id, __typename: 'GolfItem' })
        cache.evict({ id: normalizedId })
        cache.gc()

        alert.show(`Golf club deleted!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError<GolfClub>(deleteRequest)
  }

  const extendGolfClub = async (id: string, expiresAt: string) => {
    const extendRequest = await extendGolfClubMutation({
      variables: { id, expiresAt },
      update(cache, mutationResult) {
        const success = mutationResult.data?.res.successful
        if (success) {
          const normalizedId = cache.identify({ id, __typename: 'GolfClub' })
          cache.modify({
            id: normalizedId,
            fields: {
              expiresAt() {
                return expiresAt
              }
            }
          })
          alert.show(`Golf club term extended for 10 days!`, {
            type: 'success'
          })
        }
      }
    })
    return handleRequestError<GolfClub>(extendRequest)
  }

  const republishGolfClub = async (id: string, expiresAt: string, paymentCardId: string) => {
    const republishRequest = await republishGolfClubMutation({
      variables: { id, expiresAt, paymentCardId },
      update(cache, mutationResult) {
        const success = mutationResult.data?.res.successful
        const result = mutationResult.data?.res.result

        const normalizedId = cache.identify({ id, __typename: 'GolfClub' })
        cache.writeFragment({
          fragment: GolfClubAttrs,
          id: normalizedId,
          data: { ...result }
        })
        if (success) {
          alert.show(`Golf club successfully  republished!`, {
            type: 'success'
          })
        }
      }
    })

    return handleRequestError<GolfClub>(republishRequest)
  }

  const deleteMultipleGolfClubs = async (ids: string[], options?: MutationHookOptions) => {
    const request = await deleteMultipleGolfClubsMutation({
      variables: { ids },
      ...(options || {}),
      update(cache, mutationResult) {
        const successful = mutationResult?.data?.res?.successful

        if (!successful) return null

        ids.forEach(id => {
          const normalizedId = cache.identify({ id, __typename: 'GolfClub' })
          cache.evict({ id: normalizedId })
        })

        alert.show(`Golf club deleted!`, {
          type: 'success'
        })
        cache.gc()
      }
    })
    return handleRequestError<undefined>(request)
  }

  const [createGolfClubModelAlert, { loading: createGolfClubModelAlertLoading }] = useMutation<
    ResponseType<MutationResponse>
  >(graphqlGolfClub.CreateGolfClubModelAlert, {
    update(cache, mutationResult) {
      const error = mutationResult.data?.res?.messages[0]?.message || ''
      if (mutationResult.data?.res.successful || error === 'has already been taken')
        alert.show(`Successful!`, {
          type: 'success'
        })
      else
        alert.show(error, {
          type: 'error'
        })
    }
  })

  return {
    createGolfClub,
    updateGolfClub,
    deleteGolfClub,
    extendGolfClub,
    republishGolfClub,
    deleteMultipleGolfClubs,
    createGolfClubModelAlert,
    loading:
      deleteGolfClubLoading ||
      updateGolfClubLoading ||
      createGolfClubLoading ||
      deleteMultipleGolfClubsLoading ||
      extendGolfClubLoading ||
      republishGolfClubLoading ||
      createGolfClubModelAlertLoading
  }
}

export default useGraphqlGolfClub
