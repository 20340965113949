import React, { useState } from 'react'
import { Children, OptionType } from '@types'
import Arrow from '@images/collapse-arrow.svg'
import styles from './MobileSaleCard.module.scss'

interface MobileSaleCardProps {
  data?: OptionType[]
  title: string
  price: number
}

const MobileSaleCard: React.FC<MobileSaleCardProps> = props => {
  const { data, title, price } = props
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const handleToggle = () => {
    setIsOpened(!isOpened)
  }

  return (
    <div onClick={handleToggle} data-opened={isOpened} className={styles.item}>
      {/* <div data-opened={isOpened} className={styles.heading}>
        <div className={styles.headingTitle}>{title}</div>
        <div className={styles.headingTitle}>{'$' + price}</div>
      </div> */}
      <Arrow data-opened={isOpened} className={styles.itemArrow} />
      <div data-opened={isOpened} className={styles.body}>
        {data &&
          data.map(item => {
            return (
              <div key={item.label} className={styles.bodyRow}>
                <div className={styles.bodyRowLabel}>
                  <div>{item.label}</div>
                </div>
                <div className={styles.bodyRowValue}>{item.value}</div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default MobileSaleCard
