import React from 'react'
import { useQuery } from '@apollo/client'
import { Select, SelectProps } from '@components'
import { CacheUser, ResponseType } from '@types'
import { user as graphqlUser } from '@graphql'
import styles from './AddressCardSelect.module.scss'

export interface AddressCardSelectProps extends Omit<SelectProps, 'options'> {}

const AddressCardSelect: React.FC<AddressCardSelectProps> = props => {
  const { data } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const user = (data && data.res) || ({} as CacheUser)
  const addressCards = user.addressCards || []

  const addressCardOptions = addressCards.map(addressCard => {
    const { name, id } = addressCard

    return {
      label: name,
      value: id
    }
  })

  return (
    <div className={styles.addressCardSelect}>
      <Select options={addressCardOptions} {...props} />
    </div>
  )
}

export default AddressCardSelect
