import React, { memo } from 'react'
import style from './SettingsSwitcher.module.scss'

interface SettingsSwitcherProps {
  label: string
  handleMySettings: () => void
  mySettings: string[]
}

const SettingsSwitcher: React.FC<SettingsSwitcherProps> = props => {
  const { label, handleMySettings, mySettings } = props

  return (
    <div className={style.container}>
      <div className={style.label}>{label}</div>
      <div className={style.toggle}>
        <label htmlFor="toggle">
          <input className={style.board} id="toggle" type="checkbox" onChange={handleMySettings} />
          <div
            className={style.indicator}
            style={{
              backgroundColor: mySettings.includes('true') ? '#000000' : '#6e6e6e',
              transform: mySettings.includes('true') ? 'translate3d(120%, 0, 0)' : 'translate3d(0, 0, 0)'
            }}
          ></div>
        </label>
      </div>
    </div>
  )
}

export default memo(SettingsSwitcher)
