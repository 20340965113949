import loadable from '@loadable/component'
import React, { memo } from 'react'

const HomeModal = loadable(() => import('./HomeModal/HomeModal'))
const ShareModal = loadable(() => import('./ShareModal/ShareModal'))
const AvatarUploadModal = loadable(() => import('./AvatarUploadModal/AvatarUploadModal'))
const AccountSetupModal = loadable(() => import('./AccountSetupModal/AccountSetupModal'))
const AddBagGolfClubModelModal = loadable(() => import('./AddBagGolfClubModelModal/AddBagGolfClubModelModal'))
const PurchaseSelectionModal = loadable(() => import('./PurchaseSelectionModal/PurchaseSelectionModal'))
const UpdateBagGolfClubModelModal = loadable(() => import('./UpdateBagGolfClubModelModal/UpdateBagGolfClubModelModal'))
const StarterSetModal = loadable(() => import('./StarterSetModal/StarterSetModal'))
const MarketDataModal = loadable(() => import('./MarketDataModal/MarketDataModal'))
const UploadCsvModal = loadable(() => import('./UploadCsvModal/UploadCsvModal'))
const BuyingOwnGolfclubModal = loadable(() => import('./BuyingOwnGolfclubModal/BuyingOwnGolfclubModal'))
const AddBoughtGolfClubToBagModal = loadable(() => import('./AddBoughtGolfClubToBagModal/AddBoughtGolfClubToBagModal'))
const SellerPaymentMethodWarningModal = loadable(
  () => import('./SellerPaymentMethodWarningModal/SellerPaymentMethodWarningModal')
)
const SuggestChangesModal = loadable(() => import('./SuggestChangesModal/SuggestChangesModal'))
const DeleteSimilarGolfClubsModal = loadable(() => import('./DeleteSimilarGolfClubsModal/DeleteSimilarGolfClubsModal'))
const ConditionGuideExampleModal = loadable(() => import('./ConditionGuideExampleModal/ConditionGuideExampleModal'))
const GolfClubModelAlert = loadable(() => import('./GolfClubModelAlert/GolfClubModelAlert'))

// here should be next modals

type Modal =
  | typeof HomeModal
  | typeof AddBagGolfClubModelModal
  | typeof UpdateBagGolfClubModelModal
  | typeof ShareModal
  | typeof AvatarUploadModal
  | typeof AccountSetupModal
  | typeof MarketDataModal
  | typeof UploadCsvModal
  | typeof BuyingOwnGolfclubModal
  | typeof AddBoughtGolfClubToBagModal
  | typeof SellerPaymentMethodWarningModal
  | typeof DeleteSimilarGolfClubsModal
  | typeof ConditionGuideExampleModal
  | typeof SuggestChangesModal
  | typeof StarterSetModal
  | typeof GolfClubModelAlert
// | here should be next modals

type Modals = Modal[]

// Modals
const modals: Modals = [
  HomeModal,
  AddBagGolfClubModelModal,
  UpdateBagGolfClubModelModal,
  ShareModal,
  AvatarUploadModal,
  AccountSetupModal,
  SuggestChangesModal,
  MarketDataModal,
  UploadCsvModal,
  BuyingOwnGolfclubModal,
  AddBoughtGolfClubToBagModal,
  SellerPaymentMethodWarningModal,
  DeleteSimilarGolfClubsModal,
  ConditionGuideExampleModal,
  StarterSetModal,
  GolfClubModelAlert,
  PurchaseSelectionModal
  // here should be next modals
]

// Modals container
// It takes modalName and render necessary modal
const Modals = () => {
  return (
    <div id={'modals'}>
      {modals.map((Modal, index) => {
        return <Modal key={index} />
      })}
    </div>
  )
}

export default memo(Modals)
