import { gql } from '@apollo/client'
import { ValidationMessageAttrs } from '@fragments'

export const createSearchQuery = gql`
  mutation createSearchQuery($searchQuery: SearchQueryParams) {
    res: createSearchQuery(searchQuery: $searchQuery) {
      messages {
        ...ValidationMessageAttrs
      }
      successful
    }
  }
  ${ValidationMessageAttrs}
`
