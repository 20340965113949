import React, { memo, useEffect, useRef, useState } from 'react'
import { List, ListProps } from '@components'
import { useOnScreen } from '@hooks'
import { PaginationType } from '@types'
import styles from './PaginatedList.module.scss'

export interface PaginatedListProps extends ListProps {
  pagination?: PaginationType
  onPageClick?: (page: number) => void
  className?: string
  fetchMore: () => Promise<void>
  secondLoading?: boolean
}

const PaginatedList: React.FC<PaginatedListProps> = props => {
  const { className, fetchMore, ...listProps } = props
  const [isFetch, setIsFetch] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  const isFetchTriggerOnScreen = useOnScreen(ref, '500px')

  useEffect(() => {
    if (ref.current && isFetchTriggerOnScreen && !isFetch && !listProps.loading) {
      setIsFetch(true)
    }
  }, [isFetchTriggerOnScreen, listProps.loading])

  useEffect(() => {
    if (isFetch && !listProps.loading) {
      fetchMore().then(() => {
        setIsFetch(false)
      })
    }
  }, [isFetch, listProps?.loading])

  return (
    <div className={`${styles.paginatedList} ${className}`}>
      <List isFetch={isFetch} {...listProps} />
      <div className={styles.paginatedListRef} ref={ref} />
    </div>
  )
}

export default memo(PaginatedList)
