import React, { memo } from 'react'
import { LocationTabs } from '@components'
import { useQuery } from '@apollo/client'
import { Filters } from '@types'
import { filters } from '@graphql'
import { ResponseType } from '@types'
import { getOptionsBySchema } from '@utils'

interface CategoryLocationTabsProps {}

const CategoryLocationTabs: React.FC<CategoryLocationTabsProps> = props => {
  const { data } = useQuery<ResponseType<Filters>>(filters.FetchFilters, { fetchPolicy: 'cache-only' })
  const categories = data ? data.res.categories : []
  const tabOptionSchema = {
    value: 'id',
    label: 'name'
  }
  const locationTabs = getOptionsBySchema(categories, tabOptionSchema)

  return <LocationTabs locationTabs={locationTabs} query={'categoryIds'} />
}

export default memo(CategoryLocationTabs)
