import React from 'react'
import { Select, SelectProps } from '@components'
import { Input } from '@elements'
import { useQuery } from '@apollo/client'
import { Filters, ResponseType } from '@types'
import { filters as graphqlFilters } from '@graphql'
import styles from './GenderSelect.module.scss'

export interface GenderSelectProps extends Omit<SelectProps, 'options'> {}

const GenderSelect: React.FC<GenderSelectProps> = props => {
  // const { data } = useQuery<ResponseType<Filters>>(graphqlFilters.FetchFilters, {
  //   fetchPolicy: 'cache-only'
  // })
  // const filters = data ? data.res : ({} as Filters)
  // const { genders } = filters

  const genderOptions = ['Male', 'Female', 'Prefer Not to Say'].map(gender => {
    return {
      label: gender,
      value: gender
    }
  })

  return (
    <div className={styles.genderSelect}>
      <Select options={genderOptions} {...props} />
      {/* <Input placeholder={'Enter height'} /> */}
    </div>
  )
}

export default GenderSelect
