import { gql } from '@apollo/client'
import { GolfClubDraftAttrs, GolfClubDraftResponseAttrs } from '@fragments'

export const FetchPaginatedGolfClubDrafts = gql`
  query FetchPaginatedGolfClubDrafts($page: Int, $pageSize: Int) {
    res: paginatedGolfClubDrafts(page: $page, pageSize: $pageSize) {
      entries {
        ...GolfClubDraftAttrs
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }

  ${GolfClubDraftAttrs}
`

export const CreateGolfClubDraft = gql`
  mutation CreateGolfClubDraft($golfClubDraft: GolfClubDraftParams) {
    res: createGolfClubDraft(golfClubDraft: $golfClubDraft) {
      ...GolfClubDraftResponseAttrs
    }
  }

  ${GolfClubDraftResponseAttrs}
`

export const DeleteGolfClubDraft = gql`
  mutation DeleteGolfClubDraft($id: UUID4!) {
    res: deleteGolfClubDraft(id: $id) {
      ...GolfClubDraftResponseAttrs
    }
  }

  ${GolfClubDraftResponseAttrs}
`

export const UpdateGolfClubDraft = gql`
  mutation UpdateGolfClubDraft($golfClubDraft: GolfClubDraftParams, $id: UUID4!) {
    res: updateGolfClubDraft(golfClubDraft: $golfClubDraft, id: $id) {
      ...GolfClubDraftResponseAttrs
    }
  }

  ${GolfClubDraftResponseAttrs}
`
