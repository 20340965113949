import React, { useEffect, useState } from 'react'
import { OptionType } from '@types'

export type WithOptionSelectProps = {
  value: string | null
  options: OptionType[]
  onChange?: (value: string | null) => void
}

export type WithOptionSelectState = {
  selectedOption: OptionType | null
}

export type WithOptionSelectPropsPassed = {
  value: string | null
  options: WithOptionSelectProps['options']
  selectedOption: WithOptionSelectState['selectedOption']
  handleOptionSelect: (option: OptionType | null) => void
}

const withOptionSelect = <P,>(WrappedComponent: React.ComponentType<WithOptionSelectPropsPassed & P>) => {
  const OptionSelect: React.FC<WithOptionSelectProps & P> = props => {
    const { value, options, onChange, ...parentProps } = props

    const [isDynamicOptions, setIsDynamicOptions] = useState<boolean>(false)
    const [state, changeState] = useState<WithOptionSelectState>({
      selectedOption: null
    })

    /* Like React.Component setState function */
    const setState = (nextState: Partial<WithOptionSelectState>) => {
      changeState({ ...state, ...nextState })
    }

    /*
      If we have value but no options available we won't have any selected option even options will come from backend
      If options should be requested from backend we set isDynamicOptions when mounted.
    */
    useEffect(() => {
      if (!options.length) {
        setIsDynamicOptions(true)
      }
    }, [])

    /* If options were came we rerun initial value selecting */
    useEffect(() => {
      if (options.length) {
        if (isDynamicOptions) {
          const nextSelectedOption = options.find(option => option.value === value)
          if (nextSelectedOption) handleOptionSelect(nextSelectedOption)

          /* Disables dynamic flag logic completed */
          setIsDynamicOptions(false)
        }
      }
    }, [options])

    /*
      @Attention: Don't work if no options came on initial render. See useEffects above
      Set selectedOption from props value
    */
    useEffect(() => {
      if (!value) {
        handleOptionSelect(null)
      } else {
        const nextSelectedOption = options.find(option => option.value === value)
        if (nextSelectedOption) handleOptionSelect(nextSelectedOption)
      }
    }, [value])

    /* Handles option selecting and calls props.onSelect */
    const handleOptionSelect = (option: OptionType | null) => {
      setState({ selectedOption: option })

      const optionValue = option && option.value
      onChange && onChange(optionValue)
    }

    return (
      <WrappedComponent
        value={value}
        options={options}
        selectedOption={state.selectedOption}
        handleOptionSelect={handleOptionSelect}
        {...(parentProps as P)}
      />
    )
  }

  OptionSelect.defaultProps = ({
    value: null,
    options: [],
    onSelect: () => null
  } as unknown) as WithOptionSelectProps & P

  return OptionSelect
}

export default withOptionSelect
