import React from 'react'
import { ButtonWithPlus, ButtonWithPlusProps } from '@elements'
import styles from './NoDataYet.module.scss'

export interface NoDataYetProps extends Omit<ButtonWithPlusProps, 'label'> {
  message?: string
  button: string
}

const NoDataYet: React.FC<NoDataYetProps> = props => {
  const { message, button, onClick } = props

  return (
    <div className={styles.noDataYet}>
      <h5>{message}</h5>
      <ButtonWithPlus onClick={onClick}>{button}</ButtonWithPlus>
    </div>
  )
}

export default NoDataYet
