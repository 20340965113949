import { useMutation } from '@apollo/client'
import { navigate } from 'gatsby'
import { useAlert } from 'react-alert'
import { CreateGolfClubDraftArgs, GolfClubDraft, MutationResponse, ResponseType, UpdateGolfClubDraftArgs } from '@types'
import { useRequestErrorHandler } from '@hooks'
import { golfClubDraft as graphqlGolfClubDraft } from '@graphql'

type GolfClubDraftResponse = ResponseType<MutationResponse & { result: GolfClubDraft }>

const useGraphqlGolfClubDraft = () => {
  const handleRequestError = useRequestErrorHandler()
  const alert = useAlert()

  const [createGolfClubDraftMutation] = useMutation<GolfClubDraftResponse, { golfClubDraft: CreateGolfClubDraftArgs }>(
    graphqlGolfClubDraft.CreateGolfClubDraft,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const [updateGolfClubDraftMutation] = useMutation<GolfClubDraftResponse, UpdateGolfClubDraftArgs>(
    graphqlGolfClubDraft.UpdateGolfClubDraft,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const [deleteGolfClubDraftMutation] = useMutation<GolfClubDraftResponse, { id: string }>(
    graphqlGolfClubDraft.DeleteGolfClubDraft,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const createGolfClubDraft = async (golfClubDraftArgs: CreateGolfClubDraftArgs) => {
    const request = await createGolfClubDraftMutation({
      variables: { golfClubDraft: golfClubDraftArgs },
      update(cache, mutationResult) {
        const success = mutationResult.data?.res.successful

        if (success) {
          alert.show(`Golf club draft successfully created!`, {
            type: 'success'
          })
        }
      }
    })

    await navigate('/account/store/')
    return handleRequestError(request)
  }

  const updateGolfClubDraft = async (golfClubDraftArgs: CreateGolfClubDraftArgs, golfClubDraftId: string) => {
    const request = await updateGolfClubDraftMutation({
      variables: { golfClubDraft: golfClubDraftArgs, id: golfClubDraftId },
    })
    const success = request.data?.res.successful

    if (success) {
      alert.show(`Golf club draft successfully updated!`, {
        type: 'success'
      })
      await navigate('/account/store/')
    }

    return handleRequestError(request)
  }

  const deleteGolfClubDraft = async (id: string) => {
    const request = await deleteGolfClubDraftMutation({
      variables: { id }
    })
    const success = request.data?.res.successful

    if (success) {
      alert.show(`Golf club draft successfully deleted!`, {
        type: 'success'
      })
    } else {
      alert.show(`Error, something went wrong!`, {
        type: 'error'
      })
    }

    return handleRequestError(request)
  }

  return { createGolfClubDraft, updateGolfClubDraft, deleteGolfClubDraft }
}

export default useGraphqlGolfClubDraft
