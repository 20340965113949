import React, { memo } from 'react'
import { useQuery } from '@apollo/client'
import { PopupMenu, PopupPositionProp } from '@elements'
import { user } from '@graphql'
import { OptionType, ResponseType, User } from '@types'
import { useSiteMetadata } from '@hooks'
import { getOptionsBySchema } from '@utils'
import DefaultAvatar from '@images/default-user-avatar.webp'
import styles from './AvatarPopupMenu.module.scss'

interface AvatarPopupMenuProps {
  position?: PopupPositionProp
}

const AvatarPopupMenu: React.FC<AvatarPopupMenuProps> = props => {
  const { position } = props

  const { accountNavigation } = useSiteMetadata()

  const { data } = useQuery<ResponseType<User>>(user.FetchCurrentUser, { fetchPolicy: 'cache-only' })
  const currentUser = data && data.res

  const filteredAccountNavigation = accountNavigation.filter(el => el.label !== 'Store')

  const menu = getOptionsBySchema<Record<string, string>, OptionType<string> & { icon: string }>(
    filteredAccountNavigation,
    {
      label: 'label',
      value: 'path',
      icon: 'icon'
    }
  )
  const avatar = currentUser ? currentUser.avatar || DefaultAvatar : DefaultAvatar

  return (
    <div className={styles.avatarPopupMenu}>
      <PopupMenu
        offsetY={45}
        offsetX={20}
        position={position}
        options={menu}
        bottom={{ label: 'LOGOUT', value: '/logout', icon: '/assets/images/logout.svg' }}
      >
        <img src={avatar} alt="avatar" />
      </PopupMenu>
    </div>
  )
}

AvatarPopupMenu.defaultProps = {
  position: 'bottom right'
}

export default memo(AvatarPopupMenu)
