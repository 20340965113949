import React from 'react'
import { Children } from '@types'

import styles from './Drawer.module.scss'

interface DrawerProps {
  id: string
  opened: boolean
  width?: string
  children: Children
}

const Drawer: React.FC<DrawerProps> = props => {
  const { id, opened, width, children } = props

  const styleObj = {
    width: opened ? width || '100%' : '0px'
  }

  return (
    <aside id={id} className={styles.drawer} style={styleObj}>
      {children}
    </aside>
  )
}

export default Drawer
