import Typography from 'typography'

// all styles that are
// included in import './src/assets/styles/app.scss' are allowed to use here
// check baseFontSize in variables.scss/toRem func

const typography = new Typography({
  title: 'Brightlab',
  baseFontSize: '14px', // font-size
  baseLineHeight: 1.5, // line-height
  scaleRatio: 5, // default headings sizes <h1 style={{ fontSize: calc(16px * 5) || 5rem }}>Heading</h1>
  headerFontFamily: ['Rubik', 'Rubik', 'sans-serif'],
  bodyFontFamily: ['Rubik', 'Rubik', 'sans-serif'],
  headerColor: `inherit`,
  bodyColor: `inherit`,
  headerGray: 20,
  headerGrayHue: 0,
  bodyGray: 20,
  bodyGrayHue: 0,
  headerWeight: 'bold',
  bodyWeight: 'normal',
  boldWeight: 'bold',
  blockMarginBottom: 1.5, // 1 rhythm unit = baseLineHeight = 1rem
  includeNormalize: true,
  overrideStyles: ({ adjustFontSizeTo, rhythm }, options, styles) => {
    // All typography styles should be here, if they are global
    const { bodyFontFamily, baseLineHeight } = options

    return {
      '@media(min-width: 1281px)': {
        html: {
          font: `87.5%/${baseLineHeight} ${bodyFontFamily}`
        }
      },
      '@media(max-width: 1280px)': {
        html: {
          font: `75%/${baseLineHeight} ${bodyFontFamily}`
        }
      },
      '@media(max-width: 992px)': {
        html: {
          font: `62.5%/${baseLineHeight} ${bodyFontFamily}`
        }
      }
    }
  }
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
// Functions to calculate sizes returns rems
export const rhythm = typography.rhythm
export const scale = typography.scale
