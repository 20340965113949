import { useQuery } from '@apollo/client'
import { Button, Disclosure, Link } from '@elements'
import { filters as graphqlFilters, user as graphqlUser } from '@graphql'
import { useModal, useWindowSize } from '@hooks'
import TourDayLogo from '@images/tourday/tourday-logo.svg'
import NGFLogo from '@images/national-golf-foundation-logo.svg'
import ClubmakersLogo from '@images/clubmakers-guild-logo.svg'
import WinkFaceIcon from '@images/wink-face.svg'
import { CacheUser, Filters, FooterNavigationButtonType, ResponseType } from '@types'
import { navigate } from 'gatsby'
import React, { memo } from 'react'
import styles from './FooterNavigation.module.scss'
import FooterNavigationButton from './FooterNavigationButton'

export interface FooterNavigationProps {}

const FooterNavigation: React.FC<FooterNavigationProps> = () => {
  const footerButtons: FooterNavigationButtonType[] = [
    {
      name: 'Home',
      link: '/home',
      icon: 'home'
    },
    {
      name: 'Offer Book',
      link: '/account/my-offer-book',
      icon: 'offer',
      requireLogin: true
    },
    {
      name: 'Browse',
      link: '/catalogue',
      icon: 'browse'
    },
    {
      name: 'Locker',
      link: '/account/locker',
      icon: 'locker',
      requireLogin: true
    },
    {
      name: 'Store',
      link: '/account/store',
      icon: 'store',
      requireLogin: true
    }
  ]

  return (
    <div className={styles.footerNavigation}>
      {footerButtons.map((fb, index) => {
        return <FooterNavigationButton {...fb} key={index} />
      })}
    </div>
  )
}

export default memo(FooterNavigation)
