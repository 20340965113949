import { gql } from '@apollo/client'
import { AddressCardAttrs, AddressCardResponseAttrs } from '@fragments'

export const FetchAddressCards = gql`
  query FetchAddressCards {
    res: addressCards {
      ...AddressCardAttrs
    }
  }

  ${AddressCardAttrs}
`

export const FetchAddressCard = gql`
  query FetchAddressCard($id: UUID4!) {
    res: addressCard(id: $id) {
      ...AddressCardAttrs
    }
  }

  ${AddressCardAttrs}
`

export const CreateAddressCard = gql`
  mutation CreateAddressCard($addressCard: AddressCardParams!) {
    res: createAddressCard(addressCard: $addressCard) {
      ...AddressCardResponseAttrs
      result {
        ...AddressCardAttrs
      }
    }
  }

  ${AddressCardAttrs}
  ${AddressCardResponseAttrs}
`

export const UpdateAddressCard = gql`
  mutation UpdateAddressCard($id: UUID4!, $addressCard: AddressCardParams) {
    res: updateAddressCard(id: $id, addressCard: $addressCard) {
      ...AddressCardResponseAttrs
      result {
        ...AddressCardAttrs
      }
    }
  }

  ${AddressCardAttrs}
  ${AddressCardResponseAttrs}
`

export const DeleteAddressCard = gql`
  mutation DeleteAddressCard($id: UUID4!) {
    res: deleteAddressCard(id: $id) {
      ...AddressCardResponseAttrs
    }
  }

  ${AddressCardResponseAttrs}
`
