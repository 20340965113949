import { gql } from '@apollo/client'

export const GolfCourses = gql`
  query GolfCourses {
    res: golfCourses {
      address
      city
      country
      designedBy
      id
      insertedAt
      state
      title
      updatedAt
      yearFounded
    }
  }
`
