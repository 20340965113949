import { gql } from '@apollo/client'
import { PromoCodeAttrs } from '@fragments'

export const FetchPromoCode = gql`
  query FetchPromoCode($id: UUID4!) {
    res: promoCode(id: $id) {
      ...PromoCodeAttrs
    }
  }

  ${PromoCodeAttrs}
`

export const FetchPromoCodeByCode = gql`
  query FetchPromoCodeByCode($code: String!) {
    res: promoCodeByCode(code: $code) {
      ...PromoCodeAttrs
    }
  }

  ${PromoCodeAttrs}
`
