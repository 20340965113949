import { useQuery } from '@apollo/client'
import { user as graphqlUser } from '@graphql'
import { useModal } from '@hooks'
import { AccountSetupModalState, CacheUser, FooterNavigationButtonType, ResponseType } from '@types'
import { navigate } from 'gatsby'
import React, { memo } from 'react'
import styles from './FooterNavigation.module.scss'

import HomeIcon from '@images/home-nav.svg'
import OfferIcon from '@images/offer-book.svg'
import BrowseIcon from '@images/search.svg'
import LockerIcon from '@images/portfolio.svg'
import StoreIcon from '@images/store.svg'

export interface FooterNavigationButtonProps {}

const FooterNavigationButton: React.FC<FooterNavigationButtonType> = (props: FooterNavigationButtonType) => {
  const { data: currentUser } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })
  const { openModal } = useModal<AccountSetupModalState>('AccountSetupModal')

  const { name, link, icon, requireLogin } = props

  // more icons can be added here
  const renderIcon = (svg: string) => {
    if (svg === 'offer') {
      return <OfferIcon width={30} height={30} />
    } else if (svg === 'browse') {
      return <BrowseIcon width={30} height={30} />
    } else if (svg === 'locker') {
      return <LockerIcon width={30} height={30} />
    } else if (svg === 'store') {
      return <StoreIcon width={30} height={30} />
    }

    return <HomeIcon width={30} height={30} />
  }

  function handleClick() {
    if (!currentUser && requireLogin) {
      openModal()
    } else {
      navigate(link)
    }
  }

  return (
    <div>
      <div className={styles.footerNavigationWrapper} onClick={() => handleClick()}>
        <div
          className={icon === 'browse' ? styles.footerNavigationIcon : styles.footerNavigationButton}
          style={{ display: 'block', height: 30, width: 30 }}
        >
          {renderIcon(icon ?? '')}
        </div>
        <span className={styles.footerNavigationName}>{name}</span>
      </div>
    </div>
  )
}

export default memo(FooterNavigationButton)
