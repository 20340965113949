import { useMutation } from '@apollo/client'
import { useAlert } from 'react-alert'
import { MutationResponse, ResponseType } from '@types'
import { useRequestErrorHandler } from '@hooks'
import { store as graphqlStore, store, user } from '@graphql'

type StartSellingResponse = ResponseType<MutationResponse & { result: { id: string } }>

const useGraphqlStore = () => {
  const alert = useAlert()
  const handleRequestError = useRequestErrorHandler()

  const [startSellingMutation, { loading: startSellingLoading }] = useMutation<StartSellingResponse>(
    store.StartSelling,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const startSelling = async (accStatus: string) => {
    const request = await startSellingMutation({
      update: (cache, fetchResult) => {
        const { data } = fetchResult
        if (!data?.res.successful) {
          alert.show('Something went wrong', { type: 'error' })
          return null
        }

        if (data) {
          const userId = cache.identify(data?.res.result)

          cache.modify({
            id: userId,
            fields: {
              sellerStatus() {
                return accStatus
              }
            }
          })
        }
      },
      refetchQueries: [{ query: user.FetchCurrentUser }, { query: graphqlStore.FetchStore }]
    })
    return handleRequestError(request)
  }

  return { startSelling, loading: startSellingLoading }
}

export default useGraphqlStore
