import React, { CSSProperties, memo } from 'react'
import LoaderComponent from 'react-loader-spinner'
import styles from './Loader.module.scss'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

type LoaderTypes =
  | 'Audio'
  | 'BallTriangle'
  | 'Bars'
  | 'Circles'
  | 'Grid'
  | 'Hearts'
  | 'MutatingDots'
  | 'None'
  | 'NotSpecified'
  | 'Oval'
  | 'Plane'
  | 'Puff'
  | 'RevolvingDot'
  | 'Rings'
  | 'TailSpin'
  | 'ThreeDots'
  | 'Triangle'
  | 'Watch'

interface LoaderProps {
  color?: string
  height?: number
  radius?: number
  secondaryColor?: string
  timeout?: number // in milliseconds
  type?: LoaderTypes
  width?: number
  className?: string
  style?: CSSProperties
}

const TIMEOUT = 150000

const Loader: React.FC<LoaderProps> = props => {
  const { style, ...otherProps } = props

  return (
    <div className={styles.loader} style={style} data-testid={'loader'}>
      <LoaderComponent {...otherProps} />
    </div>
  )
}

Loader.defaultProps = {
  type: 'TailSpin',
  color: '#4E60DD',
  height: 60,
  width: 60,
  timeout: TIMEOUT
}

export default Loader
