import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import {
  MutationResponse,
  ParseCSVElement,
  ResponseType,
  ValidateCSVArgs,
  ValidateCSVResponse,
  ValidateCSVResultElement
} from '@types'
import { csv, golfClub } from '@graphql'
import { useRequestErrorHandler } from '@hooks'

const VALIDATE_RESPONSE_BLANK: ValidateCSVResponse = {
  rejected: [] as ParseCSVElement[],
  approved: [] as ParseCSVElement[],
  result: [] as ValidateCSVResultElement[]
}

type InsertCSVResponse = ResponseType<MutationResponse & { result: string[] }>

const useGraphqlCSV = () => {
  const handleRequestError = useRequestErrorHandler()
  const [parseCSV, { data: parseCSVData, loading: parseCSVLoading }] = useLazyQuery<ResponseType<ParseCSVElement[]>>(
    csv.ParseCSV,
    { onError: error => handleRequestError(null, error) }
  )
  const [validateCSV, { data: validateCSVData, loading: validateCSVLoading }] = useLazyQuery<
    ResponseType<ValidateCSVResponse>,
    { rows: ValidateCSVArgs }
  >(csv.ValidateCSV, { onError: error => handleRequestError(null, error) })
  const [insertCSV] = useMutation<InsertCSVResponse, { args: ValidateCSVResultElement[] }>(csv.InsertCSV, {
    onError: error => handleRequestError(null, error)
  })

  const parse = async (file: File) => {
    const fr = new FileReader()
    fr.onload = async e => {
      if (!e?.target?.result) handleRequestError(null, { message: 'No file to upload' } as ApolloError)
      if (e?.target?.result) await parseCSV({ variables: { file: e.target.result } })
    }

    await fr.readAsDataURL(file)
  }

  const validate = async (rows: ValidateCSVArgs) => {
    validateCSV({ variables: { rows } })
  }

  const insert = async (args: ValidateCSVResultElement[]) => {
    const request = await insertCSV({
      variables: { args },
      refetchQueries: [{ query: golfClub.FetchPaginatedGolfClubs, variables: { pagination: { page: 1, pageSize: 6 } } }]
    })
    return handleRequestError(request)
  }

  const filterParseCSVData = (data?: ParseCSVElement[]) => {
    let isRowHaveInvalidKey = false

    const filtredCSVData = (data || []).filter(row => {
      Object.values(row).forEach(val => {
        if (!val) isRowHaveInvalidKey = true
      })

      return !isRowHaveInvalidKey
    })

    if (isRowHaveInvalidKey) {
      return 'CSV file has invalid fields, please check "Inventory Template"'
    }
    return filtredCSVData
  }

  return {
    parse,
    parseCSVResponse: filterParseCSVData(parseCSVData && parseCSVData.res),
    parseCSVLoading,
    validate,
    validateCSVResponse: (validateCSVData && validateCSVData.res) || VALIDATE_RESPONSE_BLANK,
    validateCSVLoading,
    insert
  }
}

export default useGraphqlCSV
