import { gql } from '@apollo/client'
import { PaymentCardAttrs, PaymentCardResponseAttrs } from '@fragments'

export const FetchStripePayoutCard = gql`
  query FetchStripePayoutCard {
    res: stripePayoutCard {
      ...PaymentCardAttrs
    }
  }

  ${PaymentCardAttrs}
`

export const CreatePaymentCard = gql`
  mutation CreatePaymentCard($paymentCard: PaymentCardParams) {
    res: createPaymentCard(paymentCard: $paymentCard) {
      ...PaymentCardResponseAttrs
      result {
        ...PaymentCardAttrs
      }
    }
  }

  ${PaymentCardAttrs}
  ${PaymentCardResponseAttrs}
`

export const UpdatePaymentCard = gql`
  mutation UpdatePaymentCard(
    $id: UUID4!
    $cardNumber: String
    $cvc: String
    $expYear: String
    $expMonth: String
    $default: Boolean
  ) {
    res: updatePaymentCard(
      id: $id
      paymentCard: { cardNumber: $cardNumber, cvc: $cvc, expYear: $expYear, expMonth: $expMonth, default: $default }
    ) {
      ...PaymentCardResponseAttrs
      result {
        ...PaymentCardAttrs
      }
    }
  }

  ${PaymentCardAttrs}
  ${PaymentCardResponseAttrs}
`

export const DeletePaymentCard = gql`
  mutation DeletePaymentCard($id: UUID4!) {
    res: deletePaymentCard(id: $id) {
      ...PaymentCardResponseAttrs
    }
  }

  ${PaymentCardResponseAttrs}
`

export const SetPaymentCardDefault = gql`
  mutation SetPaymentCardDefault($id: UUID4!) {
    res: setPaymentCardDefault(id: $id) {
      messages {
        message
      }
      result {
        brand
        default
        expMonth
        expYear
        id
        insertedAt
        number
        updatedAt
      }
      successful
    }
  }
`
