import { gql } from '@apollo/client'

export const BrandAttrs = gql`
  fragment BrandAttrs on Brand {
    avatar
    id
    insertedAt
    title
    updatedAt
    preSort
  }
`

export const CategoryAttrs = gql`
  fragment CategoryAttrs on Category {
    id
    title
    insertedAt
    updatedAt
    preSort
  }
`

export const FiltersAttrs = gql`
  fragment FiltersAttrs on Filters {
    flexes
    gripSizes
    heights
    releaseYears
    brands {
      ...BrandAttrs
    }
    categories {
      ...CategoryAttrs
    }
    equipmentCategories {
      id
      insertedAt
      title
      updatedAt
    }
  }

  ${BrandAttrs}
  ${CategoryAttrs}
`

export const OptionAttrs = gql`
  fragment OptionAttrs on Option {
    avatar
    description
    id
    insertedAt
    optionClass {
      id
      insertedAt
      title
      updatedAt
    }
    preSort
    title
    updatedAt
  }
`

export const PurchaseRequestAttrs = gql`
  fragment PurchaseRequestAttrs on PurchaseRequest {
    buyerSalesTax
    buyerShippingPrice
    buyerTotalPrice
    expiresAt
    itemPrice
    id
    insertedAt
    sellerProcessingFee
    sellerProfit
    sellerShippingPrice
    sellerTotalPrice
    status
    updatedAt
    user {
      id
    }
  }
`
export const golfCourseAttrs = gql`
  fragment golfCourseAttrs on golfCourse {
    address
    city
    country
    id
    designedBy
    state
    insertedAt
    updatedAt
    title
    yearFounded
  }
`
