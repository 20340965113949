import { useQuery } from '@apollo/client'
import { AvatarPopupMenu, Container, DesktopGolfClubHeaderSearch, NotificationPopupMenu } from '@components'
import { Button, Navigation } from '@elements'
import { category, user } from '@graphql'
import { useDrawer, useModal, useSiteMetadata, useWindowSize } from '@hooks'
// import BurgerImage from '@images/burger.svg'
import BurgerImage from '@images/icons/hamburger.svg'
import LogoImage from '@images/tourday/tourday-logo.svg'
import { AccountSetupModalState, BrowseCategory, CacheUser, DeviceType, ResponseType } from '@types'
import { navigate } from 'gatsby'
import React, { memo, useState } from 'react'
import styles from './Header.module.scss'
import HeaderBrowseCategories from './HeaderBrowseCategories'

const HeaderUserInfo: React.FC = () => {
  return (
    <div className={styles.headerUserInfo}>
      <NotificationPopupMenu />
      <AvatarPopupMenu position={'left top'} />
    </div>
  )
}

const HeaderAuthorization: React.FC = () => {
  const { openModal } = useModal<AccountSetupModalState>('AccountSetupModal')

  return (
    <div className={styles.headerAuthorization}>
      <Button type={'round'} onClick={() => openModal({ part: 'SignUp' })} noBorder>
        Sign up
      </Button>
      <Button type={'round'} onClick={() => openModal({ part: 'SignIn' })}>
        Sign in
      </Button>
    </div>
  )
}

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  const { navigation } = useSiteMetadata()

  const { openDrawer } = useDrawer('MobileNavigationDrawer')
  const { data } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, { fetchPolicy: 'cache-only' })
  const { data: categoriesData } = useQuery<ResponseType<BrowseCategory[]>, { deviceType: DeviceType }>(
    category.FetchHeaderCategories,
    { variables: { deviceType: DeviceType.COMPUTER } }
  )
  const [browseCategory, setBrowseCategory] = useState<BrowseCategory | null>(null)

  const currentUser = data && data.res
  const categories = categoriesData?.res || []

  const renderCategories = () => {
    return categories.map(category => {
      return (
        <div
          onMouseMove={() => {
            setBrowseCategory(category)
          }}
          className={styles.headerCategories__categoryItem}
          key={category.id}
          data-active={browseCategory?.title === category.title}
        >
          {category.title}
        </div>
      )
    })
  }

  if (isMobileRender) {
    return (
      <div className={`${styles.header} ${styles.headerMobile}`}>
        <div className={`${styles.headerMobileInner} ${styles.headerInner}`}>
          <div className={styles.headerMobileInnerLeft}>
            <BurgerImage onClick={openDrawer} className={styles.headerBurgerIconMobile} />
          </div>
          <div className={styles.headerMobileInnerCenter}>
            <LogoImage onClick={() => navigate('/home')} className={styles.headerLogoMobile} />
          </div>
          <div className={styles.headerMobileInnerRight}>
            <div className={styles.headerIconsInner}>{currentUser && <NotificationPopupMenu isMobile />}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.header} onMouseLeave={() => setBrowseCategory(null)} data-transparent-bg={true}>
      <Container>
        <div className={styles.headerInner}>
          <div className={styles.headerInnerLeft}>
            <Navigation navigation={navigation} />
            <DesktopGolfClubHeaderSearch />
          </div>
          <div className={styles.headerInnerCenter}>
            <LogoImage className={styles.headerLogo} onClick={() => navigate('/home')} />
          </div>
          <div className={styles.headerInnerRight}>{currentUser ? <HeaderUserInfo /> : <HeaderAuthorization />}</div>
        </div>
        <div className={styles.headerCategories}>{renderCategories()}</div>
      </Container>
      <HeaderBrowseCategories browseCategory={browseCategory} />
    </div>
  )
}

export default memo(Header)
