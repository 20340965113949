import { gql } from '@apollo/client'

export const FetchNotifications = gql`
  query FetchOldNotifications {
    res: notifications {
      golfClub {
        id
        order {
          id
        }
      }
      golfEquipment {
        id
        order {
          id
        }
      }
      golfClubModel {
        id
        avatar
        title
      }
      golfEquipmentModel {
        id
        avatar
        title
      }

      id
      insertedAt
      purchaseRequest {
        id
      }
      type
      updatedAt
      user {
        id
      }
      viewed
    }
  }
`

export const NewNotifications = gql`
  subscription newNotifications {
    newNotifications {
      golfClub {
        id
        order {
          id
        }
      }
      golfClubModel {
        id
        avatar
        title
      }
      golfEquipment {
        id
        order {
          id
        }
      }
      golfEquipmentModel {
        id
        avatar
        title
      }
      id
      insertedAt
      purchaseRequest {
        id
      }
      type
      updatedAt
      user {
        id
      }
      viewed
    }
  }
`
export const ViewNotifications = gql`
  mutation ViewNotifications {
    res: viewNotifications {
      successful
    }
  }
`
