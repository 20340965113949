import { Reference, useMutation, useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { useAlert } from 'react-alert'
import {
  CacheUser,
  CreatePaymentCardParams,
  CurrentUserActions,
  EmailFormType,
  ExistingProductFeedbackType,
  MutationResponse,
  NewProductFeedbackType,
  PaymentCard,
  ResponseType,
  SubscribedEmailResponse,
  UpdatePaymentCardParams,
  UpdateUserFormType,
  UpdateUserPasswordFormType,
  User,
  UserBrowsingView,
  UserGolfItem,
  UserGolfItemArgs
} from '@types'

import {
  browsingViews as graphqlBrowsingViews,
  paymentCard as graphqlPaymentCard,
  user as graphqlUser,
  userGolfClub as graphqlUserGolfClub
} from '@graphql'
import { useRequestErrorHandler } from '@hooks'
import { compareObjectsAndGetMismatchesKeys } from '@utils'
import { PaymentCardAttrs } from '@fragments'
import { userGolfClub } from '@graphql'

type UserBrowsingViewResponse = ResponseType<MutationResponse & { result: UserBrowsingView }>
type UserGolfClubResponse = ResponseType<MutationResponse & { result: UserGolfItem }>
type CreateNewFeedbackResponse = ResponseType<MutationResponse & { result: NewProductFeedbackType }>
type CreateExistingFeedbackResponse = ResponseType<MutationResponse & { result: ExistingProductFeedbackType }>
type PaymentCardResponse = ResponseType<MutationResponse & { result: PaymentCard }>
type UserResponse = ResponseType<MutationResponse & { result: User }>
type UserSubscribedResponse = ResponseType<MutationResponse & { result: SubscribedEmailResponse }>

const useGraphqlUser = () => {
  const alert = useAlert()
  const { data } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, { fetchPolicy: 'cache-only' })
  const user = (data && data.res) || ({} as CacheUser)

  const handleRequestError = useRequestErrorHandler()

  const [updateUserGolfItemMutation] = useMutation<
    UserGolfClubResponse,
    { userGolfItem: UserGolfItemArgs; id: string }
  >(graphqlUserGolfClub.UpdateUserGolfItem, {
    refetchQueries: [{ query: graphqlUserGolfClub.FetchPaginatedUserGolfItems, variables: { userId: user.id } }],
    onError: error => handleRequestError(null, error)
  })
  const [deleteUserGolfClubMutation] = useMutation<UserGolfClubResponse, { id: string }>(
    graphqlUserGolfClub.DeleteUserGolfClub,
    {
      refetchQueries: [{ query: graphqlUserGolfClub.FetchPaginatedUserGolfItems, variables: { userId: user.id } }],
      onError: error => handleRequestError(null, error)
    }
  )

  const [createNewProductFeedback, { loading: newProductFeedbackLoading }] = useMutation<CreateNewFeedbackResponse>(
    graphqlUser.CreateNewProductFeedback,
    {
      onError: error => handleRequestError(null, error)
    }
  )
  const [createExistingProductFeedback, { loading: existingProductFeedbackLoading }] = useMutation<
    CreateExistingFeedbackResponse
  >(graphqlUser.CreateExistingProductFeedback, {
    onError: error => handleRequestError(null, error)
  })

  const [createPaymentCard, { loading: createPaymentCardLoading }] = useMutation<
    PaymentCardResponse,
    { paymentCard: CreatePaymentCardParams }
  >(graphqlPaymentCard.CreatePaymentCard, {
    onError: error => handleRequestError(null, error)
  })

  const [updatePaymentCard] = useMutation<PaymentCardResponse, UpdatePaymentCardParams>(
    graphqlPaymentCard.UpdatePaymentCard,
    {
      onError: error => handleRequestError(null, error)
    }
  )
  const [deletePaymentCard, { loading: deletePaymentCardLoading }] = useMutation<PaymentCardResponse, { id: string }>(
    graphqlPaymentCard.DeletePaymentCard,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const [setDefaultPaymentCard] = useMutation<PaymentCardResponse, { id: string }>(
    graphqlPaymentCard.SetPaymentCardDefault,
    {
      onError: error => handleRequestError(null, error)
    }
  )
  const [addUserBrowsingView] = useMutation<UserBrowsingViewResponse, { id: string }>(
    graphqlBrowsingViews.AddUserBrowsingView,
    {
      onError: error => handleRequestError(null, error)
    }
  )
  const [clearUserBrowsingHistory] = useMutation<UserBrowsingViewResponse>(
    graphqlBrowsingViews.ClearUserBrowsingHistory,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const [resetCurrentUserStripeAccount, { loading: resetCurrentUserStripeAccountLoading }] = useMutation<UserResponse>(
    graphqlUser.ResetCurrentUserStripeAccount,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const [deleteBrowsingViewMutation] = useMutation<UserBrowsingViewResponse, { id: string }>(
    graphqlBrowsingViews.DeleteBrowsingView,
    {
      onError: error => handleRequestError(null, error)
    }
  )
  const [updateUser] = useMutation<UserResponse, { user: UpdateUserFormType }>(graphqlUser.UpdateCurrentUser, {
    onError: error => handleRequestError(null, error)
  })
  const [createSubscribedEmailMutation] = useMutation<UserSubscribedResponse, { subscribedEmail: { email: string } }>(
    graphqlUser.createSubscribedEmail,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const [updateUserPassword] = useMutation<
    ResponseType<MutationResponse & { result: User }>,
    UpdateUserPasswordFormType
  >(graphqlUser.UpdateCurrentUserPassword, { onError: error => handleRequestError(null, error) })

  const createSubscribedEmail = async (subscribedEmail: EmailFormType) => {
    const request = await createSubscribedEmailMutation({
      variables: { subscribedEmail }
    })
    return handleRequestError(request)
  }

  const [createUserGolfItemMutation] = useMutation<ResponseType<MutationResponse>, { userGolfItem: UserGolfItemArgs }>(
    graphqlUserGolfClub.CreateUserGolfItem,
    {
      refetchQueries: [{ query: userGolfClub.FetchPaginatedUserGolfItems, variables: { userId: user.id } }],
      onError: error => handleRequestError(null, error),
      update(cache, mutationResult) {
        if (mutationResult?.data?.res.successful) alert.show('Success!', { type: 'success' })
      }
    }
  )

  const createUserGolfItem = async (payload: UserGolfItemArgs) => {
    return await createUserGolfItemMutation({
      variables: { userGolfItem: payload }
    })
  }

  const updateUserGolfItem = async (id: string, userGolfItem: UserGolfItemArgs) => {
    const request = await updateUserGolfItemMutation({
      variables: { id, userGolfItem },
      update: (cache, mutationResult) => {
        const result = mutationResult.data?.res?.result
        const successful = mutationResult.data?.res?.successful
        if (!successful || !result) return null

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const deleteUserGolfClub = async (id: string) => {
    const request = await deleteUserGolfClubMutation({
      variables: { id: id },
      update: (cache, mutationResult) => {
        const result = mutationResult.data?.res?.result
        const successful = mutationResult.data?.res.successful
        if (!successful || !result) return null

        const userId = cache.identify(user)

        cache.modify({
          id: userId,
          fields: {
            bag(bagRefs: Reference[], { readField }) {
              return bagRefs.filter(bagRef => result.id !== readField('id', bagRef))
            }
          }
        })

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const newProductFeedback = async (newProductFeedback: NewProductFeedbackType) => {
    const request = await createNewProductFeedback({
      variables: { newProductFeedback },
      update: (cache, mutationResult) => {
        const successful = mutationResult.data?.res.successful

        if (!successful) return null

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const existingProductFeedback = async (existingProductFeedback: ExistingProductFeedbackType) => {
    const request = await createExistingProductFeedback({
      variables: { existingProductFeedback },
      update: (cache, mutationResult) => {
        const successful = mutationResult.data?.res.successful

        if (!successful) return null

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const createCard = async (paymentCard: CreatePaymentCardParams) => {
    const request = await createPaymentCard({
      variables: { paymentCard },
      refetchQueries: [{ query: graphqlUser.FetchCurrentUser }],
      update: (cache, mutationResult) => {
        const result = mutationResult.data?.res?.result
        const successful = mutationResult.data?.res?.successful
        if (!successful) return null
        if (user?.id && result) {
          cache.modify({
            id: cache.identify(user),
            fields: {
              paymentCards(paymentCardRefs: Reference[], { toReference }) {
                const newPaymentCardRef = toReference(result)
                return [...paymentCardRefs, newPaymentCardRef]
              }
            }
          })
        }

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const updateCard = async (id: string, paymentCard: Omit<UpdatePaymentCardParams, 'id'>) => {
    const request = await updatePaymentCard({
      variables: {
        id,
        ...paymentCard
      },
      update: (cache, mutationResult) => {
        const result = mutationResult.data?.res?.result
        const successful = mutationResult.data?.res.successful

        if (!successful) return null
        if (user?.id && result) {
          cache.modify({
            id: cache.identify(user),
            fields: {
              paymentCards(paymentCardRefs: Reference[], { toReference, readField }) {
                const newPaymentCardRef = toReference(result)
                return paymentCardRefs.map(ref => {
                  if (readField('id', ref) === id) return newPaymentCardRef
                })
              }
            }
          })
        }

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const deleteCard = async (id: string) => {
    const request = await deletePaymentCard({
      variables: { id },
      update: (cache, mutationResult) => {
        const successful = mutationResult.data?.res.successful

        if (!successful) return null

        alert.show(`Success!`, {
          type: 'success'
        })
        cache.modify({
          id: cache.identify(user),
          fields: {
            paymentCards(existingUserCardRefs, { readField }) {
              return existingUserCardRefs.filter((userCardRef: Reference) => {
                return id !== readField('id', userCardRef)
              })
            }
          }
        })
      }
    })

    return handleRequestError(request)
  }

  const useCardAsDefault = async (id: string) => {
    const request = await setDefaultPaymentCard({
      variables: { id },
      update: (cache, mutationResult) => {
        const result = mutationResult.data?.res?.result
        const successful = mutationResult.data?.res?.successful
        if (!successful) return null
        if (user?.id && result) {
          const prevDefaultCard = user?.paymentCards.find(card => card.default)
          const newPaymentCardRef = cache.writeFragment({
            id: cache.identify(result),
            data: result,
            fragment: PaymentCardAttrs
          })
          let prevDefaultPaymentCardRef: Reference | undefined
          if (prevDefaultCard) {
            prevDefaultPaymentCardRef = cache.writeFragment({
              id: cache.identify(prevDefaultCard),
              data: { ...prevDefaultCard, default: false },
              fragment: PaymentCardAttrs
            })
          }
          cache.modify({
            id: cache.identify(user),
            fields: {
              paymentCards(paymentCardRefs: Reference[], { readField }) {
                return paymentCardRefs.map(ref => {
                  if (
                    prevDefaultPaymentCardRef &&
                    readField('id', ref) === readField('id', prevDefaultPaymentCardRef)
                  ) {
                    return prevDefaultPaymentCardRef
                  }
                  if (readField('id', newPaymentCardRef) === readField('id', ref)) {
                    return newPaymentCardRef
                  }
                  return ref
                })
              }
            }
          })
        }
        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const addBrowsingView = async (golfClubModelId: string) => {
    if (!user) return null

    const request = await addUserBrowsingView({
      variables: { id: golfClubModelId },
      update: (cache, mutationResult) => {
        const result = mutationResult.data?.res.result
        const successful = mutationResult.data?.res.successful
        if (!successful || !result) return null

        const userId = cache.identify(user)

        cache.modify({
          id: userId,
          fields: {
            browsingViews(browsingViewRefs: Reference[], { toReference }) {
              const resultRef = toReference(result)
              const newBrowsingViewRefs = browsingViewRefs
              if (resultRef) newBrowsingViewRefs.push(resultRef)

              return newBrowsingViewRefs
            }
          }
        })
      }
    })

    return handleRequestError(request)
  }

  const clearBrowsingHistory = async () => {
    const request = await clearUserBrowsingHistory({
      update: (cache, mutationResult) => {
        const successful = mutationResult.data?.res.successful
        if (!successful) return null

        const userId = cache.identify(user)

        cache.modify({
          id: userId,
          fields: {
            browsingViews() {
              return []
            }
          }
        })

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const resetStripeAccount = async () => {
    const request = await resetCurrentUserStripeAccount({
      update: (cache, mutationResult) => {
        const successful = mutationResult.data?.res.successful
        if (!successful) return null

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })
    return handleRequestError(request)
  }

  const deleteBrowsingView = async (id: string) => {
    const request = await deleteBrowsingViewMutation({
      variables: { id },
      update: (cache, mutationResult) => {
        const successful = mutationResult.data?.res.successful
        if (!successful) return null

        const userId = cache.identify(user)

        cache.modify({
          id: userId,
          fields: {
            browsingViews(browsingViewRefs: Reference[], { readField }) {
              return browsingViewRefs.filter(ref => id !== readField('id', ref))
            }
          }
        })

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const update = async (form: UpdateUserFormType, noCache?: boolean) => {
    const request = await updateUser({
      variables: { user: form },
      update: (cache, mutationResult) => {
        const result = mutationResult.data?.res.result
        const successful = mutationResult.data?.res.successful
        if (!successful) return null

        if (!noCache && result) {
          const isNamesExist = result?.firstName && result?.lastName
          const name = isNamesExist ? result?.firstName + ' ' + result?.lastName : ''
          const mismatchesKeys = compareObjectsAndGetMismatchesKeys(user, result!) as (keyof User)[]
          const nonNullableMismatchesKeys = mismatchesKeys.filter(key => result[key])
          const changedUserPart = nonNullableMismatchesKeys.reduce((acc, field) => {
            if (field === 'avatar') {
              // fix browser caching images
              return {
                ...acc,
                avatar: result[field] ? result[field] + `?${Math.random()}` : result[field]
              }
            }
            return {
              ...acc,
              [field]: result[field]
            }
          }, {})

          cache.writeQuery({
            query: graphqlUser.FetchCurrentUser,
            data: {
              res: { ...user, ...changedUserPart, name }
            }
          })
        }

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const updatePassword = async (form: UpdateUserPasswordFormType) => {
    const request = await updateUserPassword({
      variables: { ...form },
      update: (cache, mutationResult) => {
        const successful = mutationResult.data?.res?.successful
        if (!successful) return null

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const fetchCurrentUser = (fetchPolicy: WatchQueryFetchPolicy = 'cache-first') => {
    const currentUserRequest = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, { fetchPolicy })
    return currentUserRequest.data?.res
  }

  const fetchUserActions = (fetchPolicy: WatchQueryFetchPolicy = 'cache-first') => {
    const userActionsRequest = useQuery<ResponseType<CurrentUserActions>>(graphqlUser.UserActions, { fetchPolicy })
    return userActionsRequest.data?.res
  }

  return {
    fetchCurrentUser,
    fetchUserActions,
    updateUserGolfItem,
    deleteUserGolfClub,
    createCard,
    updateCard,
    newProductFeedback,
    existingProductFeedback,
    deleteCard,
    useCardAsDefault,
    addBrowsingView,
    clearBrowsingHistory,
    deleteBrowsingView,
    update,
    updatePassword,
    createSubscribedEmail,
    resetStripeAccount,
    createUserGolfItem,
    loading:
      createPaymentCardLoading ||
      deletePaymentCardLoading ||
      resetCurrentUserStripeAccountLoading ||
      existingProductFeedbackLoading ||
      newProductFeedbackLoading // loading status value
  }
}

export default useGraphqlUser
