import { gql } from '@apollo/client'
import { ValidationMessageAttrs } from '@fragments'

export const FetchStore = gql`
  query FetchStore {
    res: store {
      active
      unsold
      sales
      total
    }
  }
`

export const StartSelling = gql`
  mutation StartSelling {
    res: startSelling {
      messages {
        ...ValidationMessageAttrs
      }
      successful
      result {
        id
      }
    }
  }
  ${ValidationMessageAttrs}
`

export const GetStripeAccountUrl = gql`
  query GetStripeAccountUrl {
    res: stripeAccountUrl {
      created
      expiresAt
      object
      url
    }
  }
`
