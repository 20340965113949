import React, { RefObject } from 'react'
import {
  withOptionSelect,
  WithOptionSelectProps,
  WithOptionSelectPropsPassed,
  withToggle,
  WithToggleProps,
  WithTogglePropsPassed
} from '@hocs'

import { Dropdown, DropdownProps } from '@elements'
import Input, { InputProps } from '../../elements/__Input/Input'
import { OptionType } from '@types'
import SelectArrow from '@images/select-arrow.svg'
import './Select.scss'
import cls from 'classnames'

type SelectInputProps = Omit<
  InputProps,
  | 'name'
  | 'label'
  | 'value'
  | 'type'
  | 'defaultValue'
  | 'disabled'
  | 'error'
  | 'rounded'
  | 'clearable'
  | 'required'
  | 'hideCursor'
  | 'ref'
  | 'onChange'
  | 'onFocus'
  | 'onBlur'
  | 'onKeyDown'
  | 'onClear'
>

type SelectDropdownProps = Omit<DropdownProps, 'options' | 'onSelect' | 'toggle'>

export type SelectProps = {
  name: string
  className?: string
  classNameDropdown?: string
  placeholder?: string
  label?: string
  disabled?: boolean
  required?: boolean
  clearable?: boolean
  error?: boolean
  rounded?: boolean
  innerRef?: RefObject<HTMLInputElement>
  input?: SelectInputProps // TODO find more elegant way to do this
  onInputChange?: (value: string) => void
  dropdown?: SelectDropdownProps
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onClear?: () => void
  isDropUp?: true
  getSelectedOption?: (value: OptionType) => void
  value?: OptionType | string
} & WithToggleProps &
  WithOptionSelectProps

export type SelectPropsWithHocs = SelectProps & WithOptionSelectPropsPassed & WithTogglePropsPassed

const Select: React.FC<SelectPropsWithHocs> = props => {
  const {
    name,
    label,
    placeholder,
    input = {},
    dropdown = {},
    innerRef,
    options,
    selectedOption,
    toggle,
    error,
    rounded,
    disabled,
    required,
    clearable,
    className,
    classNameDropdown,
    handleOptionSelect,
    getSelectedOption,
    handleToggle,
    onFocus,
    onBlur,
    onKeyDown,
    onClear,
    isDropUp,
    value
  } = props

  const handleSelectFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    /* If button in the suffix is clicked don't open the dropdown */
    if (e.target instanceof HTMLButtonElement) return
    handleToggle(true)
    if (onFocus) onFocus(e)
  }

  const handleSelectBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      handleToggle(false)
      if (onBlur) onBlur(e)
    }
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) onKeyDown(e)
    else {
      const isBackspaceKey = e.key === 'Backspace'

      if (isBackspaceKey && clearable) {
        handleOptionSelect(null)
      }
    }
  }

  const handleInputClear = () => {
    handleToggle(false)
    handleOptionSelect(null)
    if (onClear) onClear()
  }

  const handleDropdownSelect = (option: OptionType) => {
    handleOptionSelect(option)
    handleToggle(false)
    getSelectedOption && getSelectedOption(option)
  }

  /* If disabled no focus */
  const tabIndex = disabled ? -1 : 0
  // const inputValue = value?.label || (selectedOption && selectedOption.label) || ''
  const getInputValue = () => {
    if (typeof value === 'string') return (selectedOption && selectedOption.label) || ''
    if (typeof value === 'object') return value?.label || ''
    return ''
  }

  const { suffix, direction, ...otherInputProps } = input
  const { ...otherDropdownProps } = dropdown

  return (
    <div
      className={cls('select', className)}
      tabIndex={tabIndex}
      onFocus={handleSelectFocus}
      onBlur={handleSelectBlur}
      data-disabled={disabled}
    >
      <Input
        autoComplete={'off'}
        placeholder={placeholder}
        name={name}
        label={label}
        value={getInputValue()}
        ref={innerRef}
        onKeyDown={handleInputKeyDown}
        onClear={handleInputClear}
        disabled={disabled}
        // autoComplete={'disabled'}
        error={error}
        clearable={clearable}
        required={required}
        rounded={rounded}
        direction={direction}
        readOnly={true}
        suffix={
          <>
            <SelectArrow onClick={() => handleToggle(!toggle)} className={'select-arrow'} data-toggle={toggle} />
            {suffix}
          </>
        }
        {...otherInputProps}
      />
      <Dropdown
        className={classNameDropdown}
        isDropUp={isDropUp}
        options={options}
        toggle={toggle}
        direction={direction}
        onSelect={handleDropdownSelect}
        {...otherDropdownProps}
      />
    </div>
  )
}

Select.defaultProps = {
  input: {
    placeholder: 'Select an option'
  },
  dropdown: {}
}

export default withOptionSelect(withToggle(Select))
