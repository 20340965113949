import { useState, useEffect } from 'react'

const useOnScreen = (ref: any, rootMargin = '0px') => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)

  const [currentRef, setCurrentRef] = useState<Element>()

  useEffect(() => {
    if (ref && ref.current) setCurrentRef(ref.current)

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin
      }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    if (currentRef) {
      return () => {
        observer.unobserve(currentRef)
      }
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting
}

export default useOnScreen
