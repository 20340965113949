import React, { memo } from 'react'
import { PopupMenu, PopupPositionProp } from '@elements'
import ActionsImage from '@images/actions.svg'
import styles from './ActionsPopupMenu.module.scss'

interface ActionsPopupMenuProps {
  position?: PopupPositionProp
  options: { label: string; value: string; icon: string; onClick: () => void }[]
}

const ActionsPopupMenu: React.FC<ActionsPopupMenuProps> = props => {
  const { position, options } = props

  /* value: * disables link logic */

  return (
    <div className={styles.actionsPopupMenu}>
      <PopupMenu position={position!} options={options}>
        <ActionsImage />
      </PopupMenu>
    </div>
  )
}

ActionsPopupMenu.defaultProps = {
  position: 'bottom right'
}

export default memo(ActionsPopupMenu)
