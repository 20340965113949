import { gql } from '@apollo/client'

export const SignIn = gql`
  mutation SignIn($email: String!, $password: String!) {
    res: signIn(email: $email, password: $password) {
      messages {
        code
        field
        message
        options {
          key
          value
        }
        template
      }
      result {
        token
      }
      successful
    }
  }
`

export const SignUp = gql`
  mutation SignUp($email: String, $firstName: String, $lastName: String, $password: String) {
    res: signUp(email: $email, firstName: $firstName, lastName: $lastName, password: $password) {
      messages {
        code
        field
        message
        options {
          key
          value
        }
        template
      }
      result {
        token
      }
      successful
    }
  }
`
export const ForgotPassword = gql`
  mutation ForgotPassword($email: String!) {
    res: forgotPassword(email: $email) {
      successful
    }
  }
`
