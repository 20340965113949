import React, { memo, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { section } from '@graphql'
import { ISections } from '../../types/sections'
import styles from './Sections.module.scss'

const Sections: React.FC = () => {
  const [fetchSection, { data }] = useLazyQuery<ISections>(section.Section, { fetchPolicy: 'cache-first' })

  useEffect(() => {
    fetchSection()
  }, [])

  const sections = data?.sections

  return (
    <div>
      <div>
        {sections?.map(section => {
          const { id, title, type, items, link: sectionLink } = section
          const currentLengthSmall = items.length === 12 || items.length === 6 || items.length === 18
          const condition = type === 'SMALL' && !currentLengthSmall

          if (condition) return null
          if (type === 'BIG' && items.length != 3) return null
          if (type === 'MEDIUM' && items.length != 1) return null

          return (
            <div key={id}>
              {(type === 'SMALL' && (
                <div>
                  <div className={styles.section}>
                    <div className={styles.sectionTitle}>{title}</div>
                    {sectionLink && (
                      <div className={styles.allSection}>
                        <div className={styles.all}>
                          <a className={styles.allSection} href={sectionLink}>
                            All
                            <svg
                              width="8"
                              height="13"
                              viewBox="0 0 8 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 0.5L7 6.5L1 12.5" stroke="black" />
                            </svg>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.sectionsPhotoCount}>
                    {items.map(item => {
                      const { avatar, link, id } = item
                      // const newlink = link.split("https://hotcake.golf/").join("http://localhost:8000/");
                      //  const falselink = newlink.replace("mySettings=true", "")
                      const itemlink = link.replace('mySettings=true', '')
                      return (
                        <div key={id}>
                          <a href={itemlink}>
                            <img className={styles.sectionsPhoto} src={avatar} loading={'lazy'} alt={id} />
                          </a>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )) ||
                (type === 'MEDIUM' && (
                  <div className={styles.sectionMediumWrapper}>
                    <div className={styles.section}>
                      <div className={styles.sectionTitle}>{title}</div>
                      {sectionLink && (
                        <div className={styles.allSection}>
                          <div className={styles.all}>
                            <a className={styles.allSection} href={sectionLink}>
                              All
                              <svg
                                width="8"
                                height="13"
                                viewBox="0 0 8 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1 0.5L7 6.5L1 12.5" stroke="black" />
                              </svg>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.sectionsPhotoCountBig}>
                      {items.map((item, index: number) => {
                        const { avatar, link, id } = item

                        return (
                          <div key={id} className={`golf-item-${index + 1}`}>
                            <a className={styles.link} href={link}>
                              <img className={styles.photoBig} loading={'lazy'} src={avatar} alt={id} />
                            </a>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )) ||
                (type === 'BIG' && (
                  <div className={styles.bannerWrapper}>
                    <div className={styles.section}>
                      <div className={styles.sectionTitle}>{title}</div>
                      {sectionLink && (
                        <div className={styles.allSection}>
                          <div className={styles.all}>
                            <a className={styles.allSection} href={sectionLink}>
                              All
                              <svg
                                width="8"
                                height="13"
                                viewBox="0 0 8 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1 0.5L7 6.5L1 12.5" stroke="black" />
                              </svg>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.banner}>
                      {items.map(item => {
                        const { avatar, link, id } = item
                        return (
                          <div className={styles.bannerImg} key={id}>
                            <a href={link}>
                              <img loading={'lazy'} className={styles.bannerImg} src={avatar} alt={id} />
                            </a>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ))}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default memo(Sections)
