import { useQuery } from '@apollo/client'
import { store as graphqlStore, user } from '@graphql'
import { useNotifications, useWindowScroll } from '@hooks'
import BellIcon from '@images/bell.svg'
import DeclinedCard from '@images/declined-card.svg'
import DiscountIcon from '@images/discount-icon.svg'
import ArrowLeftImg from '@images/guide-arrow-left.svg'
import { CacheUser, Notification, NotificationType, ResponseType, StripeAccountUrlResponseType } from '@types'
import { get12HourFromDate, getNotificationDescription } from '@utils'
import { navigate } from 'gatsby'
import React, { memo } from 'react'
import PopupComponent from 'reactjs-popup'
import styles from './NotificationPopupMenu.module.scss'

const NotificationPopupMenuItem: React.FC<Notification> = props => {
  const { golfClub, golfClubModel, golfEquipment, golfEquipmentModel, insertedAt, purchaseRequest, type } = props

  const insertedDate = new Date(insertedAt)

  const isSellerPaymentError = type === NotificationType.SELLER_TAXES_PAYMENT_ERROR /// Seller card error, buyer notification
  const isTaxesPaymentError = type === NotificationType.TAXES_PAYMENT_ERROR /// Seller card error, seller notification
  const isPaymentError = type === NotificationType.PAYMENT_ERROR /// Buyer card error
  const hasError = isTaxesPaymentError || isSellerPaymentError || isPaymentError

  /**
   * Get notification redirect URL by NotificationType
   * @param { NotificationType } notificationType {@link NotificationType | Type of notification}
   */
  const getNotificationNavigationPathByType = (notificationType: NotificationType) => {
    const golfItemId = golfEquipment?.id || golfClub?.id
    const itemTypeHash = golfClub?.id ? 'golfClub' : 'golfEquipment'
    const orderId = golfClub?.order?.id || golfEquipment?.order?.id

    switch (notificationType) {
      case NotificationType.GOLF_CLUB_SOLD:
        return `/account/store/my-sales-history/${orderId}/details`
      case NotificationType.GOLF_EQUIPMENT_SOLD:
        return `/account/store/my-sales-history/${orderId}/details`
      case NotificationType.TAXES_PAYMENT_ERROR:
        return '/account/profile'
      case NotificationType.PAYMENT_ERROR:
        return '/account/profile'
      case NotificationType.SELLER_TAXES_PAYMENT_ERROR:
        return '/browse'
      case NotificationType.ITEM_CREATED:
        return `/account/store/my-golf-club-inventory/${golfItemId}/details#${itemTypeHash}`
      case NotificationType.ORDER_CREATED:
        return `/account/order-history/${orderId}/details`
      case NotificationType.PURCHASE_REQUEST_CREATED:
        return `/account/my-offer-book/${purchaseRequest?.id}/details`
      default:
        return '/browse'
    }
  }

  const notificationTitle = golfEquipmentModel?.title || golfClubModel?.title
  const notificationImagePath = golfEquipmentModel?.avatar || golfClubModel?.avatar
  const notificationDescription = getNotificationDescription(type)
  const notificationNavigationPath = getNotificationNavigationPathByType(type)
  const notificationFormattedTime = get12HourFromDate(insertedDate)

  /**
   * Render payment error notifications
   * * TAXES_PAYMENT_ERROR
   * * PAYMENT_ERROR
   * * SELLER_TAXES_PAYMENT_ERROR:
   */
  const renderErrorNotification = () => {
    return (
      <div>
        <div
          onClick={() => navigate(notificationNavigationPath)}
          className={styles.notificationPopupMenuContentListItem}
        >
          <DeclinedCard />
          <div className={styles.notificationPopupMenuContentListItemInfo}>
            <div className={styles.notificationPopupMenuContentListItemInfoName}>
              {isSellerPaymentError ? 'Seller payment error.' : 'Payment error.'}
            </div>
            <div className={styles.notificationPopupMenuContentListItemInfoNotification}>{notificationDescription}</div>
            <div className={styles.notificationPopupMenuContentListItemInfoTime}>{notificationFormattedTime}</div>
          </div>
        </div>
      </div>
    )
  }

  if (hasError) return renderErrorNotification()

  return (
    <div onClick={() => navigate(notificationNavigationPath)} className={styles.notificationPopupMenuContentListItem}>
      <img className={styles.notificationPopupMenuContentListItemImage} src={notificationImagePath} alt={'avatar'} />
      <div className={styles.notificationPopupMenuContentListItemInfo}>
        <div className={styles.notificationPopupMenuContentListItemInfoName}>{notificationTitle}</div>
        <div className={styles.notificationPopupMenuContentListItemInfoNotification}>{notificationDescription}</div>
        <div className={styles.notificationPopupMenuContentListItemInfoTime}>{notificationFormattedTime}</div>
      </div>
    </div>
  )
}

/**
 * Render notifications
 * @param newNotifications - not viewed notifications { viewed: false }
 * @param oldNotifications - viewed notifications { viewed: true }
 * @param isUserStripeAccountNotSubmitted - flag if current user seller, but stripe account is not submitted
 * @param stripeAccountUrl - stripe url submit account
 */
const renderNotifications = (
  oldNotifications: Notification[],
  newNotifications: Notification[],
  isUserStripeAccountNotSubmitted?: boolean,
  stripeAccountUrl?: string
) => {
  /**
   * If no notification render empty label
   */
  const renderEmptyNotification = () => {
    const allNotificationsCount = oldNotifications?.length + newNotifications?.length
    if (allNotificationsCount === 0 && !isUserStripeAccountNotSubmitted) {
      return (
        <div className={styles.notificationPopupMenuContentListEmpty}>
          <span>You have no notifications</span>
        </div>
      )
    }
  }

  /**
   * Render STRIPE account submit notification
   */
  const renderStripeNotification = () => {
    if (isUserStripeAccountNotSubmitted && stripeAccountUrl) {
      return (
        <a href={stripeAccountUrl} className={styles.notificationPopupMenuContentListItem}>
          <DiscountIcon style={{ width: '25px', height: '25px' }} />
          <div className={styles.notificationPopupMenuContentListItemInfo}>
            <div>Verification completed</div>
            <div>Your payout method has been verified!</div>
            <div />
          </div>
        </a>
      )
    }
  }

  /**
   * Render Not viewed notifications
   * { viewed: false }
   */
  const renderNewNotifications = () => {
    return (
      <>
        {!!newNotifications?.length && <div className={styles.notificationsGroupLabel}>NEW</div>}
        {newNotifications?.map((notification, index) => {
          return <NotificationPopupMenuItem key={index} {...notification} />
        })}
      </>
    )
  }

  /**
   * Render viewed notifications
   * { viewed: true }
   */
  const renderOldNotifications = () => {
    return (
      <>
        {!!oldNotifications?.length && <div className={styles.notificationsGroupLabel}>BEFORE</div>}
        {oldNotifications?.map((notification, index) => {
          return <NotificationPopupMenuItem key={index} {...notification} />
        })}
      </>
    )
  }

  return (
    <>
      <div style={{ overflowY: 'scroll', padding: '0px' }}>
        {renderEmptyNotification()}
        {renderStripeNotification()}
        {renderNewNotifications()}
        {renderOldNotifications()}
      </div>
    </>
  )
}

interface NotificationPopupMenuProps {
  isMobile?: boolean
}

const NotificationPopupMenu: React.FC<NotificationPopupMenuProps> = props => {
  const { isMobile } = props

  const { oldNotifications, newNotifications, viewNotifications } = useNotifications()
  const scroll = useWindowScroll()

  const { data: currentUserData } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const { data: stripeAccountUrlData } = useQuery<ResponseType<StripeAccountUrlResponseType>>(
    graphqlStore.GetStripeAccountUrl,
    { fetchPolicy: 'cache-first', variables: { redirectUrl: '/account/store' } }
  )

  const currentUser = currentUserData && currentUserData.res

  const isUserStripeAccountNotSubmitted =
    currentUser && !currentUser.stripeAccountSubmitted && currentUser.sellerStatus === 'SELLER'

  const stripeAccountUrl = stripeAccountUrlData?.res?.url

  /**
   * Render BellIcon - notifications popup trigger
   */
  const renderTrigger = () => {
    // If stripe account not submitted add + 1 notification
    const newNotificationsCount = newNotifications?.length + (isUserStripeAccountNotSubmitted ? 1 : 0)
    return (
      <div className={styles.notificationPopupMenuTrigger}>
        <BellIcon />
        {(!!newNotifications.length || isUserStripeAccountNotSubmitted) && (
          <div className={styles.notificationPopupMenuTriggerCounter}>{newNotificationsCount}</div>
        )}
      </div>
    )
  }

  /**
   * Render popup content
   * @param { Function } close Close popup callback
   */
  const renderContent = (close: () => void) => {
    return (
      <div className={styles.notificationPopupMenuContent}>
        <div className={styles.notificationPopupMenuContentHeader}>
          {isMobile && (
            <div onClick={close}>
              <ArrowLeftImg />
            </div>
          )}
          Notification
        </div>
        <div className={styles.notificationPopupMenuContentList}>
          {renderNotifications(oldNotifications, newNotifications, isUserStripeAccountNotSubmitted, stripeAccountUrl)}
        </div>
      </div>
    )
  }

  return (
    <PopupComponent
      trigger={renderTrigger}
      offsetY={-scroll}
      open={false}
      modal={isMobile}
      closeOnEscape
      lockScroll={isMobile}
      position={'bottom right'}
      contentStyle={{
        width: isMobile ? '100%' : '340px',
        padding: isMobile ? '20px 0 70px 0' : '0px 0px 0px 0px',
        height: isMobile ? '100%' : '',
        top: isMobile ? '50px' : '',
        position: 'fixed'
      }}
      onClose={viewNotifications}
    >
      {renderContent}
    </PopupComponent>
  )
}

export default memo(NotificationPopupMenu)
