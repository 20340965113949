import React, { useState } from 'react'
import styles from './AvailableSelection.module.scss'
import { useUrlSearchParams } from '@hooks'

interface AvailableSelectionProps {}

const AvailableSelection: React.FC<AvailableSelectionProps> = props => {
  const { setUrlSearchParam: setUrlSearchAvailableParam, getUrlSearchParam } = useUrlSearchParams('available')
  const [active, setActive] = useState(getUrlSearchParam() || 'ALL')

  const handleClick = async (select: string) => {
    setActive(select)
    await setUrlSearchAvailableParam(select)
  }

  return (
    <div className={styles.availableSelection}>
      <div className={styles.availableSelectionWrapper}>
        <div
          className={styles.availableSelectionPart}
          onClick={() => handleClick('SELL')}
          data-active={active === 'SELL'}
        >
          SELL NOW
        </div>
        <div
          className={styles.availableSelectionPart}
          style={{ borderLeft: '1px solid #7a7a7a', borderRight: '1px solid #7a7a7a' }}
          onClick={() => handleClick('ALL')}
          data-active={active === 'ALL'}
        >
          SHOW ALL
        </div>
        <div
          className={styles.availableSelectionPart}
          onClick={() => handleClick('BUY')}
          data-active={active === 'BUY'}
        >
          BUY NOW
        </div>
      </div>
    </div>
  )
}

export default AvailableSelection
