import React, { memo } from 'react'
import styles from './Container.module.scss'
import { Children } from '@types'

export interface ContainerProps {
  withCard?: boolean
  relative?: boolean
  isGreyBackground?: boolean
}

const Container: React.FC<ContainerProps> = props => {
  const { children, withCard, relative = true, isGreyBackground } = props

  if (withCard) {
    return (
      <div className={styles.container} data-card={true} data-relative={relative}>
        <div className={styles.containerCard}>{children}</div>
      </div>
    )
  }

  return (
    <div className={styles.container} data-relative={relative} data-grey-background={isGreyBackground}>
      {children}
    </div>
  )
}

export default memo(Container)
