import { gql } from '@apollo/client'

import { ParseCSVAttr, ValidateCSVAttr } from '@fragments'

export const ParseCSV = gql`
  query ImportCSVParse($file: Upload!) {
    res: importCSVParse(file: $file) {
      ...ParseCSVAttr
    }
  }

  ${ParseCSVAttr}
`

export const ValidateCSV = gql`
  query ImportCSVValidate($rows: [importCSVValidateArgs]) {
    res: importCSVValidate(rows: $rows) {
      rejected {
        ...ParseCSVAttr
      }
      approved {
        ...ParseCSVAttr
      }
      result {
        ...ValidateCSVAttr
      }
    }
  }

  ${ValidateCSVAttr}
  ${ParseCSVAttr}
`

export const InsertCSV = gql`
  mutation ImportCSVInsert($args: [importCSVInsertArgs]) {
    res: importCSVInsert(args: $args) {
      status
      error {
        code
        detail
      }
      result
    }
  }
`
