import React from 'react'
import { Link } from 'gatsby'
import { GolfClubDexterity } from '@types'

export const prices = [
  {
    id: '1',
    label: 'Under $100',
    filter: { minPrice: 0, maxPrice: 100 }
  },
  {
    id: '2',
    label: '$100-250',
    filter: { minPrice: 100, maxPrice: 250 }
  },
  {
    id: '3',
    label: '$250-500',
    filter: { minPrice: 250, maxPrice: 500 }
  },
  {
    id: '4',
    label: '$500 - $1000',
    filter: { minPrice: 500, maxPrice: 1000 }
  },
  {
    id: '5',
    label: '$1000 - $1500',
    filter: { minPrice: 1000, maxPrice: 1500 }
  },
  {
    id: '6',
    label: '$1500 - $2000',
    filter: { minPrice: 1500, maxPrice: 2000 }
  },
  {
    id: '7',
    label: '$2000+',
    filter: { minPrice: 2000, maxPrice: 99999 }
  }
]

export const dexterities: GolfClubDexterity[] = ['LEFT', 'RIGHT']
export const shafts = ['Stock']
export const lengths = ['+1.5', '+1', 'Standart']

export const sortings = [
  { value: 'MOST_POPULAR_BRANDS', label: 'Most Popular' },
  { value: 'LOWEST_PRICE', label: 'New Lowest Price' },
  { value: 'RECOMMENDED', label: 'Recommended for You' },
  { value: 'highest_bids', label: 'New Highest Bids' } // todo elixir back-end ?
]

export const catalogueSortByOptions = [
  { value: 'LATEST_ACTIVITY', label: 'Sort By: Latest Activity' },
  { value: 'MOST_POPULAR', label: 'Sort By: Most Popular' },
  { value: 'NEWLY', label: 'Sort By: Newly Indexed' },
  { value: 'PRICE_ASC', label: 'Sort By: Price Low to High' },
  { value: 'PRICE_DESC', label: 'Sort By: Price High to Low' },
  { value: 'RELEASE_YEAR_ASC', label: 'Sort By: Release Year (Oldest)' },
  { value: 'RELEASE_YEAR_DESC', label: 'Sort By: Release Year (Newest)' }
]

export const catalogueAvailableOptions = [
  { label: 'All', value: 'ALL' },
  { label: 'BUY', value: 'BUY' },
  { label: 'SELL', value: 'SELL' }
]

export const DESCRIPTION_BY_CATEGORY = {
  all:
    "TourDay Golf is the ultimate golf marketplace for avid golfers and collectors worldwide. It's a one-stop shop for new as well as premium pre-owned golf clubs and equipment of every kind. You can make an offer on anything!",
  Putters:
    'Odyssey, Scotty Cameron, TaylorMade, Ping and more! Buy and sell the top putters right here on TourDay Golf',
  Drivers:
    'TaylorMade, Callaway, XXIO, Srixon, Ping, Titleist and more! Buy and sell the latest (or the classics) right here',
  Hybrids: 'Callaway, Titleist, TaylorMade, Adams and more. Buying and selling has never been easier',
  FairwayWoods: 'PXG, TaylorMade, Callaway, Ping, and more. Buy and sell the most popular fairway woods',
  Irons:
    'Shop our full sets of Titleist, TaylorMade, PXG, & XXIO, or replace that one missing iron in your set - all on TourDay Golf',
  Wedges: 'Cleveland, Mizuno, Ping, and more - improve your game today',
  Limited: 'Rare, limited, and one-of-one Scotty Cameron’s, Bettinardi’s and more!'
}

export const CATALOGUE_IMAGE_PATH_BY_CATEGORY = {
  all: '/assets/images/catalogue-hero-all.webp',
  Putters: '/assets/images/catalogue-hero-mobile-putters.webp',
  Drivers: '/assets/images/catalogue-hero-mobile-drivers.webp',
  Hybrids: '/assets/images/catalogue-hero-mobile-hybrids.webp',
  FairwayWoods: '/assets/images/catalogue-hero-mobile-fairway.webp',
  Irons: '/assets/images/catalogue-hero-mobile-ironsets.webp',
  Wedges: '/assets/images/catalogue-hero-mobile-wedges.webp',
  Limited: '/assets/images/catalogue-hero-mobile-limited.webp'
}

export const FAQData = [
  {
    section: 'Order',
    shortName: 'Order',
    anchorId: 'order',
    questions: [
      {
        question: 'How do I buy something on TourDay Golf?',
        answer: (
          <div>
            There are two ways to buy on our marketplace. Once you&lsquo;ve located the golf club model you want, you
            can Set a Buy Price or Buy Now. Set a Buy Price means you&lsquo;d like to make an offer. If a Seller accepts
            your offer, you will be charged on your default payment method. The other option to buy on our marketplace
            is via the Buy Now option. Feel free to visit our <Link to={'/about/buying'}>Buying Guide</Link> for more
            details!
          </div>
        )
      }
    ]
  },
  {
    section: 'Payment & Fees',
    shortName: 'Payment',
    anchorId: 'payment',
    questions: [
      {
        question: 'I see a processing fee, what does that mean?',
        answer: 'TourDay Golf charges a standard 13% to sellers on our platform.'
      }
    ]
  },
  {
    section: 'Shipping',
    shortName: 'Shipping',
    anchorId: 'shipping',
    questions: [
      {
        question: `For Buyers: I’ve just bought a golf club, how does shipping work?`,
        answer: `Generally, you will receive your order within 3-5 business days.`
      }
    ]
  },
  {
    section: `Returns and Cancellations`,
    shortName: 'Returns',
    anchorId: 'return',
    questions: [
      {
        question: 'Can I cancel or return my golf club?',
        answer: `We don't accept cancellations. Once you've made an offer or confirmed a purchased, you commit to the order. Please be sure that you want the golf club before setting a buy price or buying now. What makes TourDay Golf great, is you can easily sell the golf club back on our marketplace!`
      }
    ]
  }
]

export const driverLofts = {
  items: [10, 10.5, 11, 12, 13],
  symbol: '\u00B0'
}
export const fairwayWoodLofts = {
  items: [13.5, 15, 17, 19, 20, 21, 23, 25],
  symbol: ''
}
export const putterLengths = {
  items: [32, 32.5, 33, 33.5, 34, 34.5, 35, 35.5, 36],
  symbol: '"'
}
export const hybridLofts = {
  items: [10.5, 14, 16, 18, 21, 24, 27, 30, 34, 38, 42, 46, 50, 55, 60],
  symbol: ''
}
export const ironSetNumbers = {
  items: [2, 3, 4, 5, 6, 7, 8, 9],
  symbol: ''
}
export const wedgesBounceAngles = {
  items: [2, 4, 5, 6, 8, 10],
  symbol: '\u00B0'
}

export const stateCodes = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Ontario: 'CA',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  Ramey: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Sublimity: 'OR',
  Tennessee: 'TN',
  Texas: 'TX',
  Trimble: 'VA',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY'
}

export const experienceLevelOption = [
  { value: 'new', label: 'I’m new to the game' },
  { value: 'few_times', label: 'I play a few times a year' },
  { value: 'track_handicap', label: 'I track my handicap' },
  { value: 'scratch', label: 'Scratch' }
]

export const priceOption = [
  { value: '500', label: 'Under $500' },
  { value: '1000', label: 'Under $1000' },
  { value: '2000', label: 'Under $2000' },
  { value: '3000', label: 'Under $3000' },
  { value: '4000', label: 'Under $4000' }
]

export const USState = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' }
]

export const clothesCategoriesTitles = ['Sandals', 'Golf Polos', 'Golf Headwear', 'Golf Shoes', 'Golf Mid Layers']
