import { gql } from '@apollo/client'
import { BrandAttrs, CategoryAttrs } from '@fragments'

export const FetchBrowsePages = gql`
  query browsePages {
    res: browsePages {
      id
      slug
      avatar
      description
      title
      activeMenu
      brands {
        ...BrandAttrs
      }
      categories {
        ...CategoryAttrs
      }
      optionClasses {
        id
        title
        options {
          id
          title
          preSort
        }
      }
    }
  }
  ${BrandAttrs}
  ${CategoryAttrs}
`

export const FetchBrowsePageSlug = gql`
  query BrowsePageSlug($slug: String!) {
    res: browsePageSlug(slug: $slug) {
      includeIds
      activeMenu
      avatar
      brands {
        ...BrandAttrs
        preSort
      }
      catalogType
      categories {
        ...CategoryAttrs
        preSort
      }
      description
      equipmentCategories {
        id
        insertedAt
        title
        updatedAt
        preSort
      }
      id
      insertedAt
      maxPrice
      minPrice
      menuTitle
      optionClasses {
        id
        insertedAt
        title
        updatedAt
        options {
          avatar
          description
          id
          insertedAt
          preSort
          title
          updatedAt
        }
      }
      options {
        avatar
        description
        id
        insertedAt
        optionClass {
          id
          insertedAt
          title
          updatedAt
        }
        preSort
        title
        updatedAt
      }
      profPlayers {
        avatar
        description
        golfEvents {
          date
          event
          golfCourse {
            address
            city
            country
            designedBy
            id
            insertedAt
            state
            title
            updatedAt
            yearFounded
          }
          id
          insertedAt
          key
          updatedAt
          winnerId {
            avatar
            description
            id
            insertedAt
            title
            updatedAt
          }
          year
        }
        id
        insertedAt
        title
        updatedAt
      }
      releaseYears
      slug
      sorting
      title
      updatedAt
    }
  }
  ${BrandAttrs}
  ${CategoryAttrs}
`
