import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { Children } from '@types'
import styles from './ValidationErrorMessage.module.scss'

export type ValidationErrorMessageType = { message: string }
export interface ValidationErrorMessageProps {
  name: string
  render?(error: ValidationErrorMessageType, className: string): Children
  error?: string
}

/*
  Validation error component for react-hook-form.
  Remember that you can use this component to render anything you want when field is not valid.
  You can render Modal if validation won't complete. If you want to use this component in a different way -
  create another component with different name. For example ValidationConfirmPassword component opens a modal window
  to ask an admin password to do a high risk operation in the app.
*/

/*
  Multiple Error Messages Example

  <ValidationErrorMessage
    errors={errors}
    name="multipleErrorInput"
    render={({ messages }) =>
      messages &&
      Object.entries(messages).map(([type, message]) => (
        <p key={type}>{message}</p>
      ))
    }
  />
*/

const ValidationErrorMessage: React.FC<ValidationErrorMessageProps> = props => {
  const { name, render, error } = props
  const formContext = useFormContext()

  /* Gets error from errors object by name and passes it in render */
  const renderErrorMessage = (error: ValidationErrorMessageType) => {
    if (render) return render(error, styles.validationError)
    else {
      if (!error.message) return null

      return (
        <div role="validation-error" className={styles.validationError}>
          {error.message}
        </div>
      )
    }
  }

  if (error) {
    return <>{renderErrorMessage({ message: error })}</>
  }

  if (!formContext) return null

  return <ErrorMessage errors={formContext.errors} name={name} render={renderErrorMessage} />
}

export default memo(ValidationErrorMessage)
