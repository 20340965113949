import React, { CSSProperties } from 'react'
import GoldClubModelTestImage from '@images/golf-club-model-test.webp'
import BrandTestImage from '@images/brand-test.webp'
import styles from './AvatarCard.module.scss'

interface AvatarCardProps {
  avatar: string | null
  type?: 'golf-club' | 'brand'
  size?: 'lg' | 'md' | 'sm' | 'xs'
  style?: CSSProperties
}

const AvatarCard: React.FC<AvatarCardProps> = props => {
  const { avatar, type, size, style } = props
  let width = ''
  let height = ''

  switch (size) {
    case 'lg': {
      const remSize = 518 / 14 + 'rem'
      width = remSize
      height = remSize
      break
    }
    case 'md': {
      const remSize = 258 / 14 + 'rem'
      width = remSize
      height = remSize
      break
    }
    case 'xs': {
      const remSize = 65 / 14 + 'rem'
      width = remSize
      height = remSize
      break
    }
    default: {
      const remSize = 192 / 14 + 'rem'
      width = remSize
      height = remSize
      break
    }
  }

  const defaultImage = type === 'golf-club' ? GoldClubModelTestImage : BrandTestImage

  return (
    <div className={styles.avatarCard} data-id={'avatar-card'} style={{ width, height, position: 'relative' }}>
      <img
        className={styles.avatarCardImg}
        src={avatar || defaultImage}
        alt={'avatar'}
        style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, ...(style || {}) }}
      />
    </div>
  )
}

AvatarCard.defaultProps = {
  type: 'golf-club'
}

export default AvatarCard
