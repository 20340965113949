import React, { memo } from 'react'
import { Tooltip } from '@elements'
import CheckMark from '@images/check-mark.svg'
import styles from './CheckOption.module.scss'

interface CheckOptionProps {
  label: string
  toolTipValue: string
}

const CheckOption: React.FC<CheckOptionProps> = props => {
  const { label, toolTipValue } = props
  return (
    <div className={styles.checkOption}>
      <div className={styles.checkOptionCheckMark}>
        <CheckMark />
      </div>
      <div className={styles.checkOptionLabel}>{label}</div>
      <div className={styles.checkOptionToolTip}>
        <Tooltip>{toolTipValue}</Tooltip>
      </div>
    </div>
  )
}

// CheckOption.defaultProps = {
//   label: 'Verified',
//   toolTipValue: '* Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet'
// }

export default memo(CheckOption)
