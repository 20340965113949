import { gql } from '@apollo/client'
import {
  AddressCardAttrs,
  CreateExistingProductFeedbackResponseAttrs,
  CreateNewProductFeedbackResponseAttrs,
  LockerAttrs,
  PaymentCardAttrs,
  SharedUserAttrs,
  SubscribedEmailAttrs,
  UserAttrs,
  UserBasicInfoAttrs,
  UserResponseAttrs
} from '@fragments'

export const FetchCurrentUser = gql`
  query FetchCurrentUser {
    res: currentUser {
      isAdmin
      addressCards {
        ...AddressCardAttrs
      }
      basicInfo {
        ...UserBasicInfoAttrs
      }
      defaultAddressCard {
        ...AddressCardAttrs
      }
      avatar
      email
      emailApproved
      firstName
      gender
      id
      insertedAt
      lastName
      paymentCards {
        ...PaymentCardAttrs
      }
      sellerStatus
      stripeAccountSubmitted
      updatedAt
      userName
      defaultPaymentCard {
        ...PaymentCardAttrs
      }
      locker {
        ...LockerAttrs
      }
    }
  }
  ${LockerAttrs}
  ${AddressCardAttrs}
  ${UserBasicInfoAttrs}
  ${PaymentCardAttrs}
`

export const UserActions = gql`
  query UserActions {
    res: userActions {
      sold
      bought
    }
  }
`

export const FetchSharedUser = gql`
  query FetchSharedUser($id: UUID4!) {
    res: user(id: $id) {
      ...SharedUserAttrs
    }
  }

  ${SharedUserAttrs}
`

export const UpdateCurrentUser = gql`
  mutation UpdateCurrentUser($user: UserParams) {
    res: updateCurrentUser(user: $user) {
      ...UserResponseAttrs
      result {
        basicInfo {
          ...UserBasicInfoAttrs
        }
        defaultAddressCard {
          ...AddressCardAttrs
        }
        avatar
        email
        emailApproved
        firstName
        gender
        id
        insertedAt
        lastName
        paymentCards {
          ...PaymentCardAttrs
        }
        sellerStatus
        stripeAccountSubmitted
        updatedAt
        userName
        defaultPaymentCard {
          ...PaymentCardAttrs
        }
        addressCards {
          ...AddressCardAttrs
        }
      }
    }
  }

  ${AddressCardAttrs}
  ${UserBasicInfoAttrs}
  ${PaymentCardAttrs}
  ${UserResponseAttrs}
`

export const UpdateCurrentUserPassword = gql`
  mutation UpdateCurrentUserPassword($newPassword: String!, $oldPassword: String!) {
    res: updateCurrentPassword(newPassword: $newPassword, oldPassword: $oldPassword) {
      ...UserResponseAttrs
    }
  }

  ${UserResponseAttrs}
`

export const createSubscribedEmail = gql`
  mutation createSubscribedEmail($subscribedEmail: SubscribedEmailParams) {
    res: createSubscribedEmail(subscribedEmail: $subscribedEmail) {
      ...SubscribedEmailAttrs
      result {
        email
      }
    }
  }

  ${SubscribedEmailAttrs}
`

export const CreateNewProductFeedback = gql`
  mutation createNewProductFeedback($newProductFeedback: NewProductFeedbackParams!) {
    res: createNewProductFeedback(newProductFeedback: $newProductFeedback) {
      ...CreateNewProductFeedbackResponseAttrs
      result {
        id
        productName
        releaseYear
        retailPrice
        productLink
        email
      }
    }
  }
  ${CreateNewProductFeedbackResponseAttrs}
`

export const CreateExistingProductFeedback = gql`
  mutation createExistingProductFeedback($existingProductFeedback: ExistingProductFeedbackParams!) {
    res: createExistingProductFeedback(existingProductFeedback: $existingProductFeedback) {
      ...CreateExistingProductFeedbackResponseAttrs
      result {
        id
        productLink
        descriptionType
        description
        email
      }
    }
  }
  ${CreateExistingProductFeedbackResponseAttrs}
`

export const ResetCurrentUserStripeAccount = gql`
  mutation ResetCurrentUserStripeAccount {
    res: resetStripeAccount {
      ...UserResponseAttrs
      result {
        ...UserAttrs
      }
    }
  }

  ${UserResponseAttrs}
  ${UserAttrs}
`
