import React, { memo } from 'react'
import { Navigation } from '@elements'
import { useSiteMetadata } from '@hooks'
import styles from './AccountNavigation.module.scss'

interface AccountNavigationProps {}

const AccountNavigation: React.FC<AccountNavigationProps> = props => {
  const { accountNavigation } = useSiteMetadata()
  return (
    <div className={styles.accountNavigation}>
      <Navigation navigation={accountNavigation} direction={'vertical'} activeMatch />
    </div>
  )
}

export default memo(AccountNavigation)
