import { ButtonWithPencil } from '@elements'
import DebitCardImage from '@images/debit-card.svg'
import HouseImage from '@images/house.svg'
import { AddressCard, PaymentCard } from '@types'

import React from 'react'
import styles from './OrderPaymentDetails.module.scss'

interface OrderPaymentDetailsProps {
  address?: AddressCard | null
  payment?: PaymentCard
  onPaymentDetailsEdit?: () => void
  // onAddressEditClick?: (e: React.UIEvent<SVGElement>) => void
  // onPaymentEditClick?: (e: React.UIEvent<SVGElement>) => void
}

const OrderPaymentDetails: React.FC<OrderPaymentDetailsProps> = props => {
  const { address, payment, onPaymentDetailsEdit } = props

  const addressLine = address?.addressLine
  const addressName = address?.address
  const city = address?.city
  const state = address?.state
  const zipCode = address?.zipCode

  if (!address && !payment) return null

  return (
    <>
      <div className={styles.orderPaymentDetailsTitle}>Credit card and shipping address</div>
      <hr />
      <div className={styles.orderPaymentDetails}>
        <div className={styles.orderPaymentDetailsInfo}>
          {payment && (
            <p>
              <DebitCardImage />
              &bull;&bull;&bull;&bull;&nbsp;{payment.number.toString().substr(-4)}
              {/* {onPaymentEditClick && <Pencil className={styles.editIcon} onClick={onPaymentEditClick} />} */}
            </p>
          )}
          {address && (
            <p>
              <HouseImage />
              {addressLine} {addressName} {city + ' '}
              {state}
              {state && zipCode && ', '} {zipCode}
              {/* {onAddressEditClick && <Pencil className={styles.editIcon} onClick={onAddressEditClick} />} */}
            </p>
          )}
        </div>
        <div>
          <ButtonWithPencil onClick={() => onPaymentDetailsEdit && onPaymentDetailsEdit()}>Edit</ButtonWithPencil>
        </div>
      </div>
    </>
  )
}

export default OrderPaymentDetails
