import { Autocomplete, AutocompleteProps } from '@components'
import { golfClubModel } from '@graphql'
import { usePagination } from '@hooks'
import SearchImage from '@images/search.svg'
import { CatalogItem, FiltersArgs, GolfClubModel } from '@types'
import { debounce } from 'lodash'
import React, { memo, useCallback, useState } from 'react'
import styles from './GolfClubModelAutocomplete.module.scss'

type GolfClubModelAutocompleteOmitFields = 'apiOptions' | 'options' | 'loading' | 'loadingError' | 'inputProps' | 'role'

export interface GolfClubModelAutocompleteProps extends Omit<AutocompleteProps, GolfClubModelAutocompleteOmitFields> {
  filters?: FiltersArgs
  defaultGolfClubModel?: GolfClubModel
}

const GolfClubModelAutocomplete: React.FC<GolfClubModelAutocompleteProps> = props => {
  const { filters, defaultGolfClubModel, input: autocompleteInputProps, ...autocompleteProps } = props

  const [term, setTerm] = useState(defaultGolfClubModel ? defaultGolfClubModel.title : '')

  const debouncedSetTerm = useCallback(debounce(setTerm, 300), [])

  const { content = [], loading, error, refetch } = usePagination<GolfClubModel>(
    golfClubModel.FetchPaginatedGolfClubModels,
    {
      term,
      ...filters
    },
    { fetchPolicy: 'no-cache' },
    1,
    7
  )

  const golfClubModelOptionSchema = {
    label: (golfClubModel: GolfClubModel) => {
      const brandName = golfClubModel?.brand?.title || ''
      return brandName + ' ' + golfClubModel.title
    },
    value: 'id'
  }

  const goodOptions = content.map(item => {
    return {
      label: item.brand.title + ' ' + item.title,
      value: item.id
    }
  })

  return (
    <div className={styles.golfClubModelAutocompleteInput}>
      <Autocomplete
        options={goodOptions}
        loading={loading}
        initialSearch={defaultGolfClubModel?.title}
        input={{
          prefix: <SearchImage />,
          ...autocompleteInputProps
        }}
        onInputChange={debouncedSetTerm}
        onBlur={async () => {
          setTerm('')
          await refetch({ term: '', ...filters })
        }}
        onClear={async () => {
          setTerm('')
          await refetch({ term: '', ...filters })
        }}
        {...autocompleteProps}
      />
    </div>
  )
}

export default memo(GolfClubModelAutocomplete)
