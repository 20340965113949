import React, { memo } from 'react'
import { Link } from '@elements'
import { useUrlSearchParams } from '@hooks'
import styles from './LocationTabs.module.scss'

interface LocationTab {
  label: string
  value: string
}

interface LocationTabsProps {
  locationTabs: LocationTab[]
  query: string
  handleChangeTab?: (value: string) => void
  currentValue?: string
}

const LocationTabs: React.FC<LocationTabsProps> = props => {
  const { locationTabs, query, handleChangeTab, currentValue } = props
  const { hasUrlSearchParam, setUrlSearchParam } = useUrlSearchParams(query)

  return (
    <nav className={styles.locationTabs}>
      <ul className={styles.locationTabsList}>
        {locationTabs.map(tab => {
          const { value, label } = tab
          const match = hasUrlSearchParam(value) || value === currentValue

          const handleSetUrlSearchParam = async (e: React.UIEvent) => {
            e.preventDefault()
            if (currentValue && handleChangeTab) {
              handleChangeTab(value)
            } else {
              await setUrlSearchParam(match ? '' : value)
            }
          }

          return (
            <li key={value} className={styles.locationTabsListItem}>
              <Link to={'*'} onClick={handleSetUrlSearchParam} active={match} underlined={match}>
                {label}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default memo(LocationTabs)
