import React from 'react'
import { Select, SelectProps } from '@components'
import { Input } from '@elements'
import { useQuery } from '@apollo/client'
import { Filters, ResponseType } from '@types'
import { filters as graphqlFilters } from '@graphql'
import { capitalize } from 'lodash'
import styles from './GripSizeSelect.module.scss'

export interface GripSizeSelectProps extends Omit<SelectProps, 'options'> {
  userSelect?: boolean
}

const GripSizeSelect: React.FC<GripSizeSelectProps> = props => {
  const { userSelect } = props
  const { data } = useQuery<ResponseType<Filters>>(graphqlFilters.FetchFilters, {
    fetchPolicy: 'cache-only'
  })
  const filters = data ? data.res : ({} as Filters)

  const { gripSizes } = filters
  const options = userSelect ? ['Not Sure', ...gripSizes] : gripSizes

  const gripSizeOptions = [...options]?.reverse()?.map(gripSize => {
    return {
      label: capitalize(gripSize),
      value: gripSize
    }
  })

  return (
    <div className={styles.gripSizeSelect}>
      <Select options={gripSizeOptions} {...props} />
      {/* <Input placeholder={'Enter Grip Size'} /> */}
    </div>
  )
}

export default GripSizeSelect
