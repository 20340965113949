import React from 'react'
import { Select, SelectProps } from '@components'
import { useQuery } from '@apollo/client'
import { Filters, ResponseType } from '@types'
import { filters as graphqlFilters } from '@graphql'
import styles from './HeightSelect.module.scss'

export interface HeightSelectProps extends Omit<SelectProps, 'options'> {}

const HeightSelect: React.FC<HeightSelectProps> = props => {
  const { data } = useQuery<ResponseType<Filters>>(graphqlFilters.FetchFilters, {
    fetchPolicy: 'cache-only'
  })
  const filters = data ? data.res : ({} as Filters)
  const { heights } = filters

  const heightOptions = heights?.map(height => {
    return {
      label: height,
      value: height
    }
  })

  return (
    <div className={styles.heightSelect}>
      <Select options={heightOptions} {...props} />
      {/* <Input placeholder={'Enter height'} /> */}
    </div>
  )
}

export default HeightSelect
