import React, { memo } from 'react'
import styles from './Option.module.scss'

interface OptionProps {
  option: string
  value: string
  onClick(e: React.MouseEvent<HTMLDivElement>): void
  isActive?: boolean
  disabled?: boolean
  isSameTypes?: boolean
  condition?: string
  id: string
  buySellNowAvailable?: boolean
}

const Option: React.FC<OptionProps> = props => {
  const { option, value, onClick, isActive, disabled, isSameTypes, condition, id, buySellNowAvailable } = props

  const returnCondition = () => {
    switch (condition) {
      case 'EXCELLENT':
        return 'MINT CONDITION'
      case 'GOOD':
        return 'GAMED CONDITION'
      default:
        return 'BRAND NEW'
    }
  }

  const buyNowOrActive = isActive ? false : buySellNowAvailable

  return (
    <div
      data-tooltip-id={id}
      className={styles.option}
      onClick={disabled ? () => null : onClick}
      data-active={isActive}
      data-disabled={disabled}
      data-buynow={buyNowOrActive}
    >
      <div className={styles.optionDegree}>{isSameTypes ? returnCondition() : option}</div>
    </div>
  )
}

export default memo(Option)
