import { useLazyQuery } from '@apollo/client'
import { golfClubType as graphqlGolfClubType } from '@graphql'
import { ResponseType } from '@types'
import { useEffect } from 'react'

const useGolfClubModelFlexes = (id?: string) => {
  const [fetchGolfClubFlex, { data: dataGolfClubTypeFlex }] = useLazyQuery<
    ResponseType<string[]>,
    { golfClubModelId: string }
  >(graphqlGolfClubType.FetchGolfClubModelFlexes)

  useEffect(() => {
    if (id) fetchGolfClubFlex({ variables: { golfClubModelId: id } })
  }, [id])

  const returnFormattedFlexOptions = (flexes: string[]) => {
    return flexes.map(flex => {
      return {
        label: flex.split('_').join(' ').toUpperCase(),
        value: flex.toUpperCase()
      }
    })
  }

  const golfClubTypeFlex = (dataGolfClubTypeFlex && dataGolfClubTypeFlex.res) || []

  const formattedFlexOptions = returnFormattedFlexOptions(golfClubTypeFlex)

  return {
    dataGolfClubTypeFlex,
    golfClubTypeFlex,
    formattedFlexOptions,
    fetchGolfClubFlex
  }
}

export default useGolfClubModelFlexes
