import React, { memo } from 'react'
import { Popup } from '@elements'
import QuestionMark from '@images/question-mark.svg'
import styles from './Tooltip.module.scss'

const Tooltip: React.FC = props => {
  const { children } = props

  return (
    <Popup
      trigger={
        <div className={styles.tooltipTrigger}>
          <QuestionMark />
        </div>
      }
      position={'top left'}
    >
      <div className={styles.tooltip}>{children}</div>
    </Popup>
  )
}

export default memo(Tooltip)
