import React, { memo } from 'react'
import RefreshImage from '@images/refresh.svg'
import styles from './ButtonWithRefresh.module.scss'

interface ButtonWithRefresh {
  label?: string
  onClick(e: React.MouseEvent<HTMLDivElement>): void
}

const ButtonWithRefresh: React.FC<ButtonWithRefresh> = props => {
  const { label, children, onClick } = props

  return (
    <div className={styles.buttonWithRefresh}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={styles.buttonWithRefreshInner} onClick={onClick}>
        <RefreshImage />
        {children}
      </div>
    </div>
  )
}

export default memo<ButtonWithRefresh>(ButtonWithRefresh)
