import { GolfClub, GolfClubModel, GolfEquipment, GolfEquipmentModel, Order, PurchaseRequest, User } from '@types'

export enum NotificationType {
  GOLF_CLUB_SOLD = 'GOLF_CLUB_SOLD',
  GOLF_EQUIPMENT_SOLD = 'GOLF_EQUIPMENT_SOLD',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PURCHASE_REQUEST_APPROVED = 'PURCHASE_REQUEST_APPROVED',
  SELLER_TAXES_PAYMENT_ERROR = 'SELLER_TAXES_PAYMENT_ERROR',
  TAXES_PAYMENT_ERROR = 'TAXES_PAYMENT_ERROR',
  ITEM_CREATED = 'ITEM_CREATED',
  ORDER_CREATED = 'ORDER_CREATED',
  PURCHASE_REQUEST_CREATED = 'PURCHASE_REQUEST_CREATED'
}

export type Notification = {
  golfClub: GolfClub & { order: Order }
  golfEquipment: GolfEquipment & { order: Order }
  golfClubModel: GolfClubModel
  golfEquipmentModel: GolfEquipmentModel
  id: string
  insertedAt: string
  purchaseRequest: PurchaseRequest
  type: NotificationType
  updatedAt: string
  user: User
  viewed: boolean
  order: {
    id: string
  }
}
