import { gql } from '@apollo/client'

export const OrderAddressAttrs = gql`
  fragment OrderAddressAttrs on OrderAddress {
    address
    addressLine
    city
    country
    name
    phoneNumber
    state
    zipCode
    stateCode
  }
`

export const OrderAttrs = gql`
  fragment OrderAttrs on Order {
    hotcakeTrackingNumber
    adminExpectedDelivery
    estimatedDeliveryDate
    address {
      ...OrderAddressAttrs
    }
    buyerSalesTax
    buyerShippingPrice
    buyerTotalPrice
    itemPrice
    id
    insertedAt
    number
    paid
    paidBySeller
    sellerProcessingFee
    sellerProfit
    sellerShippingPrice
    sellerTotalPrice
    status
    stripeSessionId
    updatedAt

    golfClub {
      insertedAt
      expiresAt
      updatedAt
      price
      status
      id
      brand {
        id
        title
      }
      category {
        title
        id
      }

      golfClubType {
        flex
        dexterity
        option
        category {
          title
        }
      }

      golfClubModel {
        id
        avatar
        slug
        title
        releaseYear
      }
    }

    golfEquipment {
      insertedAt
      expiresAt
      updatedAt
      id
      price
      status
      brand {
        title
      }
      category {
        title
        id
      }

      golfEquipmentType {
        option
        condition
        category {
          title
        }
      }

      golfEquipmentModel {
        avatar
        id
        slug
        title
        releaseYear
      }
    }

    user {
      id
      lastName
      firstName
    }
    address {
      address
      addressLine
      city
      country
      name
      phoneNumber
      state
      stateCode
      zipCode
    }
    paymentCard {
      number
    }
  }
  ${OrderAddressAttrs}
`
