import React from 'react'
import { capitalize } from 'lodash'
import { GolfClubType, GolfEquipmentType, Order } from '@types'
import styles from './GolfClubInfo.module.scss'
import { getConditionName } from '@utils'

interface GolfClubInfoProps {
  golfType?: GolfEquipmentType | GolfClubType
}

const GolfClubInfo: React.FC<GolfClubInfoProps> = props => {
  const { golfType } = props

  const category = golfType?.category?.title
  const isCategoryWithoutFlex = category === 'Putters' || category === 'wedges'

  const renderDexterity = () => {
    if (golfType && 'dexterity' in golfType) {
      return (
        <div className={styles.golfClubInfoItem}>
          <div className={styles.golfClubInfoItemTitle + '  uppercase '}>
            <strong>Dexterity:</strong>
          </div>
          <strong>{capitalize(golfType?.dexterity) || <>&mdash;</>}</strong>
        </div>
      )
    }
  }

  const renderFlexInfo = () => {
    if (golfType && !isCategoryWithoutFlex && 'flex' in golfType) {
      return (
        <div className={styles.golfClubInfoItem}>
          <div className={styles.golfClubInfoItemTitle + '  uppercase '}>
            <strong>Flex:</strong>
          </div>
          <strong>{capitalize(golfType?.flex) || <>&mdash;</>}</strong>
        </div>
      )
    }
  }

  return (
    <div className={styles.golfClubInfo + ' golf-club-info-block'}>
      {renderFlexInfo()}
      {renderDexterity()}
      <div className={styles.golfClubInfoItem}>
        <div className={styles.golfClubInfoItemTitle + ' uppercase '}>
          <strong>Option:</strong>
        </div>
        <strong>{golfType?.option}</strong>
      </div>
      <div className={styles.golfClubInfoItem}>
        <div className={styles.golfClubInfoItemTitle + '  uppercase '}>
          <strong>Condition:</strong>
        </div>
        <strong>{getConditionName(golfType?.condition) || <>&mdash;</>}</strong>
      </div>
      <div className={styles.golfClubInfoItem}>
        <div className={styles.golfClubInfoItemTitle + '  uppercase '}>
          <strong>Type:</strong>
        </div>
        <strong>{category || <>&mdash;</>}</strong>
      </div>
    </div>
  )
}

export default GolfClubInfo
