import { gql } from '@apollo/client'
import { GolfClubModelAttrs, GolfClubModelMarketDataHistoryAttrs, ValidationMessageAttrs } from '@fragments'

export const FetchGolfClubModels = gql`
  query FetchGolfClubModels {
    res: golfClubModels {
      ...GolfClubModelAttrs
    }
  }

  ${GolfClubModelAttrs}
`

export const FetchGolfClubModel = gql`
  query FetchGolfClubModel($id: UUID4!) {
    res: golfClubModel(id: $id) {
      ...GolfClubModelMarketDataHistoryAttrs
    }
  }

  ${GolfClubModelMarketDataHistoryAttrs}
`

export const FetchGolfClubModelBySlug = gql`
  query FetchGolfClubModelBySlug($slug: String!) {
    res: golfClubModelSlug(slug: $slug) {
      ...GolfClubModelAttrs
    }
  }

  ${GolfClubModelAttrs}
`

export const FetchGolfClubModelRetailPrice = gql`
  query FetchGolfClubModel($id: UUID4!) {
    res: golfClubModel(id: $id) {
      id
      retailPrice
      category {
        title
        id
      }
      brand {
        title
        id
      }
    }
  }
`

export const FetchPaginatedGolfClubModels = gql`
  query paginatedGolfClubModels(
    $availabilityStatus: Boolean
    $available: GolfClubModelAvailableType
    $brandIds: [UUID4]
    $categoryIds: [UUID4]
    $dexterities: [String]
    $flexes: [String]
    $optionIds: [UUID4]
    $page: Int
    $pageSize: Int
    $playerIds: [UUID4]
    $price: [PriceFilter]
    $releaseYears: [Int]
    $sortBy: GolfClubModelSortBy
    $term: String
  ) {
    res: paginatedGolfClubModels(
      availabilityStatus: $availabilityStatus
      available: $available
      brandIds: $brandIds
      categoryIds: $categoryIds
      dexterities: $dexterities
      flexes: $flexes
      optionIds: $optionIds
      page: $page
      pageSize: $pageSize
      playerIds: $playerIds
      price: $price
      releaseYears: $releaseYears
      sortBy: $sortBy
      term: $term
    ) {
      pageNumber
      pageSize
      totalEntries
      totalPages
      entries {
        title
        avatar
        id
        brand {
          id
          title
        }
        highestBuyPrice
        lowestSellPrice
        slug
        category {
          id
          title
        }
      }
    }
  }
`

export const FetchRelatedGolfClubModels = gql`
  query FetchRelatedGolfClubModels($golfClubModelId: UUID4!) {
    res: relatedProducts(golfClubModelId: $golfClubModelId) {
      mostViewed {
        id
        title
        avatar
        slug
        description
        releaseYear
        retailPrice
        highestBuyPrice
        lowestSellPrice
      }
      related {
        id
        title
        avatar
        slug
        description
        releaseYear
        retailPrice
        highestBuyPrice
        lowestSellPrice
      }
    }
  }
`

export const LimitGolfClubModels = gql`
  query LimitGolfClubModels($sortBy: GolfClubModelSortBy, $categoryIds: [UUID4] = []) {
    res: limitGolfClubModels(sortBy: $sortBy, categoryIds: $categoryIds) {
      ...GolfClubModelAttrs
    }
  }

  ${GolfClubModelAttrs}
`

export const SearchCatalogueItems = gql`
  query SearchCatalogueItems(
    $available: GolfClubModelAvailableType
    $brandIds: [UUID4]
    $categoryIds: [UUID4]
    $dexterities: [String]
    $flexes: [String]
    $page: Int
    $pageSize: Int
    $price: [PriceFilter]
    $releaseYears: [Int]
    $sortBy: CatalogSorting
    $term: String
    $wildcard: Boolean
  ) {
    res: paginatedCatalogItems(
      available: $available
      brandIds: $brandIds
      categoryIds: $categoryIds
      dexterities: $dexterities
      flexes: $flexes
      page: $page
      pageSize: $pageSize
      price: $price
      releaseYears: $releaseYears
      sortBy: $sortBy
      term: $term
      wildcard: $wildcard
    ) {
      entries {
        id
        title
        slug
        brandTitle
        type
        categoryTitle
      }
    }
  }
`

export const UpdateGolfClubModel = gql`
  mutation UpdateGolfClubModel($id: UUID4!, $golfClubModel: GolfClubModelParams) {
    res: updateGolfClubModel(id: $id, golfClubModel: $golfClubModel) {
      successful
      messages {
        ...ValidationMessageAttrs
      }
    }
  }
  ${ValidationMessageAttrs}
`
