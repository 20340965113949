import { JSXElement } from '@babel/types'
import { Collapse } from '@elements'
import React, { ReactElement, useState } from 'react'
import ArrowIcon from '@images/link-arrow.svg'

import './Disclosure.scss'

export interface DisclosureProps {
  label: string
  children: JSXElement | ReactElement
}

const Disclosure: React.FC<DisclosureProps> = props => {
  const { label, children } = props
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="disclosure">
      <div className="disclosure-label" onClick={() => setIsOpen(!isOpen)}>
        {label}
        <ArrowIcon data-open={isOpen} className="disclosure-label-arrow" />
      </div>
      <Collapse isOpened={isOpen}>{children}</Collapse>
    </div>
  )
}

export default Disclosure
