import { Reference, useMutation, useQuery } from '@apollo/client'
import { useAlert } from 'react-alert'
import { Locker, CacheUser, MutationResponse, ResponseType, UpdateLocker, CreateLocker } from '@types'
import { useRequestErrorHandler } from '@hooks'
import { user as graphqlUser, Locker as graphqlLocker } from '@graphql'
import { LockerAttrs } from '@fragments'

type LockerResponse = ResponseType<MutationResponse & { result: Locker }>

const useGraphqlLocker = () => {
  const alert = useAlert()
  const handleRequestError = useRequestErrorHandler()

  const { data: currentUserData } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-first'
  })
  const currentUser = currentUserData?.res

  const [createLockerMutation] = useMutation<LockerResponse, { locker: CreateLocker }>(graphqlLocker.CreateLocker, {
    onError: error => handleRequestError(null, error)
  })

  const [updateLockerMutation] = useMutation<LockerResponse, { locker: Omit<UpdateLocker, 'id'> }>(
    graphqlLocker.UpdateLocker,
    { onError: error => handleRequestError(null, error) }
  )

  const [deleteLockerMutation] = useMutation<LockerResponse, { id: string }>(graphqlLocker.DeleteLocker, {
    onError: error => handleRequestError(null, error)
  })

  const createLocker = async (locker: CreateLocker) => {
    const request = await createLockerMutation({
      variables: { locker },
      refetchQueries: [{ query: graphqlUser.FetchCurrentUser }],
      update: (cache, mutationResult) => {
        const result = mutationResult?.data?.res?.result
        const successful = mutationResult?.data?.res?.successful

        if (!successful) return null
        if (currentUser?.id && result) {
          const newLockerRef = cache.writeFragment({
            data: { ...result },
            fragment: LockerAttrs
          })
          cache.modify({
            id: cache.identify(currentUser),
            fields: {
              locker() {
                return newLockerRef
              }
            }
          })
        }

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError<Locker>(request)
  }

  const updateLocker = async (locker: Locker & { id: string }) => {
    const { ...lockerUpdate } = locker

    const request = await updateLockerMutation({
      variables: { locker: lockerUpdate },
      refetchQueries: [{ query: graphqlUser.FetchCurrentUser }],
      update: (cache, mutationResult) => {
        const result = mutationResult?.data?.res?.result
        const successful = mutationResult?.data?.res?.successful

        if (!successful) return null
        if (currentUser?.id && result) {
          const newLockerRef = cache.writeFragment({
            data: { ...result },
            fragment: LockerAttrs
          })
          cache.modify({
            id: cache.identify(currentUser),
            fields: {
              locker() {
                return newLockerRef
              }
            }
          })
        }

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const deleteLocker = async (lockerId: string) => {
    const request = await deleteLockerMutation({
      variables: { id: lockerId },
      update: (cache, mutationResult) => {
        // const result = mutationResult?.data?.res?.result
        const successful = mutationResult?.data?.res?.successful

        if (!successful) return null

        if (currentUser?.id) {
          cache.modify({
            id: cache.identify(currentUser),
            fields: {
              lockers(lockerRefs: Reference[], { readField }) {
                return lockerRefs.filter(lockerRef => lockerId !== readField('id', lockerRef))
              }
            }
          })
        }

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  return {
    createLocker,
    updateLocker,
    deleteLocker
  }
}

export default useGraphqlLocker
