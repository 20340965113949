import React, { memo } from 'react'
import PopupComponent from 'reactjs-popup'

import './Popup.scss'

export type PopupPositionProp =
  | 'top left'
  | 'top center'
  | 'top right'
  | 'right top'
  | 'right center'
  | 'right bottom'
  | 'bottom left'
  | 'bottom center'
  | 'bottom right'
  | 'left top'
  | 'left center'
  | 'left bottom'
  | 'center center'

export interface PopupProps {
  position: PopupPositionProp
  trigger: JSX.Element
  modal?: boolean
  offsetY?: number
  offsetX?: number
}

const Popup: React.FC<PopupProps> = props => {
  const { position, trigger, children, modal, offsetY, offsetX } = props
  return (
    <PopupComponent
      offsetX={offsetX}
      offsetY={offsetY}
      trigger={trigger}
      on={['hover']}
      position={position}
      modal={modal}
    >
      {children}
    </PopupComponent>
  )
}

Popup.defaultProps = {
  position: 'top center'
}

export default memo(Popup)
