import { useQuery } from '@apollo/client'
import { BrowsePage, ResponseType } from '@types'
import { browsePage as browsePageGraphQl } from '@graphql'
import { useLocation } from '@reach/router'

const useBrowsePage = (slug?: string) => {
  const { pathname } = useLocation()

  const slugVar = slug || localStorage.getItem('browsePageSlug')
  const isBrowsePage = pathname.includes('browse')

  const { data: browsePageData, loading: isLoadingBrowsePage } = useQuery<ResponseType<BrowsePage>>(
    browsePageGraphQl.FetchBrowsePageSlug,
    {
      variables: { slug: slugVar },
      fetchPolicy: 'no-cache',
      skip: !isBrowsePage
    }
  )

  return {
    loading: isLoadingBrowsePage,
    browsePageData
  }
}

export default useBrowsePage
