import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { notifications as graphqlNotifications, user as graphqlUser } from '@graphql'

import { CacheUser, Notification, ResponseType } from '@types'

const useNotifications = () => {
  const { data: currentUserData } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const { data: fetchedNotifications } = useQuery<ResponseType<Notification[]>>(
    graphqlNotifications.FetchNotifications,
    {
      skip: !currentUserData?.res
    }
  )

  const [viewNotifications] = useMutation<{
    res: { successfull: boolean }
  }>(graphqlNotifications.ViewNotifications, { refetchQueries: [{ query: graphqlNotifications.FetchNotifications }] })

  const { data: subscriptionMessagesNotificationsData } = useSubscription(graphqlNotifications.NewNotifications, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      const subscribtionNotification: Notification = subscriptionData.data.newNotifications

      client.cache.writeQuery({
        query: graphqlNotifications.FetchNotifications,
        data: { res: [...allNotifications, subscribtionNotification] }
      })
    }
  })

  const allNotifications = fetchedNotifications?.res || []
  const oldNotifications = allNotifications.filter(notification => notification.viewed)
  const newNotifications = allNotifications.filter(notification => !notification.viewed)

  return {
    oldNotifications,
    newNotifications,
    viewNotifications
  }
}

export default useNotifications
