import { useLocation } from '@reach/router'
import { CacheUser, Category } from '@types'
import { navigate } from 'gatsby'
import { useEffect, useState } from 'react'

const useUserSettings = (categories?: Category[], currentUser?: CacheUser) => {
  const { search, pathname } = useLocation()
  const urlSearchParams = new URLSearchParams(search)

  const urlCategory = urlSearchParams.get('categoryIds')
  const settingsParam = urlSearchParams.get('mySettings')
  const dexterityParam = urlSearchParams.get('dexterities')
  const flexParam = urlSearchParams.get('flexes')
  const [isSettingsActive, setIsSettingsActive] = useState(settingsParam === 'true' ? true : false)

  const userFlexParam = currentUser?.basicInfo.flex
  const userDexterityParam = currentUser?.basicInfo.dexterity
  const [clubsPrarams, setClubsParams] = useState({ flex: userFlexParam, dexterity: userDexterityParam })

  const selectedCategory = categories?.find(category => category.id === urlCategory)
  const selectedCategoryName = selectedCategory?.title
  const selectedCategoryKey = selectedCategoryName?.replace(/\s/g, '')
  const isSelectedCategoryWithoutFlex =
    selectedCategoryName === 'Putters' || selectedCategoryName === 'Wedges' || selectedCategoryName === 'Limited'

  // useEffect(() => {
  //   if (currentUser && settingsParam != 'false') {
  //     userFlexParam ? urlSearchParams.set('flexes', `${userFlexParam}`) : urlSearchParams.set('flexes', 'REGULAR')
  //     userDexterityParam
  //       ? urlSearchParams.set('dexterities', `${userDexterityParam}`)
  //       : urlSearchParams.set('dexterities', 'RIGHT')
  //     urlSearchParams.set('mySettings', `true`)
  //     navigate(decodeURIComponent('?' + urlSearchParams.toString()), { replace: true })
  //   }
  // }, [currentUser])

  // useEffect(() => {
  //   if (flexParam && dexterityParam) {
  //     setClubsParams({ flex: flexParam, dexterity: dexterityParam })
  //   }
  // }, [flexParam, dexterityParam])

  // useEffect(() => {
  //   if (selectedCategory) {
  //     if (isSettingsActive) {
  //       userFlexParam ? urlSearchParams.set('flexes', `${userFlexParam}`) : urlSearchParams.set('flexes', 'REGULAR')
  //       userDexterityParam
  //         ? urlSearchParams.set('dexterities', `${userDexterityParam}`)
  //         : urlSearchParams.set('dexterities', 'RIGHT')

  //       navigate(decodeURIComponent('?' + urlSearchParams.toString()), { replace: true })
  //       return
  //     }

  //     if (!isSettingsActive) {
  //       // const stateFlex = clubsPrarams.flex
  //       // const stateDexterity = clubsPrarams.dexterity
  //       // stateFlex ? urlSearchParams.set('flexes', `${stateFlex}`) : urlSearchParams.set('flexes',"REGULAR")
  //       // stateDexterity ? urlSearchParams.set('dexterities', `${clubsPrarams.dexterity}`)   : urlSearchParams.set('dexterities', "RIGHT")
  //       // navigate(decodeURIComponent('?' + urlSearchParams.toString()), { replace: true })
  //       return
  //     }
  //   }
  // }, [selectedCategory])

  const handleMySettings = () => {
    setIsSettingsActive(!isSettingsActive)
    urlSearchParams.set('mySettings', `${isSettingsActive}`)

    if (!currentUser) {
      urlSearchParams.delete('mySettings')
      urlSearchParams.delete('flexes')
      urlSearchParams.delete('dexterities')
      navigate(decodeURIComponent('?' + urlSearchParams.toString()), { replace: true })
      return
    }

    if (!isSettingsActive) {
      urlSearchParams.delete('flexes')
      urlSearchParams.delete('dexterities')
      urlSearchParams.set('mySettings', `false`)
      navigate(decodeURIComponent('?' + urlSearchParams.toString()), { replace: true })
      return
    }

    if (isSettingsActive) {
      urlSearchParams.set('dexterities', `${userDexterityParam}`)
      urlSearchParams.set('flexes', `${userFlexParam}`)
      urlSearchParams.set('mySettings', `true`)
      navigate(decodeURIComponent('?' + urlSearchParams.toString()), { replace: true })
      return
    }
  }

  const mySettingsParam = [settingsParam || 'false']

  return { selectedCategoryKey, selectedCategoryName, handleMySettings, mySettingsParam }
}

export default useUserSettings
