import { gql } from '@apollo/client'
import { CategoryAttrs } from '@fragments'

export const FetchCategories = gql`
  query FetchCategories {
    res: categories {
      ...CategoryAttrs
    }
  }

  ${CategoryAttrs}
`

export const FetchHeaderCategories = gql`
  query BrowseCategories($deviceType: DeviceType) {
    res: browseCategories(deviceType: $deviceType) {
      title
      id
      browseSubCategories {
        title
        id

        browsePages {
          title
          menuTitle
          slug
        }
      }
    }
  }
`

export const FetchCategory = gql`
  query FetchCategory($id: UUID4!) {
    res: category(id: $id) {
      ...CategoryAttrs
    }
  }

  ${CategoryAttrs}
`
