import {
  CatalogItemType,
  CatalogSorting,
  CatalogType,
  GolfClubCondition,
  GolfClubModelAvailableType,
  GolfCourse,
  GolfEquipment,
  GolfEquipmentModel,
  GolfEquipmentType,
  GolfItemType
} from '@types'

export type OptionClass = {
  id: string
  title: string
  options?: Option[]
  insertedAt: string
  updatedAt: string
}

export type Option = {
  avatar: string
  id: string
  title: string
  preSort: boolean
  insertedAt: string
  updatedAt: string
}

export type Category = {
  id: string
  insertedAt: string
  title: string
  updatedAt: string
  preSort: boolean
}

export type Brand = {
  avatar: string
  id: string
  insertedAt: string
  title: string
  updatedAt: string
  preSort: boolean
}

export type Price = {
  id: string
  label: string
  filter: { maxPrice?: number; minPrice?: number }
}

export type GolfClub = {
  brand: Brand
  category: Category
  expiresAt: string
  golfClubModel: GolfClubModel
  golfClubType: GolfClubType
  id: string
  insertedAt: string
  owner: User
  price: number
  soldAt: string
  status: GolfClubStatus
  updatedAt: string
  avatars: string[]
  avatar: string
}

export type GolfClubType = {
  bounce: number
  brand: Brand
  category: Category
  clubHeadVolume: number
  condition: GolfClubCondition
  dexterity: GolfClubDexterity
  flex: GolfClubFlex
  golfClubModel: GolfClubModel
  golfClubs: GolfClub[]
  id: string
  insertedAt: string
  length: number
  lieAngle: number
  loft: number
  option: string
  purchaseRequests: PurchaseRequest[]
  golfClubsOnSale: GolfClub[]
  openPurchaseRequests: PurchaseRequest[]
  swingWeight: number
  totalWeight: number
  updatedAt: string
  __typename: 'GolfClubType'
}

export type SearchGolfClubTypeArgs = {
  golfClubModelId: string
  dexterity: GolfClubDexterity
  flex: GolfClubFlex
  condition: GolfClubCondition
  option: string
}

export type GolfClubModel = {
  __typename: 'GolfClubModel' | 'GolfEquipmentModel'
  additionalInformation: string
  avatar: string
  avatars: string
  brand: Brand
  category: Category
  conditions: GolfClubCondition[]
  description: string
  golfClubTypes: GolfClubType[]
  highestBuyPrice: number
  id: string
  insertedAt: string
  lowestSellPrice: number
  releaseYear: number
  retailPrice: number
  slug: string
  standardLength: number
  title?: string
  type: CatalogItemType
  tourdayValue: number
  tourdayRetailPricePercent: number
  updatedAt: string
  variants: string
  availabilityStatus: boolean
}

export type GolfClubDexterity = keyof typeof GolfClubDexterityEnum
export type GolfClubFlex = keyof typeof GolfClubFlexEnum
export type Gender = keyof typeof GenderEnum
export type GolfClubStatus = keyof typeof GolfClubStatusEnum
export type PurchaseRequestStatus = keyof typeof PurchaseRequestStatusEnum
export type LockerChampionshipRole = keyof typeof LockerChampionshipRoleEnum

export enum GolfClubModelSortByEnum {
  ALL,
  LOWEST_ASK,
  LOWEST_PRICE,
  MOST_POPULAR_BRANDS,
  POPULAR_BRANDS,
  RECOMMENDED
}

export enum GolfClubConditionEnum {
  EXCELLENT = 'EXCELLENT',
  GOOD = 'GOOD',
  NEW = 'NEW'
}

export enum GolfClubConditionFilterEnum {
  EXCELLENT,
  GOOD,
  NEW,
  ALL
}

export enum GolfClubDexterityEnum {
  LEFT,
  RIGHT
}

export enum GolfClubFlexEnum {
  EXTRA_STIFF,
  LADIES,
  REGULAR,
  SENIOR,
  STIFF
}

export enum GenderEnum {
  MALE,
  FEMALE
}

export enum GolfClubStatusEnum {
  EXPIRED,
  IN_PROCESS,
  ON_SALE,
  SOLD,
  TAXES_PAYMENT_ERROR,
  UNSOLD,
  WAIT_FOR_PAYMENT
}

export type Filters = {
  brands: Brand[]
  categories: Category[]
  flexes: GolfClubFlex[]
  releaseYears: number[]
  gripSizes: string[]
  heights: string[]
  dexterities: GolfClubDexterity[]
  equipmentCategories: Category[]

  genders: Gender[]
}

export type UserBasicInfo = {
  gripSize?: string
  height?: string
  dexterity?: GolfClubDexterity
  flex?: GolfClubFlex | 'Not Sure'
  experienceLevel?: string
}

export type AddressCard = {
  stateCode: string
  id: string
  name: string
  country: string
  state: string
  address: string
  city: string
  addressLine: string
  phoneNumber: string
  zipCode?: string
  default: boolean
}

export type UpdateAddressCard = {
  id?: string
  name?: string
  country?: string
  state?: string
  stateCode?: string
  address?: string
  addressLine?: string
  phoneNumber?: string
  default?: boolean
  zipCode?: string
}

export type UserBrowsingView = {
  id: string
  createdAt: string
  updatedAt: string
  golfClubModel: Omit<GolfClubModel, 'category' | 'golfClubs'>
}

export type PaymentCard = {
  number: number
  id: string
  expYear: number
  expMonth: number
  default: boolean
  brand: string
}
export type CreatePaymentCardParams = {
  cardNumber: string
  cvc: string
  expYear: number
  expMonth: number
  default?: boolean
}

export type UpdatePaymentCardParams = {
  cardNumber?: string
  cvc?: string
  expYear?: number
  expMonth?: number
  default?: boolean
  id: string
}

export type User = {
  emailApproved: boolean
  isAdmin: boolean
  gender: Gender
  id: string
  email: string
  firstName: string
  lastName: string
  userName: string
  avatar?: string
  basicInfo: UserBasicInfo
  addressCards: AddressCard[]
  bag: UserGolfItem[]
  sellerStatus: null | 'PROCESSING' | 'SELLER'
  paymentCards: PaymentCard[]
  stripeAccountSubmitted: boolean
  defaultAddressCard?: AddressCard
  defaultPaymentCard?: PaymentCard
}

export type ResponseType<T> = {
  res: T
}

export type PaginationType<TData = unknown> = {
  entries?: TData[]
  pageNumber: number
  pageSize: number
  totalEntries: number
  totalPages: number
}

export type PaginationArgs = {
  page?: number
  pageSize?: number
  term?: string
}

export type PriceFilterArgs = {
  maxPrice: number
  minPrice: number
}

export type FiltersArgs = {
  availabilityStatus?: boolean
  available?: GolfClubModelAvailableType | string
  brandIds?: string[]
  catalogType?: CatalogType
  categoryIds?: string[]
  dexterities?: string[]
  equipmentCategoryIds?: string[]
  flexes?: string[]
  optionIds?: string[][] | string[]
  page?: number
  pageSize?: number
  playerIds?: [string]
  price?: PriceFilter[] | undefined
  releaseYears?: number[]
  sortBy?: CatalogSorting | string
  term?: string
  wildcard?: boolean
  includeIds?: string[]
  seed?: number
}
export type PriceFilter =
  | {
      maxPrice: number
      minPrice: number
    }
  | undefined

export type CreateLocker = {
  hole?: number
  handicap?: number
  gloveSize?: string
  championshipYear?: number
  championshipRole?: LockerChampionshipRole
  myClubId?: string
  holeInOneId?: string
  turnId?: string
  drinkId?: string
}
export type Locker = {
  hole?: number
  id?: string
  handicap?: number
  gloveSize?: string
  championshipYear?: number
  championshipRole?: LockerChampionshipRole
  myClub?: GolfCourse
  holeInOne?: GolfCourse
  turn?: Turn
  drink?: Drink
  insertedAt?: string
  updatedAt?: string
  user?: User
}

export type UpdateLocker = {
  hole?: number
  id?: string
  handicap?: number
  gloveSize?: string
  championshipYear?: number
  championshipRole?: LockerChampionshipRole
  myClub?: GolfCourse
  holeInOne?: GolfCourse
  turn?: Turn
  drink?: Drink
}

export type Turn = {
  id: string
  title: string
  updatedAt: string
  insertedAt: string
}

export type Drink = {
  id: string
  title: string
  updatedAt: string
  insertedAt: string
}

export enum LockerChampionshipRoleEnum {
  CHAMPION,
  MEMBER,
  GUEST,
  CADDY,
  INTER_GENERATIONS,
  MIX_COUPLES
}

export type PurchaseRequest = {
  buyerSalesTax: number
  buyerShippingPrice: number
  buyerTotalPrice: number
  expiresAt: string
  itemPrice: number
  golfClubType: GolfClubType
  golfEquipmentType: GolfEquipmentType
  id: string
  insertedAt: string
  seller: User
  sellerProcessingFee: number
  sellerProfit: number
  sellerShippingPrice: number
  sellerTotalPrice: number
  status: PurchaseRequestStatus
  updatedAt: string
  user: User
  userAddress: PurchaseRequestUserAddress
  paymentCard: PaymentCard
}

export enum PurchaseRequestStatusEnum {
  CLOSED,
  EXPIRED,
  OPEN
}

export type PurchaseRequestUserAddress = {
  address: string
  addressLine: string
  city: string
  country: string
  name: string
  phoneNumber: string
  state: string
  stateCode: string
  zipCode: string
}

export type UserGolfItemArgs = {
  dexterity?: GolfClubDexterity
  flex?: GolfClubFlex
  golfClubModelId?: string
  golfEquipmentModelId?: string
  option?: string
  purchaseDate: string
  purchasePrice?: number
}

export type PaginatedUserGolfItems = {
  entries: UserGolfItem[]
  pageNumber: number
  pageSize: number
  totalEntries: number
  totalPages: number
}

export type UserGolfItem = {
  dexterity: GolfClubDexterity
  flex: GolfClubFlex
  golfClubModel: GolfClubModel
  golfEquipmentModel: GolfEquipmentModel
  id: string
  insertedAt: string
  option: string
  purchaseDate: string
  purchasePrice: number
  updatedAt: string
  user: User
}

export type CreatePurchaseRequestArgs = {
  addressCardId: string
  expiresAt: string | null
  golfClubTypeId: string
  golfEquipmentTypeId?: string
  paymentCardId: string
  price: number
}

export type CreatePurchaseRequest = {
  purchaseRequest: {
    addressCardId: string
    expiresAt: string | null
    golfClubTypeId?: string
    golfEquipmentTypeId?: string
    paymentCardId: string
    price: number
    promoCodeId?: string
  }
}

export type UpdatePurchaseRequestArgs = {
  id: string
  purchaseRequest: Partial<CreatePurchaseRequestArgs>
}

export type CreateGolfClubArgs = {
  expiresAt: string | null
  price: number | undefined
  golfClubTypeId: string
  addressCardId: string | undefined
  paymentCardId: string | undefined
  avatarsData: string[]
}

export type CreateGolfEquipmentArgs = {
  expiresAt: string | null
  price: number | undefined
  golfEquipmentTypeId: string
  addressCardId: string | undefined
  paymentCardId: string | undefined
  avatars: string[]
}

export type GolfClubParams = {
  addressCardId: string
  expiresAt: string | null
  golfClubTypeId: string
  paymentCardId: string
  price: number
}

export type UpdateGolfClubArgs = {
  id: string
  golfClub: Partial<GolfClubParams>
}

export type UpdateGolfEquipmentArgs = {
  id: string
  golfEquipment: Partial<CreateGolfEquipmentArgs>
}

export type ExtendGolfEquipmentArgs = {
  expiresAt: string
  id: string
}

export type QueryPaginationArgs = {
  pagination?: PaginationArgs
  filters?: FiltersArgs
  sortBy: string | CatalogSorting
  term?: string
}

export type MutationResponse = {
  successful: boolean
  messages: {
    code: string
    field?: string
    message?: string
    options?: { key: string; value: string }[]
    template?: string
  }[]
}

export type Store = {
  active: number
  unsold: number
  sales: number
  total: number
}

export type BrowsingViews = {
  golfClubModel: GolfClubModel
  golfEquipmentModel: GolfEquipmentModel
  id: string
  insertedAt: string
  updatedAt: string
  user: User
}

export type CreateBrowsingView = {
  golfClubModelId?: string
  golfEquipmentModelId?: string
}

export type GolfClubDraft = {
  brand: Brand
  category: Category
  condition: GolfClubCondition
  dexterity: GolfClubDexterity
  expiresAt: Date
  flex: GolfClubFlex
  golfClubModel: GolfClubModel
  id: string
  insertedAt: string
  option: string
  price: number
  updatedAt: string
  user: User
}

export type CreateGolfClubDraftArgs = {
  brandId: string
  categoryId: string
  condition: GolfClubCondition
  dexterity: GolfClubDexterity
  expiresAt: string
  flex: GolfClubFlex
  golfClubModelId: string
  option: string
  price: number
}

export type UpdateGolfClubDraftArgs = { golfClubDraft: CreateGolfClubDraftArgs } & { id: string }

export type ParseCSVElement = {
  model_name: string
  condition: string
  option: string
  flex: string
  dexterity: string
  price: string
  __typename: string
}

export type ValidateCSVResultElement = {
  golfClubTypeId: string
  price: number
}

export type ValidateCSVResponse = {
  rejected: ParseCSVElement[]
  approved: ParseCSVElement[]
  result: ValidateCSVResultElement[]
}

export type ValidateCSVArgs = Omit<ParseCSVElement, '__typename'>[]

export type PriceTaxes = {
  buyerSalesTax: number
  buyerShippingPrice: number
  buyerTotalPrice: number
  itemPrice: number
  sellerProcessingFee: number
  sellerProfit: number
  sellerShippingPrice: number
  sellerTotalPrice: number
}

export type PriceTaxesArgs = {
  price: number
  addressCardId?: string
  promoCodeId?: string
}

export type SubscribedEmailResponse = {
  email: string
}

export type StripeAccountUrlResponseType = {
  created: number
  expires_at: number
  object: string
  url: string
}

export type UserPayoutCard = {
  brand: string
  default: boolean
  expMonth: number
  expYear: number
  id: string
  insertedAt: string
  number: number
  updatedAt: string
  user: User
}
export type NewProductFeedbackType = {
  productName: string
  releaseYear: number
  retailPrice: number
  productLink: string
  email: string
}

export type ExistingProductFeedbackType = {
  productLink: string
  descriptionType: string
  description: string
  email: string
}

export type PromoCode = {
  id: string
  code: string
  active: boolean
  relevantTo: Date
  discount: number
}

export type StarterSetType = {
  golfExperienceLevel: string
  brand: string
  price: number
}

export type GolfItem = (GolfClub | GolfEquipment) & {
  brandTitle: string
  categoryTitle: string
  modelTitle: string
  modelAvatar: string
  slug: string
  type: GolfItemType
}

export type PaginationCatalogItemsArgs = Partial<{
  availabilityStatus: boolean
  available: GolfClubModelAvailableType | string
  brandIds: string[]
  catalogType: CatalogType
  categoryIds: string[]
  dexterities: string[]
  equipmentCategoryIds: string[]
  flexes: string[]
  includeIds: string[]
  optionIds: string[][]
  page: number
  pageSize: number
  playerIds: string[]
  price: PriceFilter[]
  releaseYears: number[]
  seed: number
  sortBy: CatalogSorting | string
  term: string
  wildcard: boolean
}>

export type PaginatedGolfCourses = {
  entries: GolfCourse[]
  pageNumber: number
  pageSize: number
  totalEntries: number
  totalPages: number
}

export type PaginatedGolfItems = {
  entries: GolfItem[]
  pageNumber: number
  pageSize: number
  totalEntries: number
  totalPages: number
}

export type GolfHomeCoursesType = {
  address: string
  city: string
  country: string
  designedBy: string
  id: string
  insertedAt: string
  state: string
  title: string
  updatedAt: string
  yearFounded: number
}
