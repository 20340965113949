import { gql } from '@apollo/client'

export const PromoCodeAttrs = gql`
  fragment PromoCodeAttrs on PromoCode {
    active
    code
    discount
    expiresAt
    id
    insertedAt
    updatedAt
  }
`
