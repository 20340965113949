import React from 'react'
import { navigate } from 'gatsby'
import { Button, DeleteButton } from '@elements'
import PopupComponent from 'reactjs-popup'
import { GolfClubDraft } from '@types'
import { useGraphqlGolfClubDraft, useWindowSize } from '@hooks'
import { CALENDAR_MONTHS } from '@utils'
import Dots from '@images/three-vertical-dots.svg'
import './Dashboard.scss'

interface DashboardProps {
  golfClubDrafts: GolfClubDraft[]
}

const getLastUpdatedString = (updatedAt: string) => {
  const updatedDate = new Date(updatedAt)
  const updatedMonth = Object.values(CALENDAR_MONTHS)[updatedDate.getMonth()]
  const updatedDay = updatedDate.getDate()

  return `Last updated ${updatedDay} ${updatedMonth}.`
}

const Dashboard: React.FC<DashboardProps> = props => {
  const { golfClubDrafts } = props

  const { toRender: isMobile } = useWindowSize(['mobile', 'landscape'])
  const { deleteGolfClubDraft } = useGraphqlGolfClubDraft()

  return (
    <>
      {golfClubDrafts.map(golfClubDraft => {
        const golfClubModel = golfClubDraft?.golfClubModel
        const golfClubModelAvatar = golfClubModel?.avatar
        const lastUpdated = getLastUpdatedString(golfClubDraft.updatedAt)

        const handleResumeDraft = () => navigate('/account/place-a-club', { state: { golfClubDraft } })

        return (
          <div className={'dash'} key={golfClubDraft.id}>
            <div className={'dash-info'}>
              {golfClubModelAvatar ? (
                <img className={'dash-info-image'} src={golfClubModelAvatar} />
              ) : (
                <div className={'dash-info-blank-image'} />
              )}
              <div className={'dash-info-text'}>
                <div className={'dash-info-text-title'}>{golfClubModel ? golfClubModel.title : '(Untitled draft)'}</div>
                <div className={'dash-info-text-subtitle'}>{lastUpdated}</div>
              </div>
            </div>
            {isMobile ? (
              <PopupComponent
                trigger={
                  <div className={'profile-payment-header-cards-item-view-actions'}>
                    <Dots />
                  </div>
                }
                modal
                overlayStyle={{ background: 'rgba(82, 82, 82, 0.61)' }}
                contentStyle={{ background: 'transparent', width: '100%', marginBottom: '0' }}
              >
                {(close: () => void) => {
                  return (
                    <div style={{ width: '300px', margin: '0 auto', fontSize: '18px' }}>
                      <div
                        style={{
                          background: '#ffffff',
                          borderRadius: '14px',
                          boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.14)'
                        }}
                      >
                        <div
                          style={{
                            padding: '19px 20px',
                            textAlign: 'center',
                            color: '#0C0C0C',
                            cursor: 'pointer'
                          }}
                          onClick={handleResumeDraft}
                        >
                          Resume Draft
                        </div>
                        <div
                          style={{
                            padding: '19px 50px',
                            textAlign: 'center',
                            color: '#F9655B',
                            borderTop: '1px solid #EFEFF9',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            deleteGolfClubDraft(golfClubDraft.id)
                            close()
                          }}
                        >
                          Delete
                        </div>
                      </div>
                      <div
                        style={{
                          background: '#ffffff',
                          padding: '19px 50px',
                          marginTop: '10px',
                          borderRadius: '14px',
                          boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.14)',
                          textAlign: 'center',
                          cursor: 'pointer'
                        }}
                        onClick={close}
                      >
                        Cancel
                      </div>
                    </div>
                  )
                }}
              </PopupComponent>
            ) : (
              <div className={'dash-buttons'}>
                <div>
                  <Button className={'dash-buttons__button_edit'} onClick={handleResumeDraft} centered>
                    Resume Draft
                  </Button>
                </div>
                <div className={'dash-buttons-delete'} onClick={() => deleteGolfClubDraft(golfClubDraft.id)}>
                  <DeleteButton />
                </div>
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}
export default Dashboard
