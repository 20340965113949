import { useMutation, useQuery } from '@apollo/client'
import { useAlert } from 'react-alert'
import {
  BrowsingViews,
  CreateBrowsingView,
  GolfClubModel,
  GolfEquipmentModel,
  MutationResponse,
  ResponseType
} from '@types'
import { useRequestErrorHandler } from '@hooks'
import { browsingViews as graphqlBrowsingViews } from '@graphql'

type UserBrowsingViewResponse = ResponseType<MutationResponse & { result: BrowsingViews }>

const useBrowsingView = () => {
  const handleRequestError = useRequestErrorHandler()
  const alert = useAlert()

  const { data: browsingViewData, loading } = useQuery<ResponseType<BrowsingViews & { entries: BrowsingViews[] }>>(
    graphqlBrowsingViews.FetchPaginatedBrowsingViews
  )

  const [createBrowsingViewMutation] = useMutation<UserBrowsingViewResponse, { browsingView: CreateBrowsingView }>(
    graphqlBrowsingViews.CreateBrowsingView,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const [deleteBrowsingViewMutation] = useMutation<UserBrowsingViewResponse, { id: string }>(
    graphqlBrowsingViews.DeleteBrowsingView,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const [clearUserBrowsingHistory] = useMutation<UserBrowsingViewResponse>(
    graphqlBrowsingViews.ClearUserBrowsingHistory,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const createBrowsingView = async (catalogItem: GolfEquipmentModel | GolfClubModel) => {
    let payload
    if (catalogItem.__typename === 'GolfClubModel') payload = { golfClubModelId: catalogItem.id }
    else payload = { golfEquipmentModelId: catalogItem.id }
    const request = await createBrowsingViewMutation({
      variables: { browsingView: payload },
      refetchQueries: [{ query: graphqlBrowsingViews.FetchPaginatedBrowsingViews }]
    })

    return handleRequestError(request)
  }

  const clearBrowsingView = async () => {
    const request = await clearUserBrowsingHistory({
      refetchQueries: [{ query: graphqlBrowsingViews.FetchPaginatedBrowsingViews }]
    })
    const success = request.data?.res.successful

    if (success) {
      alert.show(`Browse history successfully deleted!`, {
        type: 'success'
      })
    } else {
      alert.show(`Error, something went wrong!`, {
        type: 'error'
      })
    }

    return handleRequestError(request)
  }

  const deleteBrowsingView = async (id: string) => {
    const request = await deleteBrowsingViewMutation({
      variables: { id },
      refetchQueries: [{ query: graphqlBrowsingViews.FetchPaginatedBrowsingViews }]
    })
    const success = request.data?.res.successful

    if (success) {
      alert.show(`Browse history item successfully deleted!`, {
        type: 'success'
      })
    } else {
      alert.show(`Error, something went wrong!`, {
        type: 'error'
      })
    }

    return handleRequestError(request)
  }

  return { deleteBrowsingView, clearBrowsingView, createBrowsingView, browsingViewData, loading }
}

export default useBrowsingView
