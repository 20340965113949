import React, { memo } from 'react'
import styles from './Group.module.scss'

interface CheckboxGroupProps {
  groupName: string
}

const Group: React.FC<CheckboxGroupProps> = props => {
  const { groupName, children } = props

  return (
    <div className={styles.group}>
      <div className={'subtitle4'}>{groupName}</div>
      {children}
    </div>
  )
}

export default memo(Group)
