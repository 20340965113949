import { Button } from '@elements'
import { useGolfClubModelSearch } from '@hooks'
import SearchImage from '@images/search.svg'
import React, { memo } from 'react'
import Input from '../../elements/__Input/Input'
import './GolfClubsSearchInput.scss'

interface GolfClubsSearchInputProps {
  name: string
  value: string
  onChange: (e) => void
  onBlur: (e) => void
  buttonClick: (e) => void
  handleKeyDown: (e) => void
}

const GolfClubsSearchInput: React.FC<GolfClubsSearchInputProps> = props => {

  return (
    <div className={'search'}>
      <Input
        role={'search'}
        onChange={props.onChange}
        onKeyDown={props.handleKeyDown}
        onBlur={props.onBlur}
        name={'search-input'}
        autoComplete={'on'}
        rounded={false}
        value={props.value}
        prefix={<SearchImage />}
      />
      <Button type={'black'} onClick={props.buttonClick}>
        Search
      </Button>
    </div>
  )
}

export default memo(GolfClubsSearchInput)
