import React, { memo } from 'react'
import { Select, SelectProps } from '@components'
import { lengths } from '@utils'
import { capitalize } from 'lodash'
import styles from './LengthSelect.module.scss'

export interface LengthSelectProps extends Omit<SelectProps, 'options'> {
  defaultValue?: string | number | Record<string, any>
}

const LengthSelect: React.FC<LengthSelectProps> = props => {
  const lenghtOptions = lengths
    .map(length => {
      return {
        label: capitalize(length),
        value: length
      }
    })
    .reverse()

  return (
    <div className={styles.lengthSelect}>
      <Select options={lenghtOptions} {...props} />
    </div>
  )
}

export default memo(LengthSelect)
