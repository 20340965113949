import { gql } from '@apollo/client'
import { golfCourseAttrs } from '@fragments'

export const FetchGolfCourses = gql`
  query FetchGolfCourses {
    res: golfCourses {
      id
      title
      address
      city
      state
      country
      yearFounded
      designedBy
      insertedAt
      updatedAt
    }
  }
`
export const PaginatedGolfCourses = gql`
  query PaginatedGolfCourses($page: Int, $pageSize: Int, $term: String) {
    res: paginatedGolfCourses(page: $page, pageSize: $pageSize, term: $term) {
      entries {
        address
        city
        country
        designedBy
        id
        insertedAt
        state
        title
        updatedAt
        yearFounded
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
`
