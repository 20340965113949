import { gql } from '@apollo/client'
import { GolfClubModelAttrs, GolfClubModelBrowsingHistoryAttrs } from './golfClubModel'
import { GolfEquipmentModelBrowsingHistoryAttrs } from './equipment'

export const UserBasicInfoAttrs = gql`
  fragment UserBasicInfoAttrs on BasicInfo {
    dexterity
    experienceLevel
    flex
    gripSize
    height
  }
`

export const UserGolfClubAttrs = gql`
  fragment UserGolfClubAttrs on UserGolfClub {
    dexterity
    flex
    option
    id
    purchaseDate
    purchasePrice
    golfClubModel {
      ...GolfClubModelAttrs
    }
  }
  ${GolfClubModelAttrs}
`

export const UserBrowsingViewsAttrs = gql`
  fragment UserBrowsingViewsAttrs on BrowsingView {
    id
    insertedAt
    updatedAt
    golfClubModel {
      ...GolfClubModelBrowsingHistoryAttrs
    }
    golfEquipmentModel {
      ...GolfEquipmentModelBrowsingHistoryAttrs
    }
  }
  ${GolfClubModelBrowsingHistoryAttrs}
  ${GolfEquipmentModelBrowsingHistoryAttrs}
`

export const PaymentCardAttrs = gql`
  fragment PaymentCardAttrs on PaymentCard {
    brand
    default
    expMonth
    expYear
    id
    insertedAt
    number
    updatedAt
  }
`

export const UserAttrs = gql`
  fragment UserAttrs on CurrentUser {
    basicInfo {
      dexterity
      experienceLevel
      flex
      gripSize
      height
    }
    email
    firstName
    gender
    id
    insertedAt
    lastName
    sellerStatus
    updatedAt
    userName
  }
`

export const SharedUserAttrs = gql`
  fragment SharedUserAttrs on User {
    id
    userName
    firstName
    lastName
    basicInfo {
      ...UserBasicInfoAttrs
    }
    firstName
    lastName
    avatar
  }

  ${UserBasicInfoAttrs}
`
