import { gql } from '@apollo/client'
import {
  GolfClubAttrs,
  GolfClubResponseAttrs,
  GolfClubSalesHistoryAttrs,
  GolfClubInventoryDetailsAttrs,
  ValidationMessageAttrs
} from '@fragments'

export const FetchSalesHistory = gql`
  query SalesHistory($golfClubModelId: UUID4!) {
    res: salesHistory(golfClubModelId: $golfClubModelId) {
      ...GolfClubSalesHistoryAttrs
    }
  }
  ${GolfClubSalesHistoryAttrs}
`

export const FetchGolfClub = gql`
  query FetchGolfClub($id: UUID4!) {
    res: golfClub(id: $id) {
      ...GolfClubInventoryDetailsAttrs
    }
  }
  ${GolfClubInventoryDetailsAttrs}
`

export const FetchPaginatedGolfClubs = gql`
  query FetchPaginatedGolfClubs($page: Int, $pageSize: Int, $term: String) {
    res: paginatedGolfClubs(page: $page, pageSize: $pageSize, term: $term) {
      entries {
        ...GolfClubAttrs
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }

  ${GolfClubAttrs}
`

export const FetchPaginatedActualGolfClubs = gql`
  query FetchPaginatedGolfClubs($page: Int, $pageSize: Int, $term: String) {
    res: paginatedActualGolfClubs(page: $page, pageSize: $pageSize, term: $term) {
      entries {
        ...GolfClubAttrs
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }

  ${GolfClubAttrs}
`

export const CreateGolfClub = gql`
  mutation CreateGolfClub($golfClub: GolfClubParams) {
    res: createGolfClub(golfClub: $golfClub) {
      ...GolfClubResponseAttrs
    }
  }

  ${GolfClubResponseAttrs}
`

export const UpdateGolfClub = gql`
  mutation UpdateGolfClub($id: UUID4!, $golfClub: GolfClubParams) {
    res: updateGolfClub(id: $id, golfClub: $golfClub) {
      ...GolfClubResponseAttrs
    }
  }

  ${GolfClubResponseAttrs}
`

export const DeleteGolfClub = gql`
  mutation DeleteGolfClub($id: UUID4!) {
    res: deleteGolfClub(id: $id) {
      ...GolfClubResponseAttrs
    }
  }

  ${GolfClubResponseAttrs}
`

export const ExtendGolfClub = gql`
  mutation ExtendGolfClub($id: UUID4!, $expiresAt: Date!) {
    res: extendGolfClub(id: $id, expiresAt: $expiresAt) {
      ...GolfClubResponseAttrs
    }
  }
  ${GolfClubResponseAttrs}
`

export const RepublishGolfClub = gql`
  mutation RepublishGolfClub($expiresAt: Date!, $id: UUID4!, $paymentCardId: UUID4!) {
    res: republishGolfClub(id: $id, expiresAt: $expiresAt, paymentCardId: $paymentCardId) {
      ...GolfClubResponseAttrs
    }
  }
  ${GolfClubResponseAttrs}
`

export const DeleteMultipleGolfClub = gql`
  mutation DeleteMultipleGolfClubs($ids: [UUID4]) {
    deleteGolfClubs(ids: $ids) {
      successful
    }
  }
`

export const CreateGolfClubModelAlert = gql`
  mutation CreateGolfClubModelAlert($golfClubModelAlert: GolfClubModelAlertParams!) {
    res: createGolfClubModelAlert(golfClubModelAlert: $golfClubModelAlert) {
      successful
      messages {
        ...ValidationMessageAttrs
      }
    }
  }
  ${ValidationMessageAttrs}
`
