import React, { memo } from 'react'
import { isEqual } from 'lodash'
import styles from './Switcher.module.scss'

export interface SwitcherOption {
  label: string
  value: any
}

export interface SwitcherProps {
  hide?: boolean
  options: SwitcherOption[]
  value: any
  onClick(value: any): void
}

const Switcher: React.FC<SwitcherProps> = props => {
  const { options, value, onClick, hide } = props

  let side: string | null = null

  options.forEach((option, index) => {
    if (isEqual(option.value, value)) {
      side = index === 0 ? (side = 'left') : (side = 'right')
    }
  })

  if (hide) return null

  return (
    <div className={styles.switcher}>
      <div className={styles.switcherKnob} data-side={side} />
      {options.map(option => {
        const { label } = option
        const isActive = isEqual(option.value, value)

        return (
          <div
            key={label}
            className={styles.switcherOption}
            onClick={() => onClick(option.value)}
            data-active={isActive}
          >
            {label}
          </div>
        )
      })}
    </div>
  )
}

export default memo(Switcher)
