import * as AbsintheSocket from '@absinthe/socket'
import { AbsintheSocketLink, createAbsintheSocketLink } from '@absinthe/socket-apollo-link'
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { hasSubscription } from '@jumpn/utils-graphql'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'cross-fetch'
import { Socket as PhoenixSocket } from 'phoenix'
import gatsbyApolloCache from './gatsby-apollo-cache'

const isWindow = typeof window !== 'undefined'

let link: ApolloLink | null = null
const uri = process.env.GATSBY_API || ''
const socketUrl = process.env.SOCKET_URL

if (isWindow) {
  const httpLink = createUploadLink({
    uri,
    fetch
  })

  const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('token')
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : ''
      }
    }))
    return forward(operation)
  })

  const subscriptionAuthedLink = authLink.concat(httpLink)
  const timeoutLink = new ApolloLinkTimeout(120000) // 120 seconds timeout like on Loader element
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      const { name, message, stack } = networkError
      networkError = {
        name,
        message,
        stack
      }
    }
  })

  const phoenixSocket = new PhoenixSocket(socketUrl ? socketUrl : '', {
    transport: global.WebSocket,
    params: () => {
      const token = localStorage.getItem('token')
      if (token) {
        return { authorization: 'Bearer ' + token }
      } else {
        return {}
      }
    }
  })

  phoenixSocket.connect({ authorization: 'Bearer ' + localStorage.getItem('token') })

  phoenixSocket.onError(() => {
    const token = localStorage.getItem('token')
    if (!token) phoenixSocket.disconnect()
  })

  const absintheSocket = AbsintheSocket.create(phoenixSocket)
  const websocketLink: AbsintheSocketLink = createAbsintheSocketLink(absintheSocket)
  const subscriptionsLink = split(operation => hasSubscription(operation.query), websocketLink, subscriptionAuthedLink)
  link = from([timeoutLink, errorLink, subscriptionsLink])
}

const client = link
  ? new ApolloClient({
      link,
      cache: gatsbyApolloCache,
      connectToDevTools: true
    })
  : new ApolloClient({ link: new HttpLink({ uri, fetch }), cache: new InMemoryCache() })

export default client
