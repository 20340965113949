import React from 'react'
import { navigate } from 'gatsby'
import { AddressCard } from '@types'
import './UserAddress.scss'

export interface UserAddressProps {
  addressCard: AddressCard
}

const UserAddress: React.FC<UserAddressProps> = props => {
  const { addressCard } = props
  const { addressLine, address, city, state, zipCode } = addressCard

  return (
    <div className={'user-address'}>
      <div>Address</div>
      <hr />
      <div className={'user-address-information'}>
        <div className={'user-address-information-title'}>
          {addressLine} {address} {city}, {state}, {zipCode}
        </div>
        <div onClick={() => navigate('/account/profile')} className={'user-address-information-button'}>
          Go to My Profile
        </div>
      </div>
    </div>
  )
}

export default UserAddress
