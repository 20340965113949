import React from 'react'
import { ApolloProvider, useQuery } from '@apollo/client'
import { Provider as AlertProvider, transitions } from 'react-alert'
import { Drawers, Footer, Header, Modals, Page, SEO, FooterNavigation } from '@components'
import { Alert } from '@elements'
import { FetchAppData } from '@graphql'
import { loadStripe } from '@stripe/stripe-js/pure'
import { Elements } from '@stripe/react-stripe-js'
import { CatalogueLockScrollProvider } from '@contexts'
import client from './gatsby-apollo'
import '@styles/app.scss'
import '@styles/typography.scss'

const AppPrefetchData = ({ element }) => {
  /* Fetches Initial app data */
  const { loading } = useQuery(FetchAppData)

  if (loading) return null

  const alertOptions = {
    position: 'top right',
    timeout: 2000,
    offset: '25px 30px -10px',
    transition: transitions.SCALE
  }

  return (
    <AlertProvider template={Alert} {...alertOptions}>
      <SEO title={'TourDay Golf - A Marketplace for Golfers'} />
      <CatalogueLockScrollProvider>{element}</CatalogueLockScrollProvider>
    </AlertProvider>
  )
}

// const stripePromise = loadStripe(
//   'pk_test_51IAzSADr3dwxb0om9NGrPlj9rdMHdmq3Oa6nuvVq5CfZxJMGKpf4w3SqgDVWvrOUDyy9b3BiyJK5pnrbJ0cC7brL00u3e3bhOj'
// )

// eslint-disable-next-line react/display-name
export const wrapPageElement = ({ element, props }) => {
  // All routing logic is in the Page component
  // const [stripePromise, setStripePromise] = useState(() =>
  //   loadStripe(
  //     'pk_test_51IAzSADr3dwxb0om9NGrPlj9rdMHdmq3Oa6nuvVq5CfZxJMGKpf4w3SqgDVWvrOUDyy9b3BiyJK5pnrbJ0cC7brL00u3e3bhOj'
  //   )
  // )
  const stripePromise = loadStripe(
    'pk_test_51IAzSADr3dwxb0om9NGrPlj9rdMHdmq3Oa6nuvVq5CfZxJMGKpf4w3SqgDVWvrOUDyy9b3BiyJK5pnrbJ0cC7brL00u3e3bhOj'
  )
  return (
    <Elements stripe={stripePromise}>
      <Modals />
      <Drawers />
      <Header />
      <Page {...props}>{React.createElement(element.type, props)}</Page>
      <Footer />
      <FooterNavigation />
    </Elements>
  )
}

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <AppPrefetchData element={element} />
    </ApolloProvider>
  )
}
