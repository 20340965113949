import { Input, Loader } from '@elements'
import { useGolfClubModelSearch, useWindowSize } from '@hooks'
import SearchImage from '@images/search.svg'
import { ElasticCatalogItem } from '@types'
import { navigate } from 'gatsby'
import React, { memo, useEffect, useRef, useState } from 'react'
import styles from './MobileGolfClubModelSearch.module.scss'
import { useLocation } from '@reach/router'

export interface MobileGolfClubModelSearchProps {
  nav?: boolean
}

const renderSearchOptions = (catalogItems: ElasticCatalogItem[], params: string) => {
  if (!catalogItems || !catalogItems.length) {
    return <div className={styles.mobileAutocompleteListNotAvailable}>No options available</div>
  }

  return (
    <>
      {catalogItems.map(catalogItem => {
        return (
          <li
            key={catalogItem.id}
            className={styles.mobileAutocompleteListItem}
            onClick={() => navigate(`/product/${catalogItem.slug}`)}
          >
            {`${catalogItem?.brand || ''}  ${catalogItem?.title || ''} - ${catalogItem.category || ''}`}
          </li>
        )
      })}
      <li
        className={styles.mobileAutocompleteListItem}
        data-all-results={true}
        onClick={() => navigate('/catalogue' + params)}
      >
        All results
      </li>
    </>
  )
}

const MobileGolfClubModelSearch: React.FC<MobileGolfClubModelSearchProps> = props => {
  const dropdownTimer: React.MutableRefObject<number> = useRef<number>(0)
  const [isSearchDropdownOpened, setIsSearchDropdownOpened] = useState<boolean>(false)

  const { pathname } = useLocation()
  const currentPage = pathname.split('/')[1]
  const isNavDrawerSearch = props.nav ?? false

  // determine if component should direct to full catalogue page on Enter

  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  useEffect(() => clearTimeout(dropdownTimer.current), [])

  const {
    search,
    handleSearch,
    handleUrlSearchQueryClear,
    catalogItems,
    loading,
    openCataloguePageWithSearch,
    params
  } = useGolfClubModelSearch()

  const handleOpenSearchDropdown = (isOpen: boolean) => () => {
    if (!isOpen) {
      dropdownTimer.current = (setTimeout(() => setIsSearchDropdownOpened(isOpen), 300) as unknown) as number
    } else {
      setIsSearchDropdownOpened(isOpen)
    }
  }

  if (!isMobileRender) {
    return null
  }

  return (
    <div className={styles.mobileAutocomplete}>
      <Input
        prefix={loading ? <Loader timeout={1000} /> : <SearchImage />}
        type={'text'}
        name={'gcmSearch'}
        placeholder={'Search'}
        onChange={handleSearch}
        onTimesClick={handleUrlSearchQueryClear}
        value={search}
        onFocus={
          currentPage === 'home' || currentPage === 'product' || isNavDrawerSearch
            ? handleOpenSearchDropdown(true)
            : handleOpenSearchDropdown(false)
        }
        onBlur={() => {
          setTimeout(() => {
            setIsSearchDropdownOpened(false)
          }, 300)

          handleOpenSearchDropdown(false)
        }}
        onKeyDown={e => {
          if (!isSearchDropdownOpened && currentPage === 'home') {
            setIsSearchDropdownOpened(true)
          }
          if (e.key === 'Enter' || e.key === 'Return') {
            setIsSearchDropdownOpened(false)
            e.currentTarget.blur()
            // only redirect to catalog page if enter was clicked on home page.
            // otherwise just blur to drop the mobile keyboard and show results on
            // current browse page
            if (currentPage === 'home' || currentPage === 'product' || isNavDrawerSearch) {
              openCataloguePageWithSearch()
            }
          }
        }}
      />
      <ul className={styles.mobileAutocompleteList} data-open={isSearchDropdownOpened}>
        {renderSearchOptions(catalogItems, params)}
      </ul>
    </div>
  )
}

export default memo(MobileGolfClubModelSearch)
