import React, { memo } from 'react'
import { Brand } from '@types'
import { AvatarCard } from '@elements'
import { useUrlSearchParams } from '@hooks'
import styles from './BrandCard.module.scss'

interface GolfClubModelCardProps {
  brand: Brand
}

const BrandCard: React.FC<GolfClubModelCardProps> = props => {
  const { brand } = props
  const { id, title, avatar } = brand

  const { setUrlSearchParam } = useUrlSearchParams('brandIds')

  const handleBrandCardClick = async () => {
    await setUrlSearchParam(id, { redirect: '/catalogue' })
  }
  return (
    <div className={styles.brandCard} onClick={handleBrandCardClick}>
      <AvatarCard avatar={avatar} type={'brand'} />
      <div className={styles.brandCardInfo}>
        <h6 className={'subtitle2 uppercase'}>
          <strong>{title}</strong>
        </h6>
      </div>
    </div>
  )
}

export default memo(BrandCard)
