import React, { memo } from 'react'
import { Collapse as CollapseComponent, CollapseProps as CollapseComponentProps } from 'react-collapse'
import styles from './Collapse.module.scss'

interface CollapseProps extends CollapseComponentProps {}

const Collapse: React.FC<CollapseProps> = props => {
  const { children, ...collapseProps } = props

  return <CollapseComponent {...collapseProps}>{children}</CollapseComponent>
}

Collapse.defaultProps = {
  theme: {
    collapse: styles.reactCollapse,
    content: styles.reactCollapseContent
  }
}

export default memo(Collapse)
