import React, { memo } from 'react'
import { Select, SelectProps } from '@components'
import { shafts } from '@utils'
import { capitalize } from 'lodash'
import styles from './ShaftSelect.module.scss'

export interface ShaftSelectProps extends Omit<SelectProps, 'options'> {
  defaultValue?: string | number | Record<string, any>
}

const ShaftSelect: React.FC<ShaftSelectProps> = props => {
  const shaftOptions = shafts
    .map(shaft => {
      return {
        label: capitalize(shaft),
        value: shaft
      }
    })
    .reverse()

  return (
    <div className={styles.shaftSelect}>
      <Select options={shaftOptions} {...props} />
    </div>
  )
}

export default memo(ShaftSelect)
