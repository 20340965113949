import { isDate } from '@utils'

export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const passwordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.{6,})')

const noWhitespaceString = /.*\S.*/

export const zipCodePattern = new RegExp('[0-9]{5}')
export const phoneNumberPattern = new RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$')
export const americanPhoneNumberMaskPattern = new RegExp('^[0-9]{3}-[0-9]{3}-[0-9]{4}')

export const phoneValidation = {
  // minLength: { value: 10, message: 'Phone number must have a 10 digits minimum' },
  // maxLength: { value: 10, message: 'Phone number must have a 10 digits maximum' }
  pattern: { value: americanPhoneNumberMaskPattern, message: 'Please check XXX-XXX-XXXX format' }
}

export const zipCodeValidation = {
  minLength: { value: 5, message: 'Please check XXXXX format' },
  maxLength: { value: 5, message: 'Please check XXXXX format' },
  pattern: { value: zipCodePattern, message: 'Please check XXXXX format' }
}

export const passwordValidation = {
  minLength: { value: 6, message: 'Password must have a minimum of 6 characters' },
  pattern: {
    value: passwordPattern,
    message: 'Password must include one lowercase letter, one uppercase letter, one digit, and one special character.'
  }
}

export const dateValidation = {
  minLength: { value: 10, message: 'Please check yyyy-mm-dd format' },
  maxLength: { value: 10, message: 'Please check yyyy-mm-dd format' },
  validate: {
    isDate: (value: string | null) => {
      if (value) {
        return isDate(new Date(value))
      }
    }
  }
}

export const noOnlyWhitespaceValidation = {
  pattern: {
    value: noWhitespaceString,
    message: 'This field cannot contain only spaces'
  }
}
