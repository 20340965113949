import React, { memo, useState } from 'react'
import { Link, Collapse, Input } from '@elements'
import { Children } from '@types'
import SearchImage from '@images/search.svg'
import styles from './CollapseWithSearch.module.scss'

interface CollapseWithSearchProps {
  search: string
  onSearch(search: string): void
  onSearchClear(): void
  renderOuterCollapse(isOpened: boolean): Children
  renderInnerCollapse(isOpened: boolean): Children
  isHideShowAll?: boolean
}

const CollapseWithSearch: React.FC<CollapseWithSearchProps> = props => {
  const { renderOuterCollapse, renderInnerCollapse, search, onSearch, onSearchClear, isHideShowAll } = props
  const [isOpened, open] = useState(false)

  const renderCollapseSearch = () => {
    if (!isOpened) return null

    return (
      <div className={styles.collapseWithSearchInput}>
        <Input
          type={'text'}
          name={'collapseBrandsSearch'}
          prefix={<SearchImage />}
          value={search}
          onChange={onSearch}
          onTimesClick={onSearchClear}
          placeholder={'Search'}
        />
      </div>
    )
  }

  const renderCollapseButton = () => {
    const text = isOpened ? 'Close' : 'Show All'
    if (isHideShowAll) return null
    return (
      <div className={styles.collapseWithSearchButton}>
        {isOpened && <hr />}
        <Link
          to={'*'}
          onClick={e => {
            e.preventDefault()
            open(!isOpened)
          }}
          underlined
        >
          {text}
        </Link>
      </div>
    )
  }

  return (
    <div className={styles.collapseWithSearch}>
      {renderCollapseSearch()}
      <div className={styles.collapseWithSearchInner}>
        {renderOuterCollapse(isOpened)}
        <Collapse isOpened={isOpened}>{renderInnerCollapse(isOpened)}</Collapse>
      </div>
      {renderCollapseButton()}
    </div>
  )
}

export default memo(CollapseWithSearch)
