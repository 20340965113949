import { gql } from '@apollo/client'

export const PaginatedGolfItems = gql`
  query PaginatedGolfItems($page: Int, $pageSize: Int, $term: String) {
    res: paginatedGolfItems(page: $page, pageSize: $pageSize, term: $term) {
      entries {
        brandTitle
        categoryTitle
        expiresAt
        id
        insertedAt
        modelAvatar
        modelTitle
        price
        status
        updatedAt
        type
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
`
