import { gql } from '@apollo/client'
import { PurchaseRequestAttrs } from './commonTypes'

export const GolfEquipmentModelMarketDataHistoryAttrs = gql`
  fragment GolfEquipmentModelMarketDataHistoryAttrs on GolfEquipmentModel {
    golfEquipmentTypes {
      condition
      id
      option
      updatedAt
      purchaseRequests {
        itemPrice
        id
        status
        updatedAt
      }
      golfEquipments {
        id
        price
        status
        updatedAt
      }
      openPurchaseRequests {
        ...PurchaseRequestAttrs
      }
    }
    id
  }
  ${PurchaseRequestAttrs}
`
