import { useMutation } from '@apollo/client'
import { useRequestErrorHandler } from '@hooks'
import { useAlert } from 'react-alert'
import { ContactUsForm, MutationResponse, ResponseType } from '@types'
import { contactUs } from '@graphql'

type ContactUsResponse = ResponseType<MutationResponse & { result: string }>

const useContactUsForm = () => {
  const handleRequestError = useRequestErrorHandler()

  const alert = useAlert()

  const [createAddressCardMutation] = useMutation<ContactUsResponse, { contactUs: ContactUsForm }>(
    contactUs.SendContactUsFormToEmail,
    {
      onError: error => handleRequestError(null, error)
    }
  )

  const createAddressCard = async (contactUs: ContactUsForm) => {
    const request = await createAddressCardMutation({
      variables: { contactUs }
    })
    request &&
      alert.show(`Success!`, {
        type: 'success'
      })
    return handleRequestError<string>(request)
  }
  return { createAddressCard }
}

export default useContactUsForm
