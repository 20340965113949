import React from 'react'
import { Select, SelectProps } from '@components'
import { capitalize } from 'lodash'
import styles from './ExperienceLevelSelect.module.scss'

export interface ExperienceLevelSelectProps extends Omit<SelectProps, 'options'> {
  defaultValue?: string | number | Record<string, any>
}

const ExperienceLevelSelect: React.FC<ExperienceLevelSelectProps> = props => {
  const options = ["I'm new to the game ", 'I play a few times a year', 'I track my handicap', 'Scratch']

  const experienceOptions = options.map(experience => {
    return {
      label: capitalize(experience),
      value: experience
    }
  })

  return (
    <div className={styles.experienceSelect}>
      <Select options={experienceOptions} {...props} />
    </div>
  )
}

export default ExperienceLevelSelect
