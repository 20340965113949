import { gql } from '@apollo/client'

export const ValidationMessageAttrs = gql`
  fragment ValidationMessageAttrs on ValidationMessage {
    code
    field
    message
    options {
      key
      value
    }
    template
  }
`

export const SubscribedEmailAttrs = gql`
  fragment SubscribedEmailAttrs on SubscribedEmailChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`

export const StatusResponseAttrs = gql`
  fragment StatusResponseAttrs on StatusChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`

export const UserGolfClubResponseAttrs = gql`
  fragment UserGolfClubResponseAttrs on UserGolfClubChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`

export const GolfClubResponseAttrs = gql`
  fragment GolfClubResponseAttrs on GolfClubChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`
export const GolfClubDraftResponseAttrs = gql`
  fragment GolfClubDraftResponseAttrs on GolfClubDraftChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`

export const UserResponseAttrs = gql`
  fragment UserResponseAttrs on CurrentUserChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`

export const BrowsingViewResponseAttrs = gql`
  fragment BrowsingViewResponseAttrs on BrowsingViewChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`

export const PaymentCardResponseAttrs = gql`
  fragment PaymentCardResponseAttrs on PaymentCardChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`

export const AddressCardResponseAttrs = gql`
  fragment AddressCardResponseAttrs on AddressCardChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`
export const LockerResponseAttrs = gql`
  fragment LockerResponseAttrs on LockerChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`

export const OrderResponseAttrs = gql`
  fragment OrderResponseAttrs on OrderChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`

export const GolfEquipmentResponseAttrs = gql`
  fragment GolfEquipmentResponseAttrs on GolfEquipmentChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`
export const CreateNewProductFeedbackResponseAttrs = gql`
  fragment CreateNewProductFeedbackResponseAttrs on NewProductFeedbackChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`

export const CreateExistingProductFeedbackResponseAttrs = gql`
  fragment CreateExistingProductFeedbackResponseAttrs on ExistingProductFeedbackChangeset {
    successful
    messages {
      ...ValidationMessageAttrs
    }
  }

  ${ValidationMessageAttrs}
`
