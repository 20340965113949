import React from 'react'
import { useQuery } from '@apollo/client'
import { BrandCard, ListSection, ListSectionPropsType } from '@components'
import { Filters, ResponseType } from '@types'
import { filters, brand } from '@graphql'
import styles from './BrandsListSection.module.scss'

export interface BrandsListSectionProps extends ListSectionPropsType {
  noItemMessage?: string
  categoryId?: string
}

type CategoryBrand = {
  avatar: string
  id: string
  insertedAt: string
  title: string
  updatedAt: string
}

const BrandsListSection: React.FC<BrandsListSectionProps> = props => {
  const { noItemMessage, categoryId, ...listSectionProps } = props
  const { data: filtersData, loading: filtersLoading, error: filtersError } = useQuery<ResponseType<Filters>>(
    filters.FetchFilters,
    { fetchPolicy: 'cache-only' }
  )
  const { data: categoryBrandsData, loading: categoryBrandsLoading, error: categoryBrandsError } = useQuery<
    ResponseType<CategoryBrand[]>,
    { categoryId: string }
  >(brand.FetchBrandByCategory, {
    variables: { categoryId: categoryId! },
    skip: !categoryId
  })
  const loading = categoryId ? categoryBrandsLoading : filtersLoading
  const error = categoryId ? categoryBrandsError : filtersError

  const list = categoryBrandsData?.res?.slice(0, 4) || filtersData?.res?.brands?.slice(0, 4) || []
  return (
    <div className={styles.brandsListSection}>
      <ListSection
        list={list}
        loading={loading}
        error={error}
        Component={({ listData }) => <BrandCard brand={listData} />}
        noItemMessage={noItemMessage}
        {...listSectionProps}
      />
    </div>
  )
}

export default BrandsListSection
