import { useReactiveVar } from '@apollo/client'
import { CacheDrawer } from '@types'
import { drawersVar } from '@cache'

type UseDrawerResult = {
  drawer: CacheDrawer | null
  openDrawer: () => void
  closeDrawer: () => void
}

const useDrawer = (drawerName: string): UseDrawerResult => {
  const drawers = useReactiveVar(drawersVar)
  const drawer = drawers.find(({ name }) => name === drawerName) || null

  // SSR check
  if (typeof window === 'undefined') {
    return {
      drawer,
      openDrawer: () => null,
      closeDrawer: () => null
    }
  }

  // Update drawers with filtered array and new drawer pushed
  const openDrawer = () => {
    // Add modal to cache
    drawersVar([{ name: drawerName }])
    window.addEventListener('keydown', onEscKeyDown, false)
  }

  // Update drawers with filtered array
  const closeDrawer = () => {
    // Find drawer
    const drawerElement = document.getElementById(drawerName)

    if (drawerElement) {
      window.removeEventListener('keydown', onEscKeyDown, false)
    }

    drawersVar([])
  }

  const onEscKeyDown = (e: { key: string }) => e.key === 'Escape' && closeDrawer()

  return {
    drawer,
    openDrawer,
    closeDrawer
  }
}

export default useDrawer
