import { gql } from '@apollo/client'

export const SectionAttrs = gql`
  fragment SectionAttrs on Section {
    active
    id
    insertedAt
    items {
      avatar
      id
      insertedAt
      link
      order
      updatedAt
    }
    link
    order
    title
    type
    updatedAt
  }
`
