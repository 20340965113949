import React, { memo } from 'react'
import PlusImage from '@images/plus.svg'
import styles from './ButtonWithPlus.module.scss'

export interface ButtonWithPlusProps {
  label?: string
  onClick(e: React.MouseEvent<HTMLDivElement>): void
}

const ButtonWithPlus: React.FC<ButtonWithPlusProps> = props => {
  const { children, label, onClick } = props

  return (
    <div className={styles.buttonWithPlus}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={styles.buttonWithPlusInner} onClick={onClick}>
        <PlusImage />
        {children}
      </div>
    </div>
  )
}

export default memo(ButtonWithPlus)
