import React from 'react'
import { List, ListProps } from '@components'
import { Link, Tooltip } from '@elements'
import { useUrlSearchParams } from '@hooks'
import styles from './ListSection.module.scss'

export type ListSectionLinkType = {
  label: string
  value: string
}

export interface ListSectionPropsType {
  title: string
  tooltip?: string
  link: ListSectionLinkType
}

export type ListSectionProps = ListSectionPropsType & ListProps

const ListSection: React.FC<ListSectionProps> = props => {
  const { title, tooltip, link, ...listProps } = props
  const { label, value } = link
  const { setUrlSearchParam } = useUrlSearchParams('sortBy')

  const handleRedirectToCatalogueWithQueryParam = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    await setUrlSearchParam(value, { redirect: '/catalogue' })
  }

  return (
    <div className={styles.listSection}>
      <div className={styles.listSectionHeader}>
        <div className={styles.listSectionHeaderTitle}>
          <h5>{title}</h5>
          {tooltip && <Tooltip>{tooltip}</Tooltip>}
        </div>
        {!!listProps.list.length && (
          <Link to={'*'} onClick={handleRedirectToCatalogueWithQueryParam} withArrow>
            {label}
          </Link>
        )}
      </div>
      <List {...listProps} />
    </div>
  )
}

export default ListSection
