import { gql } from '@apollo/client'
import {
  AddressCardAttrs,
  FiltersAttrs,
  LockerAttrs,
  PurchaseRequestAttr,
  PurchaseRequestResponseAttr
} from '@fragments'

export * as golfClubModel from './golfClubModel'
export * as auth from './auth'
export * as brand from './brand'
export * as category from './category'
export * as filters from './filters'
export * as golfClub from './golfClub'
export * as user from './user'
export * as addressCard from './addressCard'
export * as order from './order'
export * as golfClubType from './golfClubType'
export * as purchaseRequest from './purchaseRequest'
export * as store from './store'
export * as golfClubDraft from './golfClubDraft'
export * as csv from './csv'
export * as section from './section'
export * as browsingViews from './browsingViews'
export * as userGolfClub from './userGolfClub'
export * as paymentCard from './paymentCard'
export * as promoCode from './promoCode'
export * as browsePage from './browsePage'
export * as catalogue from './catalogue'
export * as equipment from './equipment'
export * as notifications from './notifications'
export * as golfItems from './golfItems'
export * as golfEquipment from './golfEquipment'
export * as Locker from './locker'
export * as golfCourses from './golfCourses'
export * as searchQuery from './searchQuery'
export * as elastic from './elastic'
export * as optionsClasses from './optionsClasses'
export * as contactUs from './contactUs'
export * as homeCourse from './homeCourse'

export const FetchAppData = gql`
  query FetchAppData {
    currentUser {
      isAdmin
      addressCards {
        ...AddressCardAttrs
      }
      basicInfo {
        dexterity
        experienceLevel
        flex
        gripSize
        height
      }
      avatar
      defaultAddressCard {
        ...AddressCardAttrs
      }
      email
      emailApproved
      firstName
      gender
      id
      insertedAt
      lastName
      paymentCards {
        brand
        default
        expMonth
        expYear
        id
        insertedAt
        number
        updatedAt
      }
      sellerStatus
      stripeAccountSubmitted
      updatedAt
      userName
      locker {
        ...LockerAttrs
      }
      defaultPaymentCard {
        brand
        default
        expMonth
        expYear
        id
        insertedAt
        number
        updatedAt
      }
    }
    filters {
      ...FiltersAttrs
    }
  }

  ${FiltersAttrs}
  ${LockerAttrs}
  ${AddressCardAttrs}
`

export const SellNow = gql`
  mutation sellNow($purchaseRequestId: UUID4!, $paymentCardId: UUID4!, $addressCardId: UUID4!) {
    res: sellNow(purchaseRequestId: $purchaseRequestId, paymentCardId: $paymentCardId, addressCardId: $addressCardId) {
      ...PurchaseRequestResponseAttr
      result {
        ...PurchaseRequestAttr
      }
    }
  }

  ${PurchaseRequestResponseAttr}
  ${PurchaseRequestAttr}
`

export const GetPaymentInfo = gql`
  query GetPaymentInfo($price: Float!, $addressCardId: UUID4!, $promoCodeId: UUID4) {
    res: paymentInfo(price: $price, addressCardId: $addressCardId, promoCodeId: $promoCodeId) {
      buyerSalesTax
      buyerShippingPrice
      buyerTotalPrice
      itemPrice
      sellerProcessingFee
      sellerProfit
      sellerShippingPrice
      sellerTotalPrice
    }
  }
`
