import React, { memo } from 'react'
import { TableColumnType } from '@types'
import styles from './TableHead.module.scss'

interface TableHeadProps {
  columns: TableColumnType[]
  isRowIndex?: boolean
  isFixedThead?: boolean
}

const TableHead: React.FC<TableHeadProps> = props => {
  const { columns, isRowIndex, isFixedThead } = props

  return (
    <thead className={styles.tableHead}>
      <tr className={styles.tableHeadTr}>
        {isRowIndex && (
          <th className={styles.tableHeadTrTh} data-testid={'thead-row-index'} data-fixed={isFixedThead} />
        )}
        {columns.map((column, index) => {
          return (
            <th
              className={styles.tableHeadTrTh}
              key={index}
              data-avatar={column.key === 'avatar'}
              data-fixed={isFixedThead}
            >
              {column.title}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default memo(TableHead)
