import { Reference, useMutation, useQuery } from '@apollo/client'
import { useAlert } from 'react-alert'
import {
  AddressCard,
  AddressCardFormArgs,
  AddressCardFormType,
  CacheUser,
  MutationResponse,
  ResponseType,
  UpdateAddressCard
} from '@types'
import { useRequestErrorHandler } from '@hooks'
import { user as graphqlUser, addressCard as graphqlAddressCard } from '@graphql'
import { AddressCardAttrs } from '@fragments'

type AddressCardResponse = ResponseType<MutationResponse & { result: AddressCard }>

const useGraphqlAddressCard = () => {
  const alert = useAlert()
  const handleRequestError = useRequestErrorHandler()

  const { data: currentUserData } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-first'
  })
  const currentUser = currentUserData?.res

  const [createAddressCardMutation] = useMutation<AddressCardResponse, { addressCard: AddressCardFormArgs }>(
    graphqlAddressCard.CreateAddressCard,
    { onError: error => handleRequestError(null, error) }
  )

  const [updateAddressCardMutation] = useMutation<
    AddressCardResponse,
    { id: string; addressCard: Omit<UpdateAddressCard, 'id'> }
  >(graphqlAddressCard.UpdateAddressCard, { onError: error => handleRequestError(null, error) })

  const [deleteAddressCardMutation] = useMutation<AddressCardResponse, { id: string }>(
    graphqlAddressCard.DeleteAddressCard,
    { onError: error => handleRequestError(null, error) }
  )

  const createAddressCard = async (addressCard: AddressCardFormArgs) => {
    const request = await createAddressCardMutation({
      variables: { addressCard },
      refetchQueries: [{ query: graphqlUser.FetchCurrentUser }],
      update: (cache, mutationResult) => {
        const result = mutationResult?.data?.res?.result
        const successful = mutationResult?.data?.res?.successful

        if (!successful) return null

        if (currentUser?.id && result) {
          const newAddressCardRef = cache.writeFragment({
            data: { ...result },
            fragment: AddressCardAttrs
          })
          cache.modify({
            id: cache.identify(currentUser),
            fields: {
              addressCards(addressCardRefs: Reference[]) {
                return [...addressCardRefs, newAddressCardRef]
              }
            }
          })
        }

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError<AddressCard>(request)
  }

  const updateAddressCard = async (addressCard: AddressCardFormArgs & { id: string }) => {
    const { id: addressCardId, ...addressCardUpdate } = addressCard

    const request = await updateAddressCardMutation({
      variables: { id: addressCardId, addressCard: addressCardUpdate },
      refetchQueries: [{ query: graphqlUser.FetchCurrentUser }],
      update: (cache, mutationResult) => {
        const result = mutationResult?.data?.res?.result
        const successful = mutationResult?.data?.res?.successful

        if (!successful) return null

        if (currentUser?.id && result) {
          cache.modify({
            id: cache.identify(currentUser),
            fields: {
              addressCards(addressCardRefs: Reference[], { readField }) {
                const newAddressCardRef = cache.writeFragment({
                  data: result,
                  fragment: AddressCardAttrs
                })
                return addressCardRefs.map(ref => {
                  if (readField('id', ref) === addressCardId) return newAddressCardRef
                  return ref
                })
              }
            }
          })
        }

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const useAsDefaultAddressCard = async (addressCard: UpdateAddressCard & { id: string }) => {
    const { id: addressCardId } = addressCard

    const request = await updateAddressCardMutation({
      variables: { id: addressCardId, addressCard: { default: true } },
      update: (cache, mutationResult) => {
        const result = mutationResult?.data?.res?.result
        const successful = mutationResult?.data?.res?.successful

        if (!successful) return null
        if (currentUser?.addressCards && result) {
          const prevDefaultAddressCard = currentUser?.addressCards.find(addressCard => addressCard.default)
          let newPrevDefaultAddressCardRef: Reference | undefined
          if (prevDefaultAddressCard) {
            newPrevDefaultAddressCardRef = cache.writeFragment({
              id: cache.identify(prevDefaultAddressCard),
              data: { ...prevDefaultAddressCard, default: false },
              fragment: AddressCardAttrs
            })
          }
          const newDefaultAddressCardRef = cache.writeFragment({
            id: cache.identify(result),
            data: result,
            fragment: AddressCardAttrs
          })

          cache.modify({
            id: cache.identify(currentUser),
            fields: {
              addressCards(addressCardRefs: Reference[], { readField }) {
                return addressCardRefs.map(ref => {
                  if (
                    newPrevDefaultAddressCardRef &&
                    readField('id', ref) === readField('id', newPrevDefaultAddressCardRef)
                  ) {
                    return newPrevDefaultAddressCardRef
                  }
                  if (readField('id', newDefaultAddressCardRef) === readField('id', ref)) {
                    return newDefaultAddressCardRef
                  }
                  return ref
                })
              }
            }
          })
        }

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const deleteAddressCard = async (addressCardId: string) => {
    const request = await deleteAddressCardMutation({
      variables: { id: addressCardId },
      update: (cache, mutationResult) => {
        // const result = mutationResult?.data?.res?.result
        const successful = mutationResult?.data?.res?.successful

        if (!successful) return null

        if (currentUser?.id) {
          cache.modify({
            id: cache.identify(currentUser),
            fields: {
              addressCards(addressCardRefs: Reference[], { readField }) {
                return addressCardRefs.filter(addressCardRef => addressCardId !== readField('id', addressCardRef))
              }
            }
          })
        }

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  return {
    createAddressCard,
    updateAddressCard,
    deleteAddressCard,
    useAsDefaultAddressCard
  }
}

export default useGraphqlAddressCard
