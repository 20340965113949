import { gql } from '@apollo/client'
import { FiltersAttrs } from '@fragments'

export const FetchFilters = gql`
  query filters {
    res: filters {
      ...FiltersAttrs
    }
  }

  ${FiltersAttrs}
`

export const fetchOptionClass = gql`
  query fetchOptionClass($id: UUID4!) {
    res: optionClass(id: $id) {
      id
      title
      options {
        id
        title
      }
    }
  }
`
