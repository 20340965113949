import React, { memo, useState, useEffect } from 'react'
import { isEqual } from 'lodash'
import styles from './PageSwitcher.module.scss'

const useAudio = (url: string) => {
  const [audio, setAudio] = useState(new Audio(url))
  const [playing, setPlaying] = useState(false)

  const toggle = () => {
    setPlaying(!playing)
    audio.play()
  }

  const handleSound = () => {
    setAudio(new Audio(url))
  }

  useEffect(() => {
    handleSound()
  }, [url])

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false))
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false))
    }
  }, [])

  return [playing, toggle]
}

export interface PageSwitcherOption {
  label: string
  value: any
}

export interface PageSwitcherProps {
  options: PageSwitcherOption[]
  value: any
  onClick(value: any): void
  url?: any
}

const PageSwitcher: React.FC<PageSwitcherProps> = props => {
  const { options, value, onClick, url } = props

  const [playing, toggle] = useAudio(url)

  let side: string | null = null

  options.forEach((option, index) => {
    if (isEqual(option.value, value)) {
      side = index === 0 ? (side = 'left') : (side = 'right')
    }
  })

  return (
    <div className={styles.pageSwitcher}>
      <div className={styles.pageSwitcherKnob} data-side={side} />
      {options.map(option => {
        const { label } = option
        const isActive = isEqual(option.value, value)

        const handleClick = (value: string) => {
          toggle()
          onClick(value)
        }

        return (
          <div
            key={label}
            className={styles.pageSwitcherOption}
            onClick={() => handleClick(option.value)}
            data-active={isActive}
            data-click={playing}
          >
            <div className={styles.pageSwitcherLabel} data-active={isActive} data-side={side}>
              {label.toUpperCase()}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default memo(PageSwitcher)
