import { gql } from '@apollo/client'

export const ElasticCatalogItems = gql`
  query ElasticCatalogItems($limit: Int, $term: String) {
    res: elasticCatalogItems(limit: $limit, term: $term) {
      brand
      category
      id
      slug
      title
    }
  }
`
