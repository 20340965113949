import { useAlert } from 'react-alert'
import { useMutation } from '@apollo/client'
import { auth, FetchAppData } from '@graphql'
import { useGraphqlUser, useRequestErrorHandler, useGraphqlLocker } from '@hooks'
import {
  ForgotPasswordFormEmailType,
  GolfClubDexterity,
  GolfClubFlex,
  MutationResponse,
  ResponseType,
  SignInArgs,
  SignUpArgs
} from '@types'

const useGraphqlAuth = () => {
  const alert = useAlert()
  const { update } = useGraphqlUser()
  const { createLocker } = useGraphqlLocker()

  const handleRequestError = useRequestErrorHandler()

  const [handleSignIn] = useMutation<ResponseType<MutationResponse & { result: { token: string } }>, SignInArgs>(
    auth.SignIn,
    {
      refetchQueries: [{ query: FetchAppData }],
      onError: error => handleRequestError(null, error)
    }
  )

  const [handleSignUp] = useMutation<ResponseType<MutationResponse & { result: { token: string } }>, SignUpArgs>(
    auth.SignUp,
    {
      refetchQueries: [{ query: FetchAppData }],
      onError: error => handleRequestError(null, error)
    }
  )

  const [sendForgotPasswordEmail] = useMutation<
    ResponseType<MutationResponse & { result: unknown }>,
    ForgotPasswordFormEmailType
  >(auth.ForgotPassword, { onError: error => handleRequestError(null, error) })

  const signIn = async (form: { email: string; password: string }) => {
    const request = await handleSignIn({
      variables: form,
      update: async (cache, mutationResult) => {
        const { data } = mutationResult

        if (data) {
          const {
            res: {
              successful,
              result: { token }
            }
          } = data

          if (successful) {
            alert.show(`Welcome back!`, {
              type: 'success'
            })
            localStorage.setItem('token', token)
          }
        }
      }
    })
    return handleRequestError(request)
  }

  const signUp = async (form: SignUpArgs) => {
    const request = await handleSignUp({
      variables: form,
      update: async (cache, mutationResult) => {
        const { data } = mutationResult

        if (data) {
          const {
            res: {
              result: { token },
              messages,
              successful
            }
          } = data

          if (messages?.length > 0) return null

          if (successful) {
            localStorage.setItem('token', token)
            alert.show(`Welcome!`, {
              type: 'success'
            })
          }

          const localUserFlex = localStorage.getItem('flex')
          const localUserDexterity = localStorage.getItem('dexterity')

          if (localUserDexterity && localUserFlex) {
            await update({
              basicInfo: { flex: localUserFlex as GolfClubFlex, dexterity: localUserDexterity as GolfClubDexterity }
            })
          }
        }
      }
    })

    const myClub = form.golfCourseId
    if (myClub) {
      await createLocker({ myClubId: myClub })
    }
    return handleRequestError(request)
  }

  const forgotPassword = async (form: ForgotPasswordFormEmailType) => {
    const request = await sendForgotPasswordEmail({
      variables: form,
      update: async (cache, mutationResult) => {
        const { data } = mutationResult

        if (data) {
          const {
            res: { successful }
          } = data

          if (!successful) return null

          alert.show(`Reset Email Sent`, {
            type: 'success'
          })
        }
      }
    })

    return handleRequestError(request)
  }

  return {
    signIn,
    signUp,
    forgotPassword
  }
}

export default useGraphqlAuth
