import React, { memo, useEffect, useState } from 'react'
import PopupComponent from 'reactjs-popup'
import { useQuery } from '@apollo/client'
import { useAlert } from 'react-alert'
import { FormProvider, useForm } from 'react-hook-form'
import { ActionsPopupMenu } from '@components'
import { Button, ButtonWithPlus, Checkbox, Input, NoDataYet, ValidationErrorMessage } from '@elements'
import { useGraphqlUser, useWindowSize } from '@hooks'
import { CacheUser, PaymentCard, ResponseType } from '@types'
import { user as graphqlUser } from '@graphql'
import Visa from '@images/visa.svg'
import MasterCard from '@images/master-card.svg'
import Dots from '@images/three-vertical-dots.svg'
import './ProfilePayment.scss'
import { getBrowserName, zeroPad } from '@utils'

export interface ProfilePaymentProps {
  onSelect?: (paymentCard: PaymentCard) => void
  onDelete?: () => void
  handleSubmitSuccess?: () => void
  handleFormOpen?: (isOpen: boolean) => void
  handleLoading?: (loading: boolean) => void
  createCardAsDefault?: boolean
  noDataMessage?: string
}

export interface UserPaymentCardForm {
  cardnumber: string
  cvc: string
  ccyear: number
  ccmonth: number
  default: boolean
}

export interface UserPaymentCardFormSafari {
  cardExpirationMonth: number
  cardExpirationYear: number
  cardNumber: string
  cvc: string
  default: boolean
}

const ProfilePayment: React.FC<ProfilePaymentProps> = props => {
  const {
    onSelect,
    onDelete,
    handleSubmitSuccess,
    handleFormOpen,
    handleLoading,
    createCardAsDefault,
    noDataMessage
  } = props
  const { data: userData } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-first'
  })

  const { breakpoint } = useWindowSize()
  const { createCard, updateCard, useCardAsDefault, deleteCard, loading } = useGraphqlUser()
  const alert = useAlert()

  // const [paymentFormOpened, setPaymentFormOpened] = useState(false)

  const [cardFormOpened, setCardFormOpened] = useState(false)

  const [paymentCardToUpdate, setPaymentCardToUpdate] = useState<string | null>(null)
  const [selectedPaymentCard, selectPaymentCard] = useState<PaymentCard | null>(null)
  const [isAmericanExpress, setIsAmericanExpress] = useState<boolean>(false)
  const americanExpressRegex = /3[47][0-9]{13}/
  const isValidKey = (e: React.KeyboardEvent) => {
    const currentKey = e.key
    const ctrlDown = e.ctrlKey || e.metaKey
    const ctrlC = ctrlDown && currentKey.toLowerCase() === 'c'
    const ctrlV = ctrlDown && currentKey.toLowerCase() === 'v'
    if (!e.key.match(/^[0-9]+$/) && e.key !== 'Backspace' && !ctrlC && !ctrlV) {
      e.preventDefault()
    }
  }

  const { paymentCards = [] } = userData ? userData?.res : {}

  const isMobile = breakpoint === 'landscape' || breakpoint === 'mobile'
  const fullYear = new Date().getFullYear()
  const isDefaultPaymentCard = (paymentCards || []).some(paymentCard => paymentCard.default)
  const sortedPaymentCards = [...paymentCards]?.sort((a: PaymentCard, b: PaymentCard) => +a.number - +b.number)
  const defaultPaymentCard =
    (paymentCards || [])?.find(paymentCard => paymentCard.default) || paymentCards[paymentCards?.length - 1]
  const isDefaultChecked = !isDefaultPaymentCard || paymentCards?.length === 0 || createCardAsDefault

  const form = useForm<UserPaymentCardForm>()
  const { handleSubmit, reset, setValue } = form

  useEffect(() => {
    handleLoading && handleLoading(loading)
  }, [loading])

  useEffect(() => {
    handleFormOpen && handleFormOpen(cardFormOpened)
    if (!cardFormOpened) {
      setPaymentCardToUpdate(null)
      reset({})
    }
  }, [cardFormOpened])

  useEffect(() => {
    if (paymentCards && paymentCards?.length > 0 && !selectedPaymentCard) {
      const newSelectedPaymentCard =
        paymentCards?.find(paymentCard => paymentCard.default) || paymentCards[paymentCards?.length - 1]
      handleSelectPaymentCard(newSelectedPaymentCard)
    }
  }, [paymentCards])

  const onError = () => null

  const handleSelectPaymentCard = (paymentCard: PaymentCard) => {
    selectPaymentCard(paymentCard)
    setCardFormOpened(false)
    //useCardAsDefault(paymentCard.id)
    onSelect && onSelect(paymentCard)
  }

  const onSubmit = async (form: UserPaymentCardForm & UserPaymentCardFormSafari) => {
    const variables = returnVariablesPaymentCardForm(form)
    const paymentsCards = userData?.res.paymentCards

    if (paymentsCards && returnIsMatchCard(paymentsCards, form)) {
      alert.show(`Error, This pay card already exists.!`, {
        type: 'error'
      })

      return null
    }

    const data = paymentCardToUpdate ? await updateCard(paymentCardToUpdate, variables) : await createCard(variables)

    if (data) {
      // success request
      setCardFormOpened(false)
      // handleSubmitSuccess && handleSubmitSuccess()
    }
  }

  const onCancel = () => {
    setCardFormOpened(false)
  }

  if (!cardFormOpened && !sortedPaymentCards?.length) {
    return (
      <NoDataYet
        message={noDataMessage}
        button={'Add/Change Debit/Credit Card'}
        onClick={() => {
          // setPaymentFormOpened(true)
          setCardFormOpened(true)
        }}
      />
    )
  }
  const handleUseCardAsDefault = (id: string, close: () => void) => {
    useCardAsDefault(id)
    close()
  }

  const returnVariablesPaymentCardForm = (form: UserPaymentCardForm & UserPaymentCardFormSafari) => {
    if (getBrowserName() === 'safari') {
      return {
        cardNumber: form.cardNumber,
        cvc: form.cvc,
        expMonth: form.cardExpirationMonth,
        expYear: form.cardExpirationYear,
        default: form.default
      }
    }
    return {
      cardNumber: form.cardnumber,
      cvc: form.cvc,
      expMonth: form.ccmonth,
      expYear: form.ccyear,
      default: form.default
    }
  }

  const returnIsMatchCard = (paymentsCards: PaymentCard[], form: UserPaymentCardFormSafari & UserPaymentCardForm) => {
    const cardNumber = getBrowserName() === 'safari' ? form.cardNumber.toString() : form.cardnumber.toString()
    const formPayCard = {
      cardnumber: +cardNumber.slice(-4, cardNumber.length),
      ccmonth: +form.ccmonth,
      ccyear: +form.ccyear
    }

    return paymentsCards?.some(payCard => {
      const userPayCard = {
        cardnumber: payCard.number,
        ccmonth: payCard.expMonth,
        ccyear: payCard.expYear
      }

      return JSON.stringify(userPayCard) === JSON.stringify(formPayCard)
    })
  }

  const currentMonthPlaceholder = zeroPad(new Date().getMonth() + 1, 2)

  return (
    <div className={'profile-payment'}>
      <div className={'profile-payment-header'}>
        <div className={'profile-payment-header-title'}>
          <div>Accepted Cards</div>
          <Visa />
          <MasterCard />
          <div>
            <img
              className={'profile-payment-header-title-american-express'}
              src="/assets/images/american-express.webp"
              alt=""
            />
          </div>
        </div>
        {sortedPaymentCards.length > 0 && (
          <div className={'profile-payment-header-cards'}>
            {sortedPaymentCards.map(paymentCard => {
              const { id } = paymentCard
              const paymentCardPopupMenu = [
                {
                  label: 'Use as Default Card',
                  value: '',
                  icon: '/assets/images/exclamation-mark-circle.svg',
                  onClick: () => useCardAsDefault(id)
                },
                {
                  label: 'Delete',
                  value: '',
                  icon: '/assets/images/actions-delete.svg',
                  onClick: () => {
                    setCardFormOpened(false)
                    deleteCard(id)
                    onDelete && onDelete()
                    selectPaymentCard(null)
                  }
                }
              ]

              return (
                <div key={id} className={'profile-payment-header-cards-item'}>
                  <div
                    onClick={() => handleSelectPaymentCard(paymentCard)}
                    className={'profile-payment-header-cards-item-view'}
                    data-selectable={true}
                    data-selected={selectedPaymentCard?.id === id}
                  >
                    {defaultPaymentCard.id === id && (
                      <svg
                        className={'profile-payment-header-cards-item-view-as-default'}
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM6 11.25L2.25 7.5L3.3075 6.4425L6 9.1275L11.6925 3.435L12.75 4.5L6 11.25Z"
                          fill="#4E60DD"
                        />
                      </svg>
                    )}

                    <div className={'profile-payment-header-cards-item-view-preview'}>
                      {paymentCard?.brand === 'visa' ? (
                        <Visa />
                      ) : paymentCard?.brand === 'mastercard' ? (
                        <MasterCard />
                      ) : paymentCard?.brand === 'amex' ? (
                        <img
                          className={'profile-payment-header-title-american-express'}
                          src="/assets/images/american-express.webp"
                          alt=""
                        />
                      ) : null}
                    </div>
                    <div className={'profile-payment-header-cards-item-view-hidden-number'}>
                      **** {paymentCard.number.toString().substr(-4)}
                    </div>
                    {isMobile ? (
                      <PopupComponent
                        trigger={
                          <div className={'profile-payment-header-cards-item-view-actions'}>
                            <Dots />
                          </div>
                        }
                        modal
                        overlayStyle={{ background: 'rgba(82, 82, 82, 0.61)' }}
                        contentStyle={{ background: 'transparent', width: '100%', marginBottom: '0' }}
                      >
                        {(close: () => void) => {
                          return (
                            <div style={{ width: '300px', margin: '0 auto', fontSize: '18px' }}>
                              <div
                                style={{
                                  background: '#ffffff',
                                  borderRadius: '14px',
                                  boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.14)'
                                }}
                              >
                                <div
                                  style={{
                                    padding: '19px 50px',
                                    textAlign: 'center',
                                    color: '#0C0C0C',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    handleUseCardAsDefault(id, close)
                                  }}
                                >
                                  Use as Default Card
                                </div>
                                <div
                                  style={{
                                    padding: '19px 50px',
                                    textAlign: 'center',
                                    color: '#F9655B',
                                    borderTop: '1px solid #EFEFF9',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    setCardFormOpened(false)
                                    onDelete && onDelete()
                                    selectPaymentCard(null)
                                    deleteCard(id)
                                    close()
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                              <div
                                style={{
                                  background: '#ffffff',
                                  padding: '19px 50px',
                                  marginTop: '10px',
                                  borderRadius: '14px',
                                  boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.14)',
                                  textAlign: 'center',
                                  cursor: 'pointer'
                                }}
                                onClick={close}
                              >
                                Cancel
                              </div>
                            </div>
                          )
                        }}
                      </PopupComponent>
                    ) : (
                      <div className={'profile-payment-header-cards-item-view-actions'}>
                        <ActionsPopupMenu options={paymentCardPopupMenu} />
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
      {!cardFormOpened && (
        <div className={'profile-payment-button'}>
          <ButtonWithPlus
            onClick={() => {
              setCardFormOpened(!cardFormOpened)
              selectPaymentCard(null)
            }}
          >
            Add a new card
          </ButtonWithPlus>
        </div>
      )}
      {cardFormOpened && selectedPaymentCard === null && (
        <FormProvider {...form}>
          <form
            autoComplete="on"
            method="post"
            className={'profile-payment-form'}
            onSubmit={handleSubmit(onSubmit)}
            onError={onError}
          >
            <div className={'profile-payment-form-card'}>
              <div className={'profile-payment-form-card__front'}>
                <div className={'profile-payment-form-card-title'}>Card Number</div>
                <div className={'profile-payment-form-card-number'}>
                  <Input
                    onChange={v => {
                      setValue('cardnumber', parseFloat(v) || '')
                      setIsAmericanExpress(americanExpressRegex.test(v))
                    }}
                    name={getBrowserName() === 'safari' ? 'cardNumber' : 'cardnumber'}
                    type={'text'}
                    autoComplete={'cc-number'}
                    minLength={15}
                    maxLength={16}
                    validation={{
                      required: { value: true, message: 'Card number: is required' },
                      minLength: { value: 15, message: 'Card number: Please provide 15-numbers or 16-numbers format' },
                      maxLength: { value: 16, message: 'Card number: Please provide 15-numbers or 16-numbers format' }
                    }}
                    placeholder={'0000 0000 0000 0000'}
                    onKeyDown={isValidKey}
                    renderError={() => null}
                  />
                </div>
                <div className={'profile-payment-form-card-title'}>
                  <div>Expiration date</div>
                  <div>CVV</div>
                </div>
                <div className={'profile-payment-form-card-date'}>
                  <div>
                    <div className={'profile-payment-form-card-month'}>
                      <Input
                        // type={'number'}
                        name={getBrowserName() === 'safari' ? 'cardExpirationMonth' : 'ccmonth'}
                        autoComplete={'cc-exp-month'}
                        x-autocompletetype={'cc-exp-month'}
                        minLength={2}
                        maxLength={2}
                        validation={{
                          required: { value: true, message: 'Expiration month: is required' },
                          minLength: { value: 1, message: 'Expiration month: Please provide MM format' },
                          maxLength: { value: 2, message: 'Expiration month: Please provide MM format' },
                          validate: {
                            isMinMax: (value: number) => {
                              if (value < 1) {
                                return 'Expiration month: must be 1 or greater'
                              } else if (value > 12) {
                                return 'Expiration month: must be 12 or less'
                              }
                            }
                          }
                        }}
                        placeholder={currentMonthPlaceholder}
                        onKeyDown={isValidKey}
                        renderError={() => null}
                      />
                    </div>
                    /
                    <div className={'profile-payment-form-card-year'}>
                      <Input
                        onKeyDown={isValidKey}
                        onChange={v => {
                          //// fix safari autofill
                          if (v.includes('.')) {
                            const month = v.split('.')[0] //// MM format
                            const year = 20 + v.split('.')[1] ///// YYYY format
                            setValue('ccmonth', month.toString())
                            setValue('ccyear', '')
                            setTimeout(() => {
                              setValue('ccyear', year.toString())
                            }, 0)
                          }
                          setValue('ccyear', v || '')
                        }}
                        // type={'number'}
                        name={getBrowserName() === 'safari' ? 'cardExpirationYear' : 'ccyear'}
                        autoComplete={'cc-exp-year'}
                        x-autocompletetype={'cc-exp-year'}
                        minLength={4}
                        maxLength={4}
                        validation={{
                          required: { value: true, message: 'Expiration year: is required' },
                          minLength: { value: 4, message: 'Expiration year: Please provide YYYY format' },
                          maxLength: { value: 4, message: 'Expiration year: Please provide YYYY format' },
                          validate: {
                            isMinMax: (value: number) => {
                              if (+value < fullYear) {
                                return `Expiration year: must be ${fullYear} or greater`
                              }
                            }
                          }
                        }}
                        placeholder={fullYear.toString()}
                        renderError={() => null}
                      />
                    </div>
                  </div>
                  <div className={'profile-payment-form-card-cvc'}>
                    <Input
                      name={'cvc'}
                      autoComplete={'cc-csc'}
                      minLength={3}
                      maxLength={isAmericanExpress ? 4 : 3}
                      validation={{
                        required: { value: true, message: 'CVV code: is required' },
                        minLength: {
                          value: isAmericanExpress ? 4 : 3,
                          message: `CVV code: Please provide XXX${isAmericanExpress ? 'X' : ''} format`
                        },
                        maxLength: {
                          value: isAmericanExpress ? 4 : 3,
                          message: `CVV code: Please provide XXX${isAmericanExpress ? 'X' : ''} format`
                        }
                      }}
                      placeholder={'cvv'}
                      onKeyDown={isValidKey}
                      renderError={() => null}
                    />
                  </div>
                </div>
              </div>
              <div className={'profile-payment-form-card__default'}>
                <Checkbox
                  name={'default'}
                  label={'Use as default card'}
                  defaultChecked={isDefaultChecked}
                  disabled={isDefaultChecked}
                />
              </div>
            </div>
            <ValidationErrorMessage name={'cardNumber'} />
            <ValidationErrorMessage name={'exp_month'} />
            <ValidationErrorMessage name={'exp_year'} />
            <ValidationErrorMessage name={'cvc'} />
            <div className={'profile-payment-form-footer'}>
              <Button type={'roundBlack'} size={'xs'} onClick={onCancel}>
                Cancel
              </Button>
              <Button type={'roundWithBg'} submit loading={loading}>
                Confirm
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  )
}

export default memo(ProfilePayment)
