import React, { memo } from 'react'
import CarouselComponent, {
  RenderArrowProps,
  RenderPaginationProps,
  ReactElasticCarouselProps
} from 'react-elastic-carousel'
import { Button, Bullets } from '@elements'
import './Carousel.scss'
import { Link } from '@elements'

interface CarouselProps extends Partial<ReactElasticCarouselProps> {
  list: any[]
  title?: string
  withArrows?: boolean
  Component: React.ComponentType<{ listData: any }> | React.FunctionComponent<{ listData: any }>
  titleLink?: string
}

const arrow = (props: RenderArrowProps) => {
  const { type, onClick, isEdge } = props
  const arrowDirection = type === 'PREV' ? 'left' : 'right'

  return <Button type={'circle'} arrowDirection={arrowDirection} disabled={isEdge} onClick={onClick} />
}

const pagination = (paginationProps: RenderPaginationProps) => {
  return <Bullets {...paginationProps} />
}

const Carousel: React.FC<CarouselProps> = props => {
  const { title, withArrows, list, Component, titleLink, ...otherProps } = props

  const renderTitle = () => {
    if (titleLink) {
      return (
        <Link to={titleLink}>
          <h6>
            <strong>{title}</strong>
          </h6>
        </Link>
      )
    }
    return (
      <h6>
        <strong>{title}</strong>
      </h6>
    )
  }

  return (
    <div className={'carousel'}>
      {title && (
        <div className={'carousel-header'}>
          <div className={'carousel-header-title'}>{renderTitle()}</div>
        </div>
      )}
      <CarouselComponent
        isRTL={false}
        renderArrow={withArrows ? arrow : () => <></>}
        renderPagination={list.length < 1.5 ? () => <></> : pagination}
        {...otherProps}
      >
        {list.map((item, index) => (
          <Component key={index} listData={item} />
        ))}
      </CarouselComponent>
    </div>
  )
}

Carousel.defaultProps = {
  withArrows: true
}

export default memo(Carousel)
