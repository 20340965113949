import React, { memo } from 'react'
import ShareImage from '@images/share.svg'
import styles from './ButtonWithShare.module.scss'

interface ButtonWithShare {
  onClick(e: React.MouseEvent<HTMLDivElement>): void
}

const ButtonWithShare: React.FC<ButtonWithShare> = props => {
  const { children, onClick } = props

  return (
    <div className={styles.buttonWithShare} onClick={onClick}>
      <ShareImage />
      {children}
    </div>
  )
}

export default memo<ButtonWithShare>(ButtonWithShare)
