import { gql } from '@apollo/client'
import { GolfClubTypeAttrs } from '@fragments'

export const FetchGolfClubType = gql`
  query FetchGolfClubType($id: UUID4!) {
    res: golfClubType(id: $id) {
      ...GolfClubTypeAttrs
    }
  }

  ${GolfClubTypeAttrs}
`

export const SearchGolfClubType = gql`
  query SearchGolfClubType($golfClubType: SearchGolfClubTypeParams) {
    res: searchGolfClubTypes(golfClubType: $golfClubType) {
      ...GolfClubTypeAttrs
    }
  }
  ${GolfClubTypeAttrs}
`

export const FetchGolfClubModelOptions = gql`
  query FetchGolfClubModelOptions($golfClubModelId: UUID4!) {
    res: golfClubModelOptions(golfClubModelId: $golfClubModelId)
  }
`

export const FetchGolfClubModelFlexes = gql`
  query FetchGolfClubModelFlexes($golfClubModelId: UUID4!) {
    res: golfClubModelFlexes(golfClubModelId: $golfClubModelId)
  }
`
