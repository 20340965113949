import { gql } from '@apollo/client'
import { ValidationMessageAttrs } from '@fragments'

export const UpdateUserGolfItem = gql`
  mutation UpdateUserGolfItem($id: UUID4!, $userGolfItem: UserGolfItemParams) {
    res: updateUserGolfItem(id: $id, userGolfItem: $userGolfItem) {
      messages {
        ...ValidationMessageAttrs
      }
      successful
    }
  }
  ${ValidationMessageAttrs}
`

export const DeleteUserGolfClub = gql`
  mutation DeleteUserGolfItem($id: UUID4!) {
    res: deleteUserGolfItem(id: $id) {
      messages {
        ...ValidationMessageAttrs
      }
      successful
    }
  }
  ${ValidationMessageAttrs}
`

export const FetchPaginatedUserGolfItems = gql`
  query PaginatedUserGolfItems($userId: UUID4!, $page: Int, $pageSize: Int) {
    res: paginatedUserGolfItems(userId: $userId, page: $page, pageSize: $pageSize) {
      entries {
        golfClubModel {
          id
          slug
          avatar
          releaseYear
          title
          category {
            title
          }
          brand {
            title
          }
        }
        purchasePrice
        purchaseDate
        option
        id
        golfEquipmentModel {
          id
          slug
          avatar
          releaseYear
          title
          category {
            title
          }
          brand {
            title
          }
        }
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
`

export const CreateUserGolfItem = gql`
  mutation CreateUserGolfItem($userGolfItem: UserGolfItemParams) {
    res: createUserGolfItem(userGolfItem: $userGolfItem) {
      messages {
        ...ValidationMessageAttrs
      }
      successful
    }
  }
  ${ValidationMessageAttrs}
`
