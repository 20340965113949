import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { stateCodes } from '@utils'

export type CountryType = {
  country_name: string
  country_short_name: string
  country_phone_code: number
}

export type StateType = {
  state_name: string
  code?: string
}

const useCountryAndStateApi = () => {
  const alert = useAlert()
  const [token, setToken] = useState<string | null>(null)
  const [countries, setCountries] = useState<CountryType[]>([])
  const [states, setStates] = useState<StateType[]>([])

  const fetchToken = () => {
    try {
      fetch('https://www.universal-tutorial.com/api/getaccesstoken', {
        headers: {
          Accept: 'application/json',
          'api-token': 'ykWUrXXUniMS6kjNYwSIUEBZ65ITc9KwBsNb-9jFf4mJrwBgpLHm1x_4v8zmKabBQ08',
          'user-email': 'a.smolyaninov@brightlab.me'
        }
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.auth_token) {
            setToken('Bearer ' + data.auth_token)
          } else {
            alert.show("Country API doesn't works")
          }
        })
    } catch (err) {
      alert.show('Something went wrong: ' + err)
    }
  }

  const fetchCountries = async () => {
    if (!token) return
    try {
      return fetch('https://www.universal-tutorial.com/api/countries', {
        headers: {
          Authorization: token,
          Accept: 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => setCountries(data))
    } catch (err) {
      alert.show('Countries not loaded')
    }
  }

  const fetchStates = (country: string) => {
    if (!token) return
    try {
      return fetch('https://www.universal-tutorial.com/api/states/' + country, {
        headers: {
          Authorization: token,
          Accept: 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          const statesWithCodes = data.map((state: Omit<StateType, 'code'>) => {
            return {
              ...state,
              code: stateCodes[state.state_name as keyof typeof stateCodes]
            }
          })
          setStates(statesWithCodes)
        })
    } catch (err) {
      alert.show('States of country not loaded')
    }
  }

  useEffect(fetchToken, [])
  useEffect(() => {
    if (token) {
      fetchCountries()
    }
  }, [token])

  return { fetchStates, countries, states }
}

export default useCountryAndStateApi
