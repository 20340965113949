import React from 'react'
import styles from './InfoGrid.module.scss'

interface InfoGridProps {
  rows: {
    label: string
    value: string | number
  }[]
}

const InfoGrid: React.FC<InfoGridProps> = ({ rows }) => {
  return (
    <div className={styles.infoGrid}>
      {rows?.map(row => {
        return (
          <div className={styles.infoGridRow} key={row.label}>
            <strong className={styles.infoGridRowLabel + ' uppercase'}>{row.label + ':'}</strong>
            <em className={styles.infoGridRowValue}>{row.value}</em>
          </div>
        )
      })}
    </div>
  )
}

export default InfoGrid
