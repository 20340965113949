import { gql } from '@apollo/client'
import { SectionAttrs } from '@fragments'

export const Section = gql`
  query Sections {
    sections {
      ...SectionAttrs
    }
  }

  ${SectionAttrs}
`
