import { useQuery } from '@apollo/client'
import { GolfClubType, GolfEquipmentType, ResponseType } from '@types'
import { equipment as graphqlEquipment, golfClubType as graphqlGolfClubType } from '@graphql'

const useGraphqlCheckout = (id: string, type: 'golfClub' | 'equipment') => {
  const query = type === 'golfClub' ? graphqlGolfClubType.FetchGolfClubType : graphqlEquipment.FetchGolfEquipmentType

  const { data: golfTypeData, loading: golfTypeLoading } = useQuery<ResponseType<GolfClubType | GolfEquipmentType>>(
    query,
    {
      variables: { id },
      fetchPolicy: 'cache-first'
    }
  )

  return {
    golfTypeData: golfTypeData,
    golfType: golfTypeData?.res,
    loading: golfTypeLoading
  }
}

export default useGraphqlCheckout
