import React, { memo, useEffect } from 'react'
import { RouteComponentProps, useLocation } from '@reach/router'
import { Children } from '@types'
import styles from './Page.module.scss'
import { useQuery } from '@apollo/client'
import { user } from '@graphql'
import smartlookClient from 'smartlook-client'

export interface PageProps extends RouteComponentProps {
  children: Children
}

const Page: React.FC<PageProps> = props => {
  const { children } = props
  const { pathname } = useLocation()
  const { data } = useQuery(user.FetchCurrentUser, { fetchPolicy: 'cache-only' })
  const currentUser = data && data.res
  const isCheckoutSuccessPage = pathname.includes('checkout-success')
  const isDevelopment = process.env.NODE_ENV !== 'production'

  useEffect(() => {
    if (!isDevelopment) {
      smartlookClient.init('7f398015abe2cce3214ebab49ef8dfa8abc04d06')
      if (currentUser) {
        smartlookClient.identify(currentUser.id, { userName: currentUser.name })
      }
    }
  }, [])

  useEffect(() => {
    if (!isCheckoutSuccessPage) {
      localStorage.removeItem('checkoutSuccessStatus') /// remove checkout-success index.tsx
    }
  }, [pathname])

  // useEffect(() => {
  //   if (pathname.includes('/catalogue') && sessionStorage.getItem('prevPage')?.includes('/product/')) {
  //     sessionStorage.setItem('prevPage', 'true')
  //   } else sessionStorage.setItem('prevPage', pathname || '')
  // }, [pathname])

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
      />
      <div className={styles.page} role="main">
        {children}
      </div>
    </>
  )
}

Page.defaultProps = {
  path: ''
}

export default memo(Page)
