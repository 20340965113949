import { gql } from '@apollo/client'

export const GolfClubDraftAttrs = gql`
  fragment GolfClubDraftAttrs on GolfClubDraft {
    id
    category {
      id
      insertedAt
      title
      updatedAt
    }
    brand {
      avatar
      id
      insertedAt
      title
      updatedAt
    }
    dexterity
    flex
    condition
    option
    price
    expiresAt
    user {
      id
    }
    insertedAt
    updatedAt
    golfClubModel {
      id
      title
      avatar
    }
  }
`
