import React from 'react'
import { ApolloError, OperationVariables, useQuery } from '@apollo/client'
import { DocumentNode } from 'graphql'
import Loader from '../../elements/Loader/Loader'
import { Children, ResponseType } from '@types'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import styles from './QueryResponseHandler.module.scss'

interface QueryResponseHandlerProps<TData, TVariables, TResolvedData> {
  query: DocumentNode
  variables?: TVariables
  children: (data: TResolvedData) => Children
  options?: QueryHookOptions<ResponseType<TData>, TVariables>
  dataResolver?: (data: TData) => TResolvedData // TODO replace any
  errorHandler?: (error: ApolloError) => any | void
  loadingHandler?: (loading: boolean) => any | void
}

const QueryResponseHandler = <TData, TVariables = OperationVariables, TResolvedData = TData>({
  query,
  variables,
  children,
  options,
  dataResolver,
  errorHandler,
  loadingHandler
}: QueryResponseHandlerProps<TData, TVariables, TResolvedData>) => {
  const { data, loading, error } = useQuery<ResponseType<TData>, TVariables>(query, { variables, ...options })

  if (loading) {
    if (!loadingHandler) {
      return (
        <div className={styles.wrapper}>
          <Loader />
        </div>
      )
    } else loadingHandler(loading)
  }

  if (error) {
    if (!errorHandler) {
      return (
        <div className={styles.wrapper}>
          <span className="danger">{error.message}</span>
        </div>
      )
    } else errorHandler(error)
  }

  if (!data?.res) {
    return (
      <div className={styles.wrapper}>
        <span>Something went wrong...</span>
      </div>
    )
  }

  const result = dataResolver ? dataResolver(data.res) : data.res

  return <>{children(result as TResolvedData)}</>
}

export default QueryResponseHandler
