import { gql } from '@apollo/client'

export const ParseCSVAttr = gql`
  fragment ParseCSVAttr on importCSVParseResponse {
    model_name
    condition
    option
    flex
    dexterity
    price
  }
`

export const ValidateCSVAttr = gql`
  fragment ValidateCSVAttr on importCSVValidateResultResponse {
    golfClubTypeId
    price
  }
`
