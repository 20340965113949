import { Loader } from '@elements'
import ButtonArrow from '@images/button-arrow.svg'
import { handleEvent } from '@utils'
import { navigate } from 'gatsby'
import React, { memo, MouseEvent } from 'react'
import './Button.scss'

type ButtonKeyboardEvent = React.KeyboardEvent<HTMLButtonElement>
type ButtonMouseEvent = MouseEvent<HTMLButtonElement>
export type ButtonEvents = ButtonKeyboardEvent | ButtonMouseEvent

type ButtonTypeProp =
  | 'primary'
  | 'secondary'
  | 'black'
  | 'grey'
  | 'white'
  | 'round'
  | 'roundBlack'
  | 'roundWithBg'
  | 'roundWithHoverBg'
  | 'circle'
  | 'icon'
  | 'icon-mini'
  | 'icon-square'
  | 'lightGreen'
  | 'darkGreen'
  | 'lightBlue'

type ButtonSizeProp = 'lg' | 'md' | 'sm' | 'xs'

export interface ButtonProps {
  children?: string | JSX.Element
  className?: string
  type?: ButtonTypeProp
  size?: ButtonSizeProp
  onClick?(e: ButtonEvents): void
  disabled?: boolean
  loading?: boolean
  to?: string | null
  submit?: boolean
  centered?: boolean
  loaderColor?: string
  arrowDirection?: 'top' | 'left' | 'bottom' | 'right'
  noBorder?: boolean
}

const Button: React.FC<ButtonProps> = props => {
  const {
    className,
    type,
    size,
    to,
    onClick,
    loading,
    submit,
    centered,
    arrowDirection,
    noBorder,
    loaderColor = '#4E60DD'
  } = props

  const classNames = `button button__${type}-${size} ${className}`
  const disabled = props.disabled || loading

  const handleClick = async (e: ButtonEvents) =>
    await handleEvent(
      async (e: ButtonEvents) => {
        e.stopPropagation()

        if (to) await navigate(to)
        else {
          onClick && onClick(e)
        }
      },
      { value: e, disabled }
    )

  const handleKeyDown = async (e: ButtonKeyboardEvent) => {
    const isEnterKey = e.key === 'Enter'

    if (isEnterKey) {
      await handleClick(e)
    }
  }

  let children = null

  switch (type) {
    case 'circle':
      children = <ButtonArrow data-direction={arrowDirection} />
      break
    default:
      children = props.children
  }

  return (
    <button
      className={classNames}
      type={submit ? 'submit' : 'button'}
      data-disabled={loading || disabled}
      data-centered={centered}
      data-size={size}
      data-no-border={noBorder}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      {loading ? (
        <Loader color={loaderColor} type={'ThreeDots'} width={20} height={20} style={{ padding: '0.5rem' }} />
      ) : (
        children
      )}
    </button>
  )
}

Button.defaultProps = {
  className: '',
  type: 'primary',
  size: 'lg',
  loading: false,
  disabled: false,
  submit: false,
  centered: false
}

export default memo(Button)
