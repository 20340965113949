import { gql } from '@apollo/client'

export const LockerAttrs = gql`
  fragment LockerAttrs on Locker {
    hole
    handicap
    gloveSize
    championshipRole
    championshipYear
    myClub{
      id
      title
      state
      country
      address

    }
    holeInOne{
      id
      title
      state
      country
      address

    }
    turn{
      id
      title
    }
    drink{
      id
      title
    }
    id
    insertedAt
    updatedAt
  }
`
