import { navigate } from 'gatsby'
import React, { memo } from 'react'
import { Popup, PopupPositionProp, Link } from '@elements'
import { Children, OptionType } from '@types'
import styles from './PopupMenu.module.scss'

type PopupMenuOptionType = {
  icon?: string
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void
} & OptionType

const PopupMenuMenuItem: React.FC<PopupMenuOptionType & { bottom?: boolean }> = props => {
  const { icon, label, value, bottom } = props

  const inner = value ? <Link to={value}>{label}</Link> : <a>{label}</a>
  const onClick = (e: React.MouseEvent<HTMLLIElement>) => (props.onClick ? props.onClick(e) : navigate(value))

  return (
    <li className={styles.popupMenuNavListItem} data-bottom={bottom} onClick={onClick}>
      {icon && <img src={icon} alt={label} />}
      {inner}
    </li>
  )
}

interface PopupMenuProps {
  options: PopupMenuOptionType[]
  children: Children
  position?: PopupPositionProp
  bottom?: PopupMenuOptionType
  disabled?: boolean
  offsetY?: number
  offsetX?: number
}

const PopupMenu: React.FC<PopupMenuProps> = props => {
  const { options = [], position = 'top left', bottom, children, disabled, offsetY, offsetX } = props

  const trigger = <div className={styles.popupMenuTrigger}>{children}</div>
  const menu = (
    <nav role={'navigation'} className={styles.popupMenuNav} data-direction={'vertical'}>
      <ul className={styles.popupMenuNavList}>
        {options.map((option, index) => {
          const { label } = option
          const key = label + index
          return <PopupMenuMenuItem key={key} {...option} />
        })}
        {bottom && <PopupMenuMenuItem {...bottom} bottom />}
      </ul>
    </nav>
  )

  return (
    <div className={'popup-menu'}>
      {disabled ? (
        trigger
      ) : (
        <Popup offsetY={offsetY} offsetX={offsetX} trigger={trigger} position={position}>
          {menu}
        </Popup>
      )}
    </div>
  )
}

export default memo(PopupMenu)
