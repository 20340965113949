import React from 'react'
import { BrowseCategory, BrowsePage, BrowseSubCategory } from '@types'
import { navigate } from 'gatsby'
import styles from './Header.module.scss'

interface IHeaderBrowseCategoriesProps {
  browseCategory: BrowseCategory | null
}

const BrowseSubCategoryListItem: React.FC<{ subCategory: BrowseSubCategory }> = props => {
  const { subCategory } = props

  return (
    <div className={styles.headerSubCategoriesBrowseSubCategoriesBrowseSubCategory} key={subCategory.id}>
      <div className={styles.headerSubCategoriesSubCategory__title}>{subCategory.title}</div>

      <div>
        {subCategory.browsePages.map(browsePage => (
          <BrowsePageListItem key={browsePage.title} browsePage={browsePage} />
        ))}
      </div>
    </div>
  )
}

const BrowsePageListItem: React.FC<{ browsePage: BrowsePage }> = props => {
  const { browsePage } = props

  const handleCLickBrowsePage = async () => {
    localStorage.setItem('browsePageSlug', browsePage.slug)
    await navigate(`/browse/${browsePage.slug}`)
  }

  return (
    <div
      className={styles.headerSubCategoriesBrowseSubCategoriesBrowseSubCategoryBrowsePage__title}
      key={browsePage.id}
      onClick={handleCLickBrowsePage}
    >
      {browsePage.menuTitle}
    </div>
  )
}

const HeaderBrowseCategories: React.FC<IHeaderBrowseCategoriesProps> = props => {
  const { browseCategory } = props

  if (!browseCategory) return null

  return (
    <div className={styles.headerSubCategories}>
      <div className={styles.headerSubCategoriesBrowseTitle}>{browseCategory.title}</div>
      <div className={styles.headerSubCategoriesBrowseSubCategories}>
        {browseCategory.browseSubCategories.map(subCategory => (
          <BrowseSubCategoryListItem key={subCategory.id} subCategory={subCategory} />
        ))}
      </div>
    </div>
  )
}

export default HeaderBrowseCategories
