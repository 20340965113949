import React, { memo } from 'react'
import PencilImage from '@images/edit.svg'
import styles from './ButtonWithPencil.module.scss'

interface ButtonWithPencilProps {
  label?: string
  onClick(e: React.MouseEvent<HTMLDivElement>): void
}

const ButtonWithPencil: React.FC<ButtonWithPencilProps> = props => {
  const { label, children, onClick } = props

  return (
    <div className={styles.buttonWithPencil}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={styles.buttonWithPencilInner} onClick={onClick}>
        <PencilImage />
        {children}
      </div>
    </div>
  )
}

export default memo<ButtonWithPencilProps>(ButtonWithPencil)
