export { wrapRootElement, wrapPageElement } from './gatsby-entry'

export const shouldUpdateScroll = ({ routerProps: { location }, prevRouterProps }) => {
  const prevLocation = prevRouterProps?.location
  const scrollTop = () => {
    window.history.scrollRestoration = 'manual'
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }
  const checkCategoryIdsParamChange = () => {
    const currentURLSearchParamsCategory = new URLSearchParams(location?.search).get('categoryIds')
    const prevURLSearchParamsCategory = new URLSearchParams(prevLocation?.search).get('categoryIds')
    return currentURLSearchParamsCategory !== prevURLSearchParamsCategory
  }

  const isWindowAvailable = typeof window !== undefined
  const isCategoryChangeOnCataloguePage = location.pathname.includes('catalogue') && checkCategoryIdsParamChange()
  const isPathnameChange = prevLocation?.pathname !== location.pathname

  // if (isWindowAvailable && isCategoryChangeOnCataloguePage) scrollTop()
  if (isPathnameChange) scrollTop()
  return false
}
