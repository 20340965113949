import React, { memo } from 'react'
import styles from './GolfClubAvatarBox.module.scss'
import DeleteIcon from '@images/close-mini.svg'

interface GolfClubAvatarBoxProps {
  image: any | null
  index: number
  handleDelete: () => void
}

const GolfClubAvatarBox: React.FC<GolfClubAvatarBoxProps> = props => {
  return props.image ? (
    <div
      className={styles.golfClubAvatarBoxUploaded}
      onClick={() => {
        props.handleDelete()
      }}
    >
      <DeleteIcon width={10} height={10} className={styles.golfClubAvatarBoxUploadedDelete} />
      <img src={props.image} />
    </div>
  ) : (
    <div className={styles.golfClubAvatarBoxEmpty}>{props.index}</div>
  )
}

export default memo(GolfClubAvatarBox)
