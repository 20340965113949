import { gql } from '@apollo/client'
import {
  GolfClubModelAttrs,
  GolfClubModelMyInventoryAttrs,
  CategoryAttrs,
  SharedUserAttrs,
  BrandAttrs
} from '@fragments'

export const GolfClubAttrs = gql`
  fragment GolfClubAttrs on GolfClub {
    brand {
      ...BrandAttrs
    }
    category {
      ...CategoryAttrs
    }
    expiresAt
    golfClubModel {
      ...GolfClubModelAttrs
    }
    golfClubType {
      id
      bounce
      clubHeadVolume
      condition
      dexterity
      flex
      insertedAt
      length
      lieAngle
      loft
      option
      swingWeight
      totalWeight
      updatedAt
    }
    id
    insertedAt
    owner {
      ...SharedUserAttrs
    }
    price
    soldAt
    status
    updatedAt
  }
  ${BrandAttrs}
  ${CategoryAttrs}
  ${GolfClubModelAttrs}
  ${SharedUserAttrs}
`

export const GolfClubSalesHistoryAttrs = gql`
  fragment GolfClubSalesHistoryAttrs on GolfClub {
    expiresAt
    golfClubType {
      dexterity
      flex
      option
    }
    id
    price
    status
    updatedAt
  }
`

export const GolfClubInventoryDetailsAttrs = gql`
  fragment GolfClubInventoryDetailsAttrs on GolfClub {
    brand {
      ...BrandAttrs
    }
    category {
      ...CategoryAttrs
    }
    expiresAt
    golfClubModel {
      ...GolfClubModelMyInventoryAttrs
    }
    golfClubType {
      id
      flex
      dexterity
      condition
      option
    }
    id
    insertedAt
    price
    status
    updatedAt
  }
  ${BrandAttrs}
  ${CategoryAttrs}
  ${GolfClubModelMyInventoryAttrs}
`
