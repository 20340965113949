import { navigate } from 'gatsby'
import React, { memo } from 'react'
import { GolfClubModel, GolfEquipmentModel } from '@types'
import { AvatarCard } from '@elements'
import { useUrlSearchParams } from '@hooks'
import TrashIcon from '@images/delete.svg'
import styles from './GolfClubModelCard.module.scss'

interface GolfClubModelCardProps {
  golfModel: GolfClubModel | GolfEquipmentModel
  onDelete?: () => void
  disabled?: boolean
  className?: string
  value?: string
}

const GolfClubModelCard: React.FC<GolfClubModelCardProps> = props => {
  const { golfModel, disabled, onDelete, className, value } = props
  const lowestPriceCard = value === 'lowest_price'
  const highestBidCard = value === 'highest_bids'

  const { title, avatar, brand, highestBuyPrice, lowestSellPrice, slug } = golfModel

  const viewHightestBuyPrice = highestBuyPrice ? '$ ' + highestBuyPrice.toLocaleString() : '$ --'
  const viewLowestSellPrice = lowestSellPrice ? '$ ' + lowestSellPrice.toLocaleString() : '$ --'

  const { getUrlSearchParams } = useUrlSearchParams('term')
  const filters = getUrlSearchParams()
  const { dexterities = [], flexes = [], available = [] } = filters

  const isSellAvailable = available?.[0] === 'SELL'

  const renderSubtitle = () => {
    if (lowestPriceCard || highestBidCard) {
      return <em>DATE</em>
    }
    return <div> {isSellAvailable ? <em>Highest offer</em> : <em>Best Price</em>}</div>
  }

  const renderPrice = () => {
    if (lowestPriceCard) {
      return (
        <div className={styles.golfClubModelCardInfoPrice}>
          <b>{viewLowestSellPrice}</b>
        </div>
      )
    }
    if (highestBidCard) {
      return (
        <div className={styles.golfClubModelCardInfoPrice}>
          <b>{viewHightestBuyPrice}</b>
        </div>
      )
    }
    return (
      <div className={styles.golfClubModelCardInfoPrice}>
        <b>{isSellAvailable ? viewHightestBuyPrice : viewLowestSellPrice}</b> {disabled && <em>(Not available)</em>}
      </div>
    )
  }

  const generateGolfClubModelLink = () => {
    const isGolfClub = golfModel?.__typename === 'GolfClubModel'
    const newSearch = new URLSearchParams('')
    const flex = flexes?.[0]
    const dexterity = dexterities?.[0]

    let link = `/product/${slug}`

    if (isGolfClub) {
      if (flex) newSearch.set('flex', flex)
      if (dexterity) newSearch.set('dexterity', dexterity)
      if (newSearch.toString().length > 0) link += '?' + newSearch.toString()
    }

    return link
  }

  return (
    <div
      className={`${styles.golfClubModelCard} ${className}`}
      onClick={async () => {
        const link = generateGolfClubModelLink()
        await navigate(link)
      }}
      data-disabled={disabled}
    >
      <AvatarCard avatar={avatar} style={{ height: '100%', margin: '0 auto' }} />
      <div className={styles.golfClubModelCardInfo}>
        <p className={'text-16'}>
          <b>{brand && brand.title && brand.title.toUpperCase()}</b>
        </p>
        <p className={styles.golfClubModelCardInfoName + ' subtitle2'}>{title}</p>
        {renderSubtitle()}
        {renderPrice()}
      </div>
      {onDelete && (
        <TrashIcon
          onClick={e => {
            e.stopPropagation()
            onDelete()
          }}
          className={styles.golfClubModelCardDelete}
        />
      )}
    </div>
  )
}

export default memo(GolfClubModelCard)
