import React from 'react'

interface FallbackProps {
  height: string
  width?: string
}

const Fallback: React.FC<FallbackProps> = ({ height, width }) => {
  return <div className="fallback" style={{ height, width }} />
}

export default Fallback
