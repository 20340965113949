import { memo } from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

export default memo(Img)

// All these queries will be imported to the any static query by default
export const query = graphql`
  fragment ImageFluid1440x900 on File {
    childImageSharp {
      fluid(maxWidth: 1440, maxHeight: 900) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment ImageFluid1293x443 on File {
    childImageSharp {
      fluid(maxWidth: 1293, maxHeight: 443) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment ImageFluid1803x266 on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment ImageFluid1300x500 on File {
    childImageSharp {
      fluid(maxWidth: 1300, maxHeight: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment ImageFluid1440x550 on File {
    childImageSharp {
      fluid(maxWidth: 1440, maxHeight: 550) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment ImageFluid1920x402 on File {
    childImageSharp {
      fluid(maxWidth: 1920, maxHeight: 402) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment ImageFluid500x500 on File {
    childImageSharp {
      fluid(maxWidth: 500, maxHeight: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment ImageFluid335x275 on File {
    childImageSharp {
      fluid(maxWidth: 335, maxHeight: 275) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment ImageFixed1440x900 on File {
    childImageSharp {
      fixed(width: 1440, height: 900) {
        ...GatsbyImageSharpFixed
      }
    }
  }

  fragment ImageFixed1440x550 on File {
    childImageSharp {
      fixed(width: 1440, height: 550) {
        ...GatsbyImageSharpFixed
      }
    }
  }

  fragment ImageFluid1920x1080 on File {
    childImageSharp {
      fluid(maxWidth: 1920, maxHeight: 1080) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment ImageFluid868x530 on File {
    childImageSharp {
      fluid(maxWidth: 868, maxHeight: 530) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment ImageFluid1884x2160 on File {
    childImageSharp {
      fluid(maxWidth: 1884, maxHeight: 2160) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
