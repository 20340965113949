import { gql } from '@apollo/client'

export const AddressCardAttrs = gql`
  fragment AddressCardAttrs on AddressCard {
    address
    addressLine
    city
    country
    default
    id
    insertedAt
    name
    phoneNumber
    state
    stateCode
    updatedAt
    zipCode
  }
`
