import React from 'react'
import { useQuery } from '@apollo/client'
import { Select, SelectProps } from '@components'
import { capitalize } from 'lodash'
import { Filters, ResponseType } from '@types'
import { filters as graphqlFilters } from '@graphql'
import styles from './CategorySelect.module.scss'

export interface CategorySelectProps extends Omit<SelectProps, 'options'> {}

const CategorySelect: React.FC<CategorySelectProps> = props => {
  const { data } = useQuery<ResponseType<Filters>>(graphqlFilters.FetchFilters, {
    fetchPolicy: 'cache-only'
  })
  const filters = data ? data.res : ({} as Filters)
  const { categories } = filters

  const categoryOptions = categories.map(category => {
    return {
      label: capitalize(category.title),
      value: category.id
    }
  })

  return (
    <div className={styles.categorySelect}>
      <Select options={categoryOptions} {...props} />
    </div>
  )
}

export default CategorySelect
