import { gql } from '@apollo/client'

export const FetchPaginatedCatalogItems = gql`
  query PaginatedCatalogItems(
    $availabilityStatus: Boolean
    $available: GolfClubModelAvailableType
    $brandIds: [UUID4]
    $categoryIds: [UUID4]
    $dexterities: [String]
    $equipmentCategoryIds: [UUID4]
    $flexes: [String]
    $optionIds: [[UUID4]]
    $page: Int
    $playerIds: [UUID4]
    $pageSize: Int
    $price: [PriceFilter]
    $releaseYears: [Int]
    $sortBy: CatalogSorting
    $term: String
    $catalogType: CatalogType
    $wildcard: Boolean
    $includeIds: [UUID4]
    $seed: Float
  ) {
    res: paginatedCatalogItems(
      availabilityStatus: $availabilityStatus
      available: $available
      brandIds: $brandIds
      categoryIds: $categoryIds
      dexterities: $dexterities
      equipmentCategoryIds: $equipmentCategoryIds
      flexes: $flexes
      optionIds: $optionIds
      page: $page
      playerIds: $playerIds
      pageSize: $pageSize
      price: $price
      releaseYears: $releaseYears
      sortBy: $sortBy
      term: $term
      catalogType: $catalogType
      wildcard: $wildcard
      includeIds: $includeIds
      seed: $seed
    ) {
      entries {
        avatar
        brandTitle
        highestBuyPrice
        categoryTitle
        id
        insertedAt
        lowestSellPrice
        releaseYear
        retailPrice
        slug
        title
        type
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
`
