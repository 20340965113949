import { get, groupBy, isEqual } from 'lodash'
import {
  Category,
  GolfClubCondition,
  GolfClubType,
  GolfEquipmentType,
  NotificationType,
  OptionSchema,
  OptionType
} from '@types'
import { driverLofts, fairwayWoodLofts, hybridLofts, ironSetNumbers, putterLengths, wedgesBounceAngles } from './mocks'
import { equipment as graphqlEquipment, golfClubType as graphqlGolfClubType } from '@graphql'

interface handleEventOptions {
  value?: any
  disabled?: boolean
}

/*
  Adds different behaviors to the handler, for example:
  const handler = () => null
  const options = { disabled }

  Won't work
  handleEvent(handler, { disabled: true })

  Works
  handleEvent(handler, { disabled: false })
*/
export const handleEvent = (handler?: (...args: any[]) => void, options: handleEventOptions = {}) => {
  const { disabled, value } = options

  if (disabled) return

  return handler && handler(value)
}

// TODO tests
/*
  Goes through the array and apply OptionSchema logic

  const optionSchema = {
    value: '[id]',
    label: 'name'
  }

  Where optionSchema keys are option fields
  and values are item fields in passed array, for example:

  array: [{ [id]: '1', name: 'Hello world!' }]
  schema: { label: '[id]', value: 'name' }

  return [{ label: 'Hello world!', value: '1' }]
*/
export const getOptionsBySchema = <T = Record<string, any>, V = OptionType<string>>(
  arr: T[],
  optionSchema: OptionSchema
) => {
  return arr.map(item => {
    return Object.keys(optionSchema).reduce((acc, field) => {
      const itemKey = get(optionSchema, field)
      const optionKey = field

      if (typeof itemKey === 'function') {
        return {
          ...acc,
          [optionKey]: itemKey(item)
        }
      } else {
        return {
          ...acc,
          [optionKey]: (item || ({} as Record<string, any>))[itemKey]
        }
      }
    }, {} as V)
  })
}

export const objectsToOptions = <T, V = string>(
  arr: T[],
  labelKey: keyof T | ((item: T) => string),
  valueKey: keyof T | ((item: T) => V)
): OptionType<V>[] => {
  return arr.map(item => {
    return {
      label: typeof labelKey === 'function' ? labelKey(item) : get(item, labelKey),
      value: typeof valueKey === 'function' ? valueKey(item) : get(item, valueKey)
    } as OptionType<V>
  })
}

// TODO tests
/*
  Groups array by first letter, for example:

  const arr = [{ hello: 'Abc' }, { hello: 'Bcd' }]
  groupByFirstChar(arr, 'hello')

  return {
    'A': [{ hello: 'Abc' }],
    'B': [{ hello: 'Bcd' }]
  }
*/
export const groupByFirstChar = (arr: any[], key: string) => {
  return groupBy(arr, item => {
    const field = get(item, key)
    return field.charAt(0).toUpperCase()
  })
}

export const getGolfClubInfoByCategory = (category?: Category) => {
  let title
  let options: { items: number[]; symbol: string }
  switch (category?.title) {
    case 'Putters':
      title = 'Length'
      options = putterLengths
      break
    case 'Irons':
      title = 'No. of Clubs'
      options = ironSetNumbers
      break
    case 'Wedges':
      title = 'Loft'
      options = wedgesBounceAngles
      break
    case 'Drivers':
      title = 'Loft'
      options = driverLofts
      break
    case 'Fairway Woods':
      title = 'Loft'
      options = fairwayWoodLofts
      break
    case 'Hybrids':
      title = 'Loft'
      options = hybridLofts
      break
    default:
      title = 'Loft'
      options = { items: [], symbol: '' }
      break
  }

  return { title, options }
}

export const compareObjectsAndGetMismatchesKeys = (a: Record<string, any>, b: Record<keyof typeof a, any>) => {
  const fields = Object.keys(a)
  return fields.filter(field => !isEqual(a[field], b[field]))
}

export const bringingToInputNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const isBackspace = e.key === 'Backspace'
  const isArrowLeft = e.key === 'ArrowLeft'
  const isArrowRight = e.key === 'ArrowRight'
  const isArrowUp = e.key === 'ArrowUp'
  const isArrowDown = e.key === 'ArrowDown'
  const isTab = e.key === 'Tab'

  if (isNaN(+e.key) && !isBackspace && !isArrowLeft && !isArrowRight && !isArrowUp && !isArrowDown && !isTab) {
    e.preventDefault()
  }
}
//////////////////////////google place api ...
export const getOptionsByPlace = (placeOptions: google.maps.places.AutocompletePrediction[] | null) => {
  if (!placeOptions) {
    return []
  }
  return placeOptions.map(place => {
    return { label: place.description, value: place.place_id, shortLabel: place.structured_formatting.main_text }
  })
}

export const getFillInformationByPlaces = (places: google.maps.GeocoderAddressComponent[] | undefined) => {
  if (!places) {
    return null
  }

  const [streetNumber] = places.filter((place: any) => {
    return Object.values(place.types).includes('street_number')
  })

  const [address] = places.filter((place: any) => {
    return Object.values(place.types).includes('route')
  })

  const [city] = places.filter((place: any) => {
    return Object.values(place.types).includes('locality')
  })

  const [zipCode] = places.filter((place: any) => {
    return Object.values(place.types).includes('postal_code')
  })

  const [state] = places.filter((place: any) => {
    return Object.values(place.types).includes('administrative_area_level_1')
  })

  return {
    address: address
      ? `${streetNumber?.long_name}${streetNumber?.long_name && address?.long_name && ' '}${address?.long_name}`
      : '',
    zipCode: zipCode ? zipCode?.long_name : '',
    state: state ? state?.long_name : '',
    city: city ? city?.long_name : '',
    stateCode: state ? state?.short_name : ''
  }
}

export const getBrowserName = () => {
  const userAgent = navigator.userAgent
  let browserName

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome'
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox'
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari'
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera'
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge'
  } else {
    browserName = 'No browser detection'
  }

  return browserName
}

export const returnQueryFetchGolfTypes = () => {
  if (localStorage.getItem('fetchType') === 'GolfClubType') return graphqlGolfClubType.FetchGolfClubType
  return graphqlEquipment.FetchGolfEquipmentType
}

export const getNotificationDescription = (notificationType: NotificationType) => {
  switch (notificationType) {
    case NotificationType.GOLF_CLUB_SOLD:
      return 'Your Golf Club Sold!'
    case NotificationType.GOLF_EQUIPMENT_SOLD:
      return 'Your Golf Equipment Sold!'
    case NotificationType.TAXES_PAYMENT_ERROR:
      return 'Your card has been declined for payment processing. All golf clubs for sale are cancelled and will need to be re-opened once you resolve your payment issue.'
    case NotificationType.SELLER_TAXES_PAYMENT_ERROR:
      return 'Sorry, seller’s card has been declined. We are unable to process the sale.'
    case NotificationType.PAYMENT_ERROR:
      return 'Your card has been declined and we are unable to process the sale.'
    case NotificationType.ITEM_CREATED:
      return 'Your Sell Price was Created'
    case NotificationType.PURCHASE_REQUEST_CREATED:
      return 'Your Offer was Created'
    case NotificationType.ORDER_CREATED:
      return 'Order Received!'
  }
}

/**
 * golfClub or golfEquipment by golfType
 * * true - golfClub
 * * false - golfEquipment
 */
export const isGolfClubByType = (golfType: GolfClubType | GolfEquipmentType): golfType is GolfClubType => {
  return golfType.hasOwnProperty('golfClubModel')
}

export const getConditionName = (condition: GolfClubCondition | undefined) => {
  switch (condition) {
    case GolfClubCondition.EXCELLENT:
      return 'MINT'
    case GolfClubCondition.GOOD:
      return 'GAMED'
    case GolfClubCondition.NEW:
      return 'NEW'
    default:
      return '-'
  }
}
/////////////////////////...

export function generateRandomNumber(): number {
  const randomNumber = Math.random()
  const roundedNumber = +randomNumber.toFixed(10)
  const randomSeedLocale = sessionStorage.getItem('randomSeed')
  const randomSeed: number[] = randomSeedLocale ? JSON.parse(randomSeedLocale) : []

  if (randomSeed?.includes(randomNumber)) {
    return generateRandomNumber()
  }
  sessionStorage.setItem('randomSeed', JSON.stringify([...randomSeed, roundedNumber]))
  return roundedNumber
}
