import React, { memo } from 'react'
import { OptionType } from '@types'
import { Link } from '@elements'
import styles from './Dropdown.module.scss'
import cls from 'classnames'

export interface DropdownProps {
  options: OptionType[]
  onSelect: (selectedOption: OptionType) => void
  toggle: boolean
  noItemMessage?: string
  allResultsLink?: string
  direction?: 'horizontal' | 'vertical'
  isDropUp?: true
  className?: string
}

const Dropdown = (props: DropdownProps) => {
  const {
    options,
    onSelect,
    toggle,
    allResultsLink,
    noItemMessage = 'No options available',
    direction = 'vertical',
    isDropUp,
    className
  } = props

  const handleClick = (e: React.MouseEvent<HTMLLIElement>, option: OptionType<any>) => {
    e.stopPropagation()
    e.preventDefault()
    onSelect(option)
  }

  const renderOptions = () => {
    const isOptions = options && options.length

    if (!isOptions) {
      return <i className={styles.dropdownEmpty}>{noItemMessage}</i>
    }

    /* For Global Search element */
    if (allResultsLink) {
      return options.slice(0, 7).map((option, index) => {
        const { label } = option
        const key = label + index

        if (index === 6) {
          return (
            <div key={key} className={styles.dropdownAllResultsLink}>
              <hr />
              <Link to={allResultsLink} underlined>
                All results
              </Link>
            </div>
          )
        }

        return (
          <li key={key} onClick={e => handleClick(e, option)}>
            {label}
          </li>
        )
      })
    }

    return options.map((option, index) => {
      const { label } = option
      const key = label + index
      // TODO Enter, Arrows and tab logic + test

      return (
        <li onClick={e => handleClick(e, option)} key={key}>
          {label}
        </li>
      )
    })
  }

  return (
    <ul
      className={cls(className, styles.dropdown)}
      data-toggle={toggle}
      data-selection={true}
      data-direction={direction}
      data-testid={'dropdown'}
      data-isdropup={isDropUp}
    >
      {renderOptions()}
    </ul>
  )
}

export default memo(Dropdown)
