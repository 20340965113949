import { useQuery } from '@apollo/client'
import { Button, Disclosure, Link } from '@elements'
import { filters as graphqlFilters, user as graphqlUser } from '@graphql'
import { useModal, useWindowSize } from '@hooks'
import TourDayLogo from '@images/tourday/tourday-logo.svg'
import NGFLogo from '@images/national-golf-foundation-logo.svg'
import ClubmakersLogo from '@images/clubmakers-guild-logo.svg'
import WinkFaceIcon from '@images/wink-face.svg'
import { CacheUser, Filters, ResponseType } from '@types'
import { navigate } from 'gatsby'
import React, { memo } from 'react'
import styles from './Footer.module.scss'
import FacebookImage from '@images/facebook-white.svg'
import InstagramImage from '@images/instagram.svg'
import AmericanExpress from '@images/american-express.svg'
import MasterCard from '@images/master-card.svg'
import ApplePay from '@images/apple-pay.svg'
import GooglePay from '@images/google-pay.svg'
import UnionPay from '@images/unionPay.webp'
import Shop from '@images/shop.webp'
import Visa from '@images/visa.svg'

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const { openModal: openSuggestChangesModal } = useModal('SuggestChangesModal')

  const { toRender: isMobile } = useWindowSize(['mobile', 'landscape', 'tablet'])

  const { data } = useQuery<ResponseType<Filters>>(graphqlFilters.FetchFilters, { fetchPolicy: 'cache-only' })
  const categories = data?.res.categories.map(category => {
    return {
      label: category.title,
      value: `/catalogue/?mySettings=false&categoryIds=${category.id}`
    }
  })

  const contactUsLinks = [
    { label: 'Call Us: ', value: '929-324-0457' },
    { label: 'Text Us: ', value: '929-324-0457' },
    { label: 'Email Us: ', value: 'concierge@tourdaygolf.com' },
    { label: 'Address: ', value: '16 Old Track Road, Greenwich, CT 06830 United States' }
  ]

  const aboutLinks = [
    { label: 'About Us', value: '/about/' },
    { label: 'How It Works', value: '/about/' },
    { label: 'FAQ', value: '/FAQ/' },
    { label: 'Contact Us', value: '/contact-us' },
    { label: 'Guides', value: '/about/guides' }
  ]
  const ourPolicyLinks = [
    { label: 'Privacy Policy', value: '/our-policy/privacy' },
    { label: 'Return & Refund Policy', value: '/our-policy/return-refund' },
    { label: 'Shipping Policy', value: '/our-policy/shipping' },
    { label: 'Terms & Conditions', value: '/TERMS/' },
    { label: 'Payment Policy', value: '/our-policy/payment' }
  ]

  const handleScrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const { data: currentUser } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })
  const isLogin = Boolean(currentUser?.res?.id)

  const getColName = (index: number) => {
    switch (index) {
      case 0:
        return 'OUR POLICY'
      case 1:
        return 'HELP & SUPPORT'
      case 2:
        return 'CATALOG'
      case 3:
        return 'CONTACT US'
      default:
        return 'OUR POLICY'
    }
  }
  const renderBottomSection = () => {
    if (isMobile) {
      return (
        <div className={styles.footerBottomSectionMobile}>
          {[ourPolicyLinks, aboutLinks, categories]?.map((list, index) => {
            return (
              <Disclosure key={index} label={getColName(index)}>
                <div className={styles.footerLinksList}>
                  <ul>
                    <li />
                    {list?.map(listItem => (
                      <li key={listItem.label} onClick={handleScrollToTop}>
                        <Link to={listItem.value}>{listItem.label}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Disclosure>
            )
          })}
        </div>
      )
    }
    return (
      <div className={styles.footerBottomSection}>
        <div className={styles.footerBottomSectionDescription}>
          <TourDayLogo onClick={() => navigate('/home')} />
          TourDay Golf is a premier destination to buy and sell new and pre-owned golf equipment.
          <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
            <NGFLogo style={{ height: 40, width: 90 }} />
            <ClubmakersLogo style={{ marginLeft: 20, height: 40, width: 180 }} />
          </div>
          <div className={styles.socialIcons}>
            <div style={{ display: 'flex', gap: '20px' }}>
              <a
                href="https://www.facebook.com/tourdaygolf/"
                target="_blank"
                rel="noreferrer"
                className={styles.facebookIcon}
              >
                <FacebookImage />
              </a>
              <a href="https://www.instagram.com/tourdaygolf/" target="_blank" rel="noreferrer">
                <InstagramImage width={25} height={25} />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-links-block">
          <div className={styles.footerLinks}>
            {[ourPolicyLinks, aboutLinks, categories, contactUsLinks]?.map((list, index) => {
              return (
                <div key={index} className={styles.footerLinksList}>
                  <ul>
                    <li>{getColName(index)}</li>
                    {list?.map(listItem => {
                      const name = getColName(index)
                      const isContactUs = name === 'CONTACT US'
                      const isCatalog = name === 'CATALOG' || listItem.label === 'Guides'
                      const isEmail = listItem.label === 'Email Us: '
                      return (
                        <li key={listItem.label}>
                          {isContactUs ? (
                            <div>
                              {listItem.label}
                              {isEmail ? (
                                <a style={{ color: '#464646' }} href={`mailto:${listItem.value}`}>
                                  {listItem.value}
                                </a>
                              ) : (
                                <span>{listItem.value}</span>
                              )}
                            </div>
                          ) : (
                            <Link
                              className={isCatalog ? styles.footerLinksCatalog : styles.footerLinks}
                              to={listItem.value}
                            >
                              {listItem.label}
                            </Link>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
          <div className={styles.payServices}>
            <AmericanExpress />
            <ApplePay />
            <GooglePay />
            <MasterCard />
            <img width={50} height={33} src={Shop} alt="" />
            <img width={50} height={33} src={UnionPay} alt="" />
            <Visa />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.footer} role="contentinfo">
      <div>
        {isLogin && (
          <div className={styles.footerBlock}>
            <div className={styles.footerBlockTitle}>
              Is there a club you’d like to buy or sell that is not in our library?
            </div>

            <div className={styles.footerBlockButton}>
              <Button onClick={() => openSuggestChangesModal()} type="black">
                Fill out the form
              </Button>
              <div className={styles.footerBlockHint}>
                <WinkFaceIcon />
                And we will review.
              </div>
            </div>
          </div>
        )}
        {renderBottomSection()}
      </div>
    </div>
  )
}

export default memo(Footer)
